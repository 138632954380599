import React from "react";
import TableBooking from './components/TableBooking'


const Booking = () => {
  return (
    <TableBooking />
  );
};

export default Booking;
