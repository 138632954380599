import React, { useMemo } from "react";
import DOMPurify from "dompurify";
import "quill/dist/quill.snow.css"; // Ensure this is imported
import { useAllPages } from "../features/Page/pageServices";

const Warranty = () => {
  const { data: page, error, isLoading } = useAllPages("/api/pages");
  
  console.log("Page data===>", page);

  // Find warranty data
  const warantyData = page?.find((waranty) => waranty?.slug === "waranty");
  console.log("Waranty Data===>", warantyData);

  // Sanitize content without stripping Quill classes
  const sanitizedContent = useMemo(() => {
    return {
      __html: DOMPurify.sanitize(warantyData?.content || "", {
        ADD_ATTR: ["class"], 
        ADD_TAGS: ["iframe"], 
      }),
    };
  }, [warantyData?.content]);

  console.log("sanitizedContent", sanitizedContent);

  return (
    <div className="poppins-regular relative w-full">
      {/* Top Banner */}
      <div
        className="absolute left-0 top-0 h-[650px] w-full bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/images/top-banner-contact.png')" }}
      ></div>

      {/* Main Content */}
      <div className="relative z-10 space-y-8 flex w-full flex-col items-center justify-center px-4 pt-32 md:px-32">
        <h6 className="text-3xl lowercase md:text-5xl">
          {warantyData?.heading}
        </h6>
        <h3 className="text-2xl font-bold">
          Simply Cavapoos / 10 YEAR GENETIC WARRANTY
        </h3>
        <div className="pb-10 flex w-full max-w-5xl flex-col">
          {/* Render sanitized HTML */}
          <div
            className="text-justify leading-8 ql-editor" // Ensure this class is present for Quill's default styling
            dangerouslySetInnerHTML={sanitizedContent}
          />
        </div>
      </div>
    </div>
  );
};

export default Warranty;
