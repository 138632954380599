import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GET_ALL_DELIVERIES } from "./deliveriestypes";
import { create, Delete, fetchAll, update } from "../Apis/Apis";


export const useAllDeliveries = (apiUrl) => {
  return useQuery({
    queryKey: [GET_ALL_DELIVERIES],
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateDeliveries = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: create,
    onMutate: (new_Deliveries) => {
      queryClient.invalidateQueries(GET_ALL_DELIVERIES);
    },
    onError: (error, context, new_Deliveries) => {
      console.log("error creating deliveries", error);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_DELIVERIES);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_DELIVERIES);
    },
  });
};

export const useUpdateDeliveries = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: update,
    onMutate: (new_Deliveries) => {
      queryClient.invalidateQueries(GET_ALL_DELIVERIES);
    },
    onError: (error, context, new_Deliveries) => {
      console.log("error updating deliveries", error);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_DELIVERIES);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_DELIVERIES);
    },
  });
};

export const useDeleteDeliveries = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: Delete,
    onMutate: (new_Deliveries) => {
      queryClient.invalidateQueries(GET_ALL_DELIVERIES);
    },
    onError: (error, context, new_Deliveries) => {
      console.log("error deleting deliveries", error);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_DELIVERIES);
    },
    onSettled: ()=> {
        queryClient.invalidateQueries(GET_ALL_DELIVERIES)
    }
  });
};
