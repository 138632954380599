import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../utils/uploadFile";

import { CiSquareRemove } from "react-icons/ci";
import {
  useCreatePups,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";
import { useAllBreeds } from "../../../features/Breeds/Breeds";
import moment from "moment";
import dayjs from "dayjs";
import {
  useCreateProducts,
  useUpdateProductsMutation,
} from "../../../features/Products/productsService";
import { ReactSortable } from "react-sortablejs";
import { deleteImages } from "../../../utils";
import { useAllCAVAPOO, useUpdateCavaPooMutation } from "../../../features/cavapoo/cavapooServices";

const { TextArea } = Input;

const CavaPooForm = ({ onClose, selectedData, isUpdateModal, cavapooPage }) => {
 
console.log('edit cavapooPage', cavapooPage)
  const [isLoading, setIsLoading] = useState(false);
  const [media, setMedia] = useState([]);
  const toast = useToast();

  const UpdateMutation = useUpdateCavaPooMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (cavapooPage) {
    //   Convert existing media URLs to the format expected by Upload component
      const existingMedia =
      cavapooPage?.[0]?.media?.map((url, index) => ({
          uid: `-${index}`, // Unique ID for each file
          name: `image-${index}`, // A name for the file
          status: "done", // Mark it as already uploaded
          url: url, // The actual URL of the image
        })) || [];

      setMedia(existingMedia);

      form.setFieldsValue({
        mainHeading: cavapooPage?.[0]?.mainHeading ?? null,
        description: cavapooPage?.[0]?.description ?? null,
        dogDetails: cavapooPage?.[0]?.dogDetails ?? null,
        companionshipBreeds: cavapooPage?.[0]?.companionshipBreeds ?? null,
        cavapooCompanionship: cavapooPage?.[0]?.cavapooCompanionship?? null,
        video1: cavapooPage?.[0]?.video1 ?? null,
        video2: cavapooPage?.[0]?.video2 ?? null,
        media: cavapooPage?.[0]?.media,
     
      });
    }
  }, [cavapooPage]);

  const handleBeforeUpload = (file) => {
    const newFile = {
      ...file,
      uid: file.uid,
      name: file.name,
      status: "done",
      originFileObj: file, // Keep the original file object for upload
    };

    setMedia((prev) => [...prev, newFile]);
    return false; // Prevent automatic upload
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    try {
      // Handle media uploads first
      let mediaUrl = null;
      if (media?.length) {
        // Filter out already uploaded media (those with urls) from new ones that need uploading
        const newMedia = media.filter((file) => !file.url);
        const existingMedia = media
          .filter((file) => file.url)
          .map((file) => file.url);

        if (newMedia.length) {
          // Upload new media and get their URLs
          const uploadedMediaUrls = await uploadFiles(
            newMedia.map((file) => file.originFileObj),
          );
          mediaUrl = [...existingMedia, ...uploadedMediaUrls];
        } else {
          mediaUrl = existingMedia;
        }
      }

      const data = {
        mainHeading: values.mainHeading ?? null,
        description: values.description ?? null,
        dogDetails: values.dogDetails ?? null,
        companionshipBreeds: values.companionshipBreeds ?? null,
        cavapooCompanionship: values.cavapooCompanionship?? null,
        video1: values.video1 ?? null,
        video2: values.video2 ?? null,
        media: mediaUrl,
      };

      try {
        await UpdateMutation.mutateAsync({
            apiUrl: `/api/cavapoo/674a0de708b9283d41764625`,
            data,
          });

          toast({
            title: "Product Updated successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });

        // Reset form and clear states
        setMedia([]);
        form.resetFields();
        onClose();
      } catch (error) {
        toast({
          title: error.response?.data?.details?.includes("duplicate")
            ? "Product Already Exists"
            : error.response?.data?.details ||
              error.message ||
              "Error creating Product",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error uploading files",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          CavaPoo 101
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <Form.Item
              label="Media"
              name={"media"}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <ReactSortable
                animation={200}
                delayOnTouchStart={true}
                delay={2}
                list={media}
                setList={setMedia}
                className="flex cursor-move flex-wrap gap-4 pb-4"
              >
                {media.map((file) => (
                  <Upload
                    key={file.uid}
                    listType="picture-card"
                    fileList={[file]}
                    showUploadList={{
                      showPreviewIcon: true,
                      showRemoveIcon: true,
                    }}
                    onRemove={() => deleteImages(file, setMedia)}
                    disabled={isLoading}
                  />
                ))}
              </ReactSortable>

              {media?.length < 5 && (
                <Upload
                  listType="picture-card"
                  beforeUpload={handleBeforeUpload}
                  showUploadList={false}
                  multiple
                  disabled={isLoading}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              )}
              <span className="text-red-600">
                *Image dimensions should be 1200x800
              </span>
            </Form.Item>

            <Form.Item
              name="mainHeading"
              label="Main Heading"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Main Heading" disabled={isLoading} />
            </Form.Item>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Description
            </div>
            <Form.Item
              name="description"
              label="Description"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea
                rows={8}
                placeholder="Description"
                disabled={isLoading}
              />
            </Form.Item>
            <Form.Item
              name="dogDetails"
              label="Dog Details"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea
                rows={8}
                placeholder="Dog Details"
                disabled={isLoading}
              />
            </Form.Item>
            <Form.Item
              name="companionshipBreeds"
              label="Companionship Breeds"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea
                rows={4}
                placeholder="Companionship Breeds"
                disabled={isLoading}
              />
            </Form.Item>
            <Form.Item
              name="cavapooCompanionship"
              label="Cavapoo Companionship"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea
                rows={4}
                placeholder="Cavapoo Companionship"
                disabled={isLoading}
              />
            </Form.Item>

    

       

            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                name="video1"
                label="video 1"
                rules={[{ required: true }]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="video 1" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name="video2"
                label="video 2"
                rules={[{ required: true }]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="video 2" disabled={isLoading} />
              </Form.Item>
            </div>

          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button
              className="!bg-[#001219] !text-white"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default CavaPooForm;
