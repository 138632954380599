import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GET_ALL_DISCOUNTS } from "./discountType";
import { create, Delete, fetchAll, update } from "../Apis/Apis";



export const useAllDiscounts = (apiUrl) => {
    return useQuery({
      queryKey: [GET_ALL_DISCOUNTS],
      queryFn: () => fetchAll(apiUrl),
    });
  };


  export const useCreateDiscount = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: create,
      onMutate: async (newProduct) => {
        queryClient.invalidateQueries(GET_ALL_DISCOUNTS);
      },
      onError: (err, newProduct, context) => {
        console.log("Error creating DISCOUNT:", err);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(GET_ALL_DISCOUNTS);
      },
      onSettled: () => {
        queryClient.invalidateQueries(GET_ALL_DISCOUNTS);
      },
    });
  };



  export const useDeleteDISCOUNTMutation = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: Delete,
      onMutate: async (newProduct) => {
        queryClient.invalidateQueries(GET_ALL_DISCOUNTS);
      },
      onError: (err, newProduct, context) => {
        console.log("Error DELETING DISCOUNT COUPON:", err);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(GET_ALL_DISCOUNTS);
      },
      onSettled: () => {
        queryClient.invalidateQueries(GET_ALL_DISCOUNTS);
      },
    });
  };
  
  export const useUpdateDISCOUNTMutation = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: update,
      onMutate: async (newProduct) => {
        queryClient.invalidateQueries(GET_ALL_DISCOUNTS);
      },
      onError: (err, newProduct, context) => {
        console.log("Error UPDATING DSICOUNT COUPON:", err);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(GET_ALL_DISCOUNTS);
      },
      onSettled: () => {
        queryClient.invalidateQueries(GET_ALL_DISCOUNTS);
      },
    });
  };