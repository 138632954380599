import React, { useState } from "react";
import TableEmailAndSms from "./components/TableEmailAndSms";
import EmailTemplateForm from "./components/EmailTemplateForm";

const EmailAndSms = () => {
  const [showPage, setShowPage] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const pageHandler = () => {
    setShowPage(true);
  };

  return (
    <div>
      <div className="my-5 flex flex-1 flex-col">
        <button
          onClick={pageHandler}
          className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white"
        >
          Add Email Template
        </button>
      </div>
      {showPage ? (
        <EmailTemplateForm
          pageHandler={pageHandler}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setShowPage={setShowPage}
        />
      ) : (
        <TableEmailAndSms />
      )}
    </div>
  );
};

export default EmailAndSms;
