import React from "react";

const CustomButton = ({
  onClick,
  colorClass = "bg-[#35f8fe]",
  textColorClass = "text-white",
  icon: IconComponent,
  size = "p-1",
  ...props
}) => {
  return (
    <button
      className={`rounded-full ${colorClass} ${textColorClass} ${size}`}
      onClick={onClick}
      onMouseDown={(e) => e.stopPropagation()}
      {...props}
    >
      {IconComponent && <IconComponent />}
    </button>
  );
};

export default CustomButton;
