import React from 'react'
import BookingTabs from "../BookingTabs";

const TableBooking = () => {
  return (
    <BookingTabs />
  )
}

export default TableBooking
