import { useMutation, useQueryClient } from "@tanstack/react-query"
import { create } from "../Apis/Apis"
import { GET_ALL_ZIPCODE } from "./locationType"



export const useCreateZIP = ()=>{

    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: create,
        onMutate: (newZip)=>{
            queryClient.invalidateQueries(GET_ALL_ZIPCODE)
        },
        onError: (error, newZip, context)=>{
            console.log("An Error creating zip", error)
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_ZIPCODE)
        },
        onSettled: ()=>{
            queryClient.invalidateQueries(GET_ALL_ZIPCODE)
        }
    })

};