import axios from "axios";

// Define the API calls
export const fetchAll = async (apiUrl) => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + `${apiUrl}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return response.data;
};

export const Delete = async ({ apiUrl }) => {
  const response = await axios.delete(
    process.env.REACT_APP_BASE_URL + `${apiUrl}`,
  );
  return response;
};

// export const getById = async ({ apiUrl }) => {
//   const response = await axios.get(
//     process.env.REACT_APP_BASE_URL + `${apiUrl}`,
//     {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     },
//   );
//   return response.data;
// };

export const getById = async (apiUrl) => {
  const fullUrl = `${process.env.REACT_APP_BASE_URL}${apiUrl}`;
  try {
    const response = await axios.get(fullUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in getById:", error);
    throw error;
  }
};

export const create = async ({ apiUrl, data }) => {
  const response = await axios.post(
    process.env.REACT_APP_BASE_URL + `${apiUrl}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return response.data;
};

export const update = async ({ apiUrl, data }) => {
  const response = await axios.put(
    process.env.REACT_APP_BASE_URL + `${apiUrl}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return response.data;
};
