import { Tooltip } from "antd";
import React from "react";
import { IoIosUndo } from "react-icons/io";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const ViewBtn = ({ onClick, ...props }) => {
  return (
    <Tooltip title="View order details" color="#374151">
      <button
        className={`rounded-full bg-gray-700 p-1 text-white`}
        onClick={onClick}
        onMouseDown={(e) => e.stopPropagation()}
        {...props}
      >
        <MdOutlineRemoveRedEye />

      </button>
    </Tooltip>
  );
};

export default ViewBtn;
