import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

const GlobalLoader = () => {
  return (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      <Spinner />
    </Flex>
  );
};

export default GlobalLoader;
