import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { GET_ALL_TARGETCITIES } from "./targetCitiesTypes"
import { create, Delete, fetchAll, update } from "../Apis/Apis.js";



export const useAllTargetCities = (apiUrl) => {
    return useQuery({
        queryKey: [GET_ALL_TARGETCITIES],
        queryFn: () => fetchAll(apiUrl)
    })
}

export const useCreateTargetCities = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: create,
        onMutate: (new_TargetCities) => {
            queryClient.invalidateQueries(GET_ALL_TARGETCITIES)
        },
        onError: (error, context, new_TargetCities)=>{
            console.log('error creating target cities', error)
        },
        onSuccess: (data)=>{
            queryClient.invalidateQueries(GET_ALL_TARGETCITIES)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_TARGETCITIES)
        }
    })
}

export const useUpdateTargetCities = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: update,
        onMutate: (new_TargetCities) => {
            queryClient.invalidateQueries(GET_ALL_TARGETCITIES)
        },
        onError: (error, context, new_TargetCities)=> {
            console.log('error updating target cities', error)
        },
        onSuccess: (data)=>{
            queryClient.invalidateQueries(GET_ALL_TARGETCITIES)
        },
        onSettled: ()=>{
            queryClient.invalidateQueries(GET_ALL_TARGETCITIES)
        }
    })
}

export const useDeleteTargetCities = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: Delete,
        onMutate: (new_TargetCities)=>{
            queryClient.invalidateQueries(GET_ALL_TARGETCITIES)
        },
        onError: (error, context, new_TargetCities)=> {
            console.log('error deleting target cities', error)
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_TARGETCITIES)
        },
        onSettled: ()=>{
            queryClient.invalidateQueries(GET_ALL_TARGETCITIES)
        }
    })
}