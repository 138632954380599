import { Tooltip } from "antd";
import React from "react";
import { AiFillEdit } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { TbEdit } from "react-icons/tb";



const EditBtn = ({ onClick, ...props }) => {
  return (
    <Tooltip title="Edit Item" color="#3b82f6">
      <button
        className={`rounded-full bg-blue-500 p-1 text-white`}
        onClick={onClick}
        onMouseDown={(e) => e.stopPropagation()}
        {...props}
      >
        <TbEdit />
        
      </button>
    </Tooltip>
  );
};

export default EditBtn;
