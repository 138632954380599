import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../utils/uploadFile";

import moment from "moment";
import dayjs from "dayjs";

import {
  useCreateSpecialRequest,
  useUpdateSpecialRequest,
} from "../../../features/Special-request/specialRequestServices";

const { TextArea } = Input;

const AddSpecialRequest = ({ onClose, selectedData, orderID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreateSpecialRequest();

  const [form] = Form.useForm();

  console.log("orderid==>", orderID);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
      order: orderID,
      puppyInformation: {
        breeder: values?.puppyInformation.breeder ?? null,
        dogName: values?.puppyInformation.dogName ?? null,
        chip: values?.puppyInformation.chip ?? null,
        breed: values?.puppyInformation.breed ?? null,
        dateOfBirth: values?.puppyInformation.dateOfBirth ?? null,
      },
      customerPhotos: {
        email: values?.customerPhotos.email ?? null,
        phone: values?.customerPhotos.phone ?? null,
        lastName: values?.customerPhotos.lastName ?? null,
        pupSoldTo: values?.customerPhotos.pupSoldTo ?? null,
      },
      requestInformation: {
        notes: values?.requestInformation.notes ?? null,
        sentBy: values?.requestInformation.sentBy ?? null,
        video: values?.requestInformation.video ?? null,
        birthCertificate: values?.requestInformation.birthCertificate ?? null,
        breederInfo: values?.requestInformation.breederInfo ?? null,
        appt: values?.requestInformation.appt ?? null,
        asap: values?.requestInformation.asap ?? null,
        photo: values?.requestInformation.photo ?? null,
        puppyHealth: values?.requestInformation.puppyHealth ?? null,
        parentPhotos: values?.requestInformation.parentPhotos ?? null,
        inHouse: values?.requestInformation.inHouse ?? null,
        faceTime: values?.requestInformation.faceTime ?? null,
        ofa: values?.requestInformation.ofa ?? null,
        parentHealth: values?.requestInformation.parentHealth ?? null,
        needsUpdate: values?.requestInformation.needsUpdate ?? null,
      },
      cost: {
        price: values?.cost.price ?? null,
        chargeRemaining: values?.cost.chargeRemaining ?? null,
      },
      sentBirthCertificate: values?.sentBirthCertificate ?? null,
    };

    try {
      await createMutation.mutateAsync({
        apiUrl: "/api/special-request",
        data,
      });

      toast({
        title: "Special Request Created Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Reset form and clear states if needed
      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Special Request Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating Special Request",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Special Requests
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Puppy Information
            </div>

            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                name={["puppyInformation", "breeder"]}
                label="Breeder"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Breeder" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "dogName"]}
                label="Dog Name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Dog Name" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "chip"]}
                label="Chip"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Chip" disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name={["puppyInformation", "breed"]}
                label="Breed"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Breed" disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name={["puppyInformation", "dateOfBirth"]}
                label="Date Of Birth"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="dateOfBirth" disabled={isLoading} />
              </Form.Item>
            </div>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Customer Information
            </div>
            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                name={["customerPhotos", "email"]}
                label="Email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Email" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["customerPhotos", "phone"]}
                label="Phone"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Phone" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["customerPhotos", "lastName"]}
                label="Last Name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Last Name" disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name={["customerPhotos", "pupSoldTo"]}
                label="Pup Sold To"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Pup Sold To" disabled={isLoading} />
              </Form.Item>
            </div>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Request Information
            </div>
            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                name={["requestInformation", "notes"]}
                label="Notes"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Notes" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["requestInformation", "sentBy"]}
                label="Sent By"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Sent By" disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name={["requestInformation", "video"]}
                valuePropName="checked"
                label="Video"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "birthCertificate"]}
                valuePropName="checked"
                label="Birth Certificate"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "breederInfo"]}
                valuePropName="checked"
                label="Breeder Info"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "appt"]}
                valuePropName="checked"
                label="Appt"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "asap"]}
                valuePropName="checked"
                label="asap"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "photo"]}
                valuePropName="checked"
                label="photo"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "puppyHealth"]}
                valuePropName="checked"
                label="Puppy Health"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "parentPhotos"]}
                valuePropName="checked"
                label="Parent Photos"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "inHouse"]}
                valuePropName="checked"
                label="In House"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "faceTime"]}
                valuePropName="checked"
                label="Face Time"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "ofa"]}
                valuePropName="checked"
                label="ofa"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "parentHealth"]}
                valuePropName="checked"
                label="parentHealth"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "needsUpdate"]}
                valuePropName="checked"
                label="needsUpdate"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name="sentBirthCertificate"
                valuePropName="checked"
                label="sentBirthCertificate"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
            </div>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Cost
            </div>
            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                name={["cost", "price"]}
                label="Price"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Price" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["cost", "chargeRemaining"]}
                label="Charge Remaining"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Charge Remaining" disabled={isLoading} />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button
              className="!bg-[#001219] !text-white"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddSpecialRequest;
