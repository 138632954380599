import React from "react";
import Categories from "./component/Categories";
import Questions from "./component/Questions";
import ChakraTabs from "../../components/ChakraTabs";

const Faqs = () => {
  const tabData = [
    {
      label: "Categories",
      content: <Categories />,
    },
    {
      label: "Questions",
      content: <Questions />,
    },
  ];
  return (
    
    <div>
      <ChakraTabs tabData={tabData} />
    </div>
    
  );
};

export default Faqs;
