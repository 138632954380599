import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useToast } from "@chakra-ui/react";
import { uploadFiles } from "../../../utils/uploadFile";
import { ReactSortable } from "react-sortablejs";
import { deleteImages, handleBeforeUpload } from "../../../utils";
import { useCreateReviews, useUpdateReviewMutation } from "../../../features/reviews/reviewsService";

const GalleryForm = ({ onClose, selectedData, isUpdateModal }) => {
  const [form] = Form.useForm();
  const toast = useToast();
  const [media, setMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const createMutation = useCreateReviews();
  const updateMutation = useUpdateReviewMutation();

  console.log(selectedData, "selectedData");

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        pupNewName: selectedData[0]?.pupNewName ?? null,
        weight: selectedData[0]?.weight ?? null,
        pupEnjoyCmnt: selectedData[0]?.pupEnjoyCmnt ?? null,
        experience: selectedData[0]?.experience ?? null,
      });
      // setMedia(selectedData[0]?.media ?? []);
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const data = {
        pupNewName: values.pupNewName ?? null,
        weight: values.weight ?? null,
        pupEnjoyCmnt: values.pupEnjoyCmnt ?? null,
        experience: values.experience ?? null,
      };

      let photoUrls = [];
      if (media?.length) {
        const newPhotos = media.filter((photo) => !photo.url);
        const existingPhotos = media
          .filter((photo) => photo.url)
          .map((photo) => photo.url);

        if (newPhotos.length) {
          const uploadedPhotoUrls = await uploadFiles(
            newPhotos.map((photo) => photo.originFileObj),
          );
          photoUrls = [...existingPhotos, ...uploadedPhotoUrls];
          data.media = photoUrls;
        } else {
          photoUrls = existingPhotos;
        }
      }

      if (isUpdateModal && selectedData) {
        await updateMutation.mutateAsync({
          apiUrl: `/api/reviews/${selectedData[0]._id}`,
          data,
        });

        toast({
          title: "Review successfully updated!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/reviews",
          data,
        });
        setMedia([]);
        toast({
          title: "Review successfully created!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title:
          error.response?.data?.details ||
          error.message ||
          "Error processing request",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <h2 className="pb-10 text-2xl font-bold">Review</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
      >
        <div className="min-w-full">
          <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
            Customer Information
          </div>
          <Form.Item label="Pup new name" name="pupNewName">
            <Input placeholder="Pup new name" disabled={isLoading} />
          </Form.Item>
          <Form.Item label="Pup weight" name="weight">
            <Input placeholder="Pup weight" disabled={isLoading} />
          </Form.Item>

          <Form.Item label="Review" name="pupEnjoyCmnt">
            <TextArea rows={3} placeholder="Pup review" disabled={isLoading} />
          </Form.Item>

          <Form.Item label="Experience" name="experience">
            <TextArea
              rows={3}
              placeholder="Pup experience"
              disabled={isLoading}
            />
          </Form.Item>
        </div>

        <div className="flex items-center justify-center">
          <Form.Item
            valuePropName="fileList"
            getValueFromEvent={normFile}
            name="media"
            className="flex items-center justify-center"
          >
            <ReactSortable
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              list={media}
              setList={setMedia}
              className="flex cursor-move flex-wrap gap-4 pb-4"
            >
              {media.map((file) => (
                <Upload
                  key={file.uid}
                  listType="picture-card"
                  fileList={[file]}
                  showUploadList={{
                    showPreviewIcon: true,
                    showRemoveIcon: true,
                  }}
                  onRemove={() => deleteImages(file, setMedia)}
                  disabled={isLoading}
                />
              ))}
            </ReactSortable>
            {media.length < 5 && (
              <Upload
                listType="picture-card"
                beforeUpload={(file) => handleBeforeUpload(file, setMedia)}
                showUploadList={false}
                multiple
                disabled={isLoading}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Select Files</div>
                </div>
              </Upload>
            )}
            <span className="text-red-600">
              *Image dimensions should be 1200x800
            </span>
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            {isUpdateModal ? "Update" : "Create"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GalleryForm;
