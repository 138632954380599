import React, { useState, useEffect } from "react";
import { Button, DatePicker, Dropdown, Space, Modal } from "antd";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { LiaTimesSolid } from "react-icons/lia";
import { MdKeyboardArrowDown } from "react-icons/md";
import GlobalLoader from "../../../components/GlobalLoader.jsx";
import { AiFillEdit } from "react-icons/ai";
import { useDisclosure, useToast } from "@chakra-ui/react";

import { GlobalModalV1 } from "../../../components/GlobalModal.jsx";

import {
  useAllBreeder,
  useDeleteBreederMutation,
} from "../../../features/Breeder/breederService.js";
import BreederForm from "./BreederForm.jsx";
import EditBtn from "../../../components/EditBtn.jsx";
import DeleteBtn from "../../../components/DeleteBtn.jsx";

const BreederTable = () => {
  const { data: breeders, isLoading, error } = useAllBreeder("/api/breeders");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const filterProduct = breeders?.map((breeder, index) => ({
    ...breeder,
    id: index + 1,
  }));

  console.log(filterProduct, "filterProduct");

  console.log(breeders, "breeders");
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const toast = useToast();

  const createDeleteMutation = useDeleteBreederMutation();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    setSelectedId(itemToDelete);
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const handleDelete = () => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/breeder/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast({
            title: "Breeder Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted Breeder");
          toast({
            title: "Error deleted Breeder",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = filterProduct?.filter(
      (item) => item?._id === selectedIdEdit,
    );
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
      onOpen();
    };
    return (
      <div className="flex gap-5 pt-2">
        {/* <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          onClick={handle}
        >
          <AiFillEdit />
        </button>

        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
          onClick={() => handleDeleteClick(props.data._id)}
        >
          <LiaTimesSolid />
        </button> */}

        <EditBtn onClick={handle} />
        <DeleteBtn onClick={() => handleDeleteClick(props.data._id)} />
      </div>
    );
  };

  const columns = [
    {
      headerName: "ID",
      field: "id",
    },
    {
      headerName: "First Name",
      field: "firstName",
    },
    {
      headerName: "Last Name",
      field: "lastName",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Cell phone",
      field: "phoneNo",
    },
    {
      headerName: "breeds",
      field: "breeds",
    },

    { headerName: "Action", cellRenderer: btnComponent, pinned: 'right' , width: '100%' },
  ];

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
    flex: 1,
  };

  const items = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
      danger: true,
    },
    {
      label: "4th menu item",
      key: "4",
      danger: true,
      disabled: true,
    },
  ];

  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading Breeder</p>;

  return (
    <div className="my-10">
      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={filterProduct}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <BreederForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>

      <Modal
        title="Confirm Delete"
        open={showDeleteConfirm}
        onOk={handleConfirmDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Yes, delete"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to delete this Breeder record?</p>
      </Modal>
    </div>
  );
};

export default BreederTable;
