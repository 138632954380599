import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import { useCreateBookingBreed, useUpdateBookingBreed } from "../../features/Bookings/BookingService";

const { TextArea } = Input;

const BreedForms = ({ onClose, selectedData, isUpdateModal }) => {

  
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreateBookingBreed();
  const UpdateMutation = useUpdateBookingBreed();

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        name: selectedData[0]?.name ?? null,
        avgLitter: selectedData[0]?.avgLitter ?? null,
        id: selectedData[0]?.id ?? null,
        active: selectedData[0]?.active ?? null,
        pureBreed: selectedData[0]?.pureBreed ?? null
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
     
      name: values.name ?? null,
      active: values.active ?? null,
      avgLitter: values.avgLitter ?? null,
      pureBreed: values.pureBreed ?? null
    };

    try {
      if (isUpdateModal && selectedData) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/booking-breed/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "Booking Breed Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/booking-breed",
          data,
        });

        toast({
          title: "Booking Breed created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      // Reset form and clear states if needed
      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Booking Breed Already Exists"
          : error.response?.data?.details ||
            error.message ||
                "Error creating Booking Breed",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Breed
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">

        

            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Name" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="active"
              valuePropName="checked"
              label="Active"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 1 }}
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>
            <Form.Item
              name="avgLitter"
              valuePropName="checked"
              label="Avg Litter"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 1 }}
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>
            <Form.Item
              name="pureBreed"
              valuePropName="checked"
              label="Pure Breed"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 1 }}
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button className="!bg-[#001219] !text-white" type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default BreedForms;
