import React, { useEffect, useMemo, useState } from "react";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../../components/GlobalModal";

import { availablePups_tableData } from "../../../utils/data";
import {
  useAllPups,
  useDeletePupsMutation,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";
import { getDate } from "../../../utils";
import { RxOpenInNewWindow } from "react-icons/rx";
import { Button, Modal } from "antd";

const DeletedParent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updateMutation = useUpdatePupsMutation();
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [status, setStatus] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showUpdateConfirm, setShowUpdateConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToUpdate, setItemToUpdate] = useState(null);

  const [filters, setFilters] = useState({
    available: "",
    details: "",
    views: "",
    checkboxes: "",
    desc: "",
    wholesale: "",
  });
  const toast = useToast();

  const extendedClose = () => {
    onClose();
    setSelectedIdEdit(null);
    setSelectedData([]);
  };
  const { data: puppies, isLoading, error } = useAllPups("/api/parents");
  console.log("puppies===>", puppies);

  const createDeleteMutation = useDeletePupsMutation();

  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    setSelectedId(itemToDelete);
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const handleUpdateClick = (id) => {
    setItemToUpdate(id);
    setShowUpdateConfirm(true);
  };

  const handleConfirmUpdate = () => {
    setSelectedIdEdit(itemToUpdate);
    setShowUpdateConfirm(false);
    setItemToUpdate(null);
  };

  const handleDelete = () => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/parent/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast({
            title: "Parent Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error deleting Parent",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const data = { status };

    updateMutation.mutateAsync(
      { apiUrl: `/api/parent-status/${selectedIdEdit}`, data },
      {
        onSuccess: () => {
          setSelectedIdEdit(null);
          toast({
            title: "Parent Status Updated Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error updating Parent Status",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  useEffect(() => {
    if (selectedIdEdit) {
      handleUpdate();
    }
    if (selectedId) {
      handleDelete();
    }
  }, [selectedIdEdit, selectedId]);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };
  const filteredData = availablePups_tableData.filter(
    (item) =>
      item.available.includes(filters.available) &&
      item.details.toLowerCase().includes(filters.details.toLowerCase()) &&
      item.views.toLowerCase().includes(filters.views.toLowerCase()) &&
      item.checkboxes
        .toLowerCase()
        .includes(filters.checkboxes.toLowerCase()) &&
      item.desc.toLowerCase().includes(filters.desc.toLowerCase()) &&
      item.wholesale.toLowerCase().includes(filters.wholesale.toLowerCase()),
  );

  console.log(filteredData, "filteredData");

  const sortedPuppies = useMemo(() => {
    return puppies
      ?.filter((puppy) => !puppy?.parentDetails?.status)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [puppies]);


  return (
    <div className="my-10">
    <div className="flex flex-1 flex-col">
  
    </div>
    <div className="my-10">
      <div className="rounded-md border-2 shadow-md border-gray-200">
        <div className="grid grid-cols-12 gap-4 p-4 ">
          {/* Image */}
          <div className="col-span-3 flex flex-col items-start justify-start ml-14">
            <div className="font-bold">Image</div>
          </div>

          {/* Details */}
          <div className="col-span-4 flex flex-col items-center justify-start  text-center">
            <input
              type="text"
              placeholder="Details"
              name="details"
              value={filters.details}
              onChange={handleFilterChange}
              className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
            />
          </div>

          {/* Description */}
          <div className="col-span-4 flex flex-col items-center justify-start  text-center">
            <input
              type="text"
              placeholder="Description"
              name="desc"
              value={filters.desc}
              onChange={handleFilterChange}
              className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
            />
          </div>
        </div>

        {sortedPuppies &&
          sortedPuppies.map((item, index) => (
            <div
              className="grid grid-cols-12 gap-4 border-t border-gray-200 p-4"
              key={index}
            >
              {/* Image Section */}
              <div className="col-span-3 flex items-center justify-center">
                <div className="h-[90%] w-[230px]">
                  <img
                    src={
                      item?.parentInformation?.parentPhotos?.[0] ||
                      "default-image-url"
                    }
                    alt="breed-img"
                    className="h-full w-full rounded-lg object-cover"
                  />
                </div>
              </div>

              {/* Details Section */}
              <div className="col-span-4 flex flex-col items-start gap-2 border border-gray-200 p-3">
                <p className="font-bold">
                  Name: {item?.parentInformation?.parentName || "No Name"}
                </p>
                <p>
                  <span className="font-semibold">  Parent ID:{" "}</span>
                
                  {item?.parentInformation?.petPlanParentId || "No Parent Id"}
                </p>
                <p>
                  <span className="font-semibold">Related Breed</span>:{" "}
                  {item?.parentInformation?.relatedBreeds || "No Breed "}
                </p>
                <p><span className="font-semibold">Category</span>: {item?.parentDetails?.parentCategory || "No Category"}</p>
                <p><span className="font-semibold">Weight</span>: {item?.parentDetails?.adultWeight || "No Weight "} lbs</p>
                <p><span className="font-semibold">Height</span>: {item?.parentDetails?.adultHeight || "No Height"} </p>
                <p><span className="font-semibold">Life span</span>: {item?.parentDetails?.lifespan || "No lifespan"}</p>
                
                <div className="mt-2 flex space-x-2">
                    <button
                      onClick={() => handleUpdateClick(item?._id)}
                      className="rounded bg-orange-500 px-2 py-1 text-sm text-white"
                    >
                      Undo
                    </button>

                    <button
                      onClick={() => handleDeleteClick(item?._id)}
                      className="rounded bg-red-500 px-2 py-1 text-sm text-white"
                    >
                      Delete
                    </button>
                  </div>
              </div>

              {/* Description Section */}
              <div className="col-span-4 flex flex-col gap-2 border border-gray-200 p-2">
                <p>
                <span className="font-semibold">Description</span> :{" "}
                  {item?.parentDetails?.metaDescription || "No Description"}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>

     {/* Delete Modal */}
     <Modal
        title="Confirm Delete"
        open={showDeleteConfirm}
        onOk={handleConfirmDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Yes, delete"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to delete this Puppy?</p>
      </Modal>

      {/* Update Modal */}
      <Modal
        title="Update Puppy Status"
        open={showUpdateConfirm}
        onOk={handleConfirmUpdate}
        onCancel={() => setShowUpdateConfirm(false)}
        okText="Update"
        cancelText="Cancel"
        centered
      >
        <p>Enter the new status for the Puppy:</p>
      </Modal>
  </div>
  );
};

export default DeletedParent;
