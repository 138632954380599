import React, { useEffect, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import {
  useAllTEAMS,
  useDeleteTEAMSMutation,
  useUpdateTEAMSMutation,
} from "../../../features/Teams/teamsService";
import { useDisclosure, useToast } from "@chakra-ui/react";
import TeamsForm from "./TeamsForm";
import { GlobalModalV1 } from "../../../components/GlobalModal";
import { Modal } from "antd";
import EditBtn from "../../../components/EditBtn";
import DeleteBtn from "../../../components/DeleteBtn";

const TableTeams = () => {
  const [showPage, setShowPage] = useState(false);
  const { data: teams, isLoading, error } = useAllTEAMS("/api/teams");
  console.log("teams", teams);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  const pageHandler = () => {
    setShowPage(!showPage);
  };

  const updateMutation = useUpdateTEAMSMutation();
  const deleteMutation = useDeleteTEAMSMutation();

  const btnComponent = (props) => {
    const handleEdit = () => {
      setSelectedIdEdit(props?.data?._id);
      onOpen();
    };

    return (
      <div className="flex gap-5 pt-2">
        <EditBtn onClick={handleEdit} />
        <DeleteBtn onClick={() => handleDeleteClick(props?.data?._id)} />
      </div>
    );
  };

  const handleDelete = () => {
    deleteMutation.mutate(
      { apiUrl: `/api/team/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast({
            title: "TEAM deleted successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error deleting TEAM",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = teams?.filter((item) => item?._id === selectedIdEdit);
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    setSelectedId(itemToDelete);
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const columns = [
    { headerName: "Name", field: "name", editable: false }, // Maps to 'name'
    { headerName: "Title", field: "title", editable: false }, // Maps to 'title'
    { headerName: "Description", field: "description", editable: false }, // Maps to 'description'
    { headerName: "Quote", field: "quote", editable: false }, // Maps to 'quote'
    { headerName: "Author Block", field: "authorBlock", editable: false }, // Maps to 'authorBlock'
    // { headerName: "Primary Photo", field: "primaryPhoto", editable: false }, // Maps to 'primaryPhoto'
    { headerName: "Display Order", field: "displayOrder", editable: false }, // Maps to 'displayOrder'
    { headerName: "Favorites breeds", field: "favoriteBreed" },
    {
      headerName: "Active",
      field: "active",
      editable: false,
    }, // Maps to 'active'
    {
      headerName: "Show On Team Page",
      field: "showOnTeamPage",
      editable: false,
    }, // Maps to 'showOnTeamPage'
    { headerName: "Action", cellRenderer: btnComponent, pinned: 'right' , width: '100%' },
  ];

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
  };
  return (
    <div className="my-5">
      <div
        className="ag-theme-quartz"
        style={{ height: 500, overflowX: "auto" }}
      >
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={teams}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"800px"}>
        <TeamsForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>

      <Modal
        title="Confirm Delete"
        open={showDeleteConfirm}
        onOk={handleConfirmDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Yes, delete"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to delete this team member?</p>
      </Modal>
    </div>
  );
};

export default TableTeams;
