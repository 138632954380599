import React from "react";

import { GlobalModalV1 } from "../../components/GlobalModal";
import { useDisclosure } from "@chakra-ui/react";
import EmailForms from "./components/EmailForms";
import EmailTable from "./components/EmailTable";

const Email = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  return (
    <div className="my-10">
    <div className="flex flex-1 flex-col">
      <button
        onClick={onOpen}
        className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white"
      >
        Add Email
      </button>

      <GlobalModalV1 onClose={onClose} isOpen={isOpen} width={"800px"}>
        <EmailForms onClose={onClose} isOpen={isOpen} />
      </GlobalModalV1>
    </div>
    <EmailTable />
  </div>
  );
};

export default Email;
