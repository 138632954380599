import { Tooltip } from "antd";
import React from "react";
import { IoIosUndo } from "react-icons/io";

const UndoBtn = ({ onClick, ...props }) => {
  return (
    <Tooltip title="Undo Changes" color="#ffa500">
      <button
        className={`rounded-full bg-[#ffa500] p-1 text-white`}
        onClick={onClick}
        onMouseDown={(e) => e.stopPropagation()}
        {...props}
      >
        <IoIosUndo />
      </button>
    </Tooltip>
  );
};

export default UndoBtn;
