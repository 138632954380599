import { Tooltip } from "antd";
import React from "react";
import { LiaTimesSolid } from "react-icons/lia";

const DeleteBtn = ({ onClick, ...props }) => {
  return (
    <Tooltip title="Delete Item" color="#EE5D50">
      <button
        className={`rounded-full bg-[#EE5D50] p-1 text-white`}
        onClick={onClick}
        onMouseDown={(e) => e.stopPropagation()}
        {...props}
      >
        <LiaTimesSolid />
      </button>
    </Tooltip>
  );
};

export default DeleteBtn;
