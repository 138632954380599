import React, { useMemo } from "react";
import PendingOffers from "./components/PendingOffers";
import RejectedOffers from "./components/RejectedOffers";
import ChakraTabs from "../../components/ChakraTabs";
import { useAllOffer } from "../../features/Offer/OfferService";



const Offers = () => {

  
const {data: offers=[], isLoading, error} = useAllOffer('/api/offers');
console.log('offers===>', offers)

const pendingData = useMemo(()=> offers?.filter((offer)=>offer.status === "pending"), [offers])
console.log("pendingData", pendingData)
const rejectedData = useMemo(()=> offers?.filter((offer)=> offer.status === 'rejected'),[offers] )

  const tabData = [
    {
      label: "Pending",
      content: <PendingOffers filterData={pendingData} isLoading={isLoading} error={error}/>,
    },
    {
      label: "Rejected",
      content: <RejectedOffers filterData={rejectedData} isLoading={isLoading} error={error} />,
    },
  ];
  return (
    <div>
      <ChakraTabs tabData={tabData} />
    </div>
  );
};

export default Offers;
