import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../utils/uploadFile";


import moment from "moment";
import dayjs from "dayjs";

import { useCreateTargetCities, useUpdateTargetCities } from "../../../features/TargetCities/targetCitiesServices";

const { TextArea } = Input;

const TargetCitiesForm = ({ onClose, selectedData, isUpdateModal }) => {
//   const { data: products } = useAllBreeds("/api/products/");
  
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreateTargetCities();
  const UpdateMutation = useUpdateTargetCities();

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
       
        id: selectedData[0]?.id ?? null,
        name: selectedData[0]?.name ?? null,
        state: selectedData[0]?.state ?? null,
        latitude: selectedData[0]?.latitude ?? null,
        longitude: selectedData[0]?.longitude ?? null,
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
    
      id: values.id ?? null,
      name: values.name ?? null,
      state: values.state ?? null,
      latitude: values.latitude ?? null,
      longitude: values.longitude?? null,
    
    };

    try {
      if (isUpdateModal && selectedData) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/target-city/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "Target City Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/target-city",
          data,
        });

        toast({
          title: "Target City created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      // Reset form and clear states if needed
      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Target City Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating Target Cities",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Target Cities
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            

            <Form.Item
              name="id"
              label="ID"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="ID" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Name" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="State" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="latitude"
              label="Latitude"
              initialValue={0}
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Latitude" disabled={isLoading} />
            </Form.Item>

              <Form.Item
                name="longitude"
                label="Longitude"
                initialValue={0}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Longitude" disabled={isLoading} />
              </Form.Item>

          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button className="!bg-[#001219] !text-white" type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default TargetCitiesForm;
