

import React from 'react'
import ChakraTabs from '../../components/ChakraTabs'
import Puppies from './components/Puppies'
import Registration from './components/Registration'
import Breeds from './Breeds'





const OrdersTabs = () => {
  const tabData = [
    {
        label: 'Puppies',
        content: <Puppies/>,
    },
    {
        label: 'Registration',
        content: <Registration />,
    }, 
    {
        label: 'Breeds',
        content: <Breeds />
    }
  ]

  return (
    <div>
      <ChakraTabs tabData={tabData} /> 
    </div>
  )
}

export default OrdersTabs
