import React from "react";
import Methods from "./components/Methods";
import Locations from "./components/Locations";
import ChakraTabs from "../../components/ChakraTabs";

const DeliveryOptions = () => {
  const tabData = [
    // {
    //   label: "Methods",
    //   content: <Methods />,
    // },
    {
      label: "Locations",
      content: <Locations />,
    },
  ];
  return (
    <div>
      <ChakraTabs tabData={tabData} />
    </div>
  );
};

export default DeliveryOptions;
