import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";
import {
  useAllCategory,
  useCreateCategory,
  useUpdateCategoryMutation,
} from "../../../../features/Category/Category";

const { TextArea } = Input;

const CategoryForm = ({ onClose, selectedData, isUpdateModal }) => {
  const { data: categories } = useAllCategory("/api/categories/");

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreateCategory();
  const updateMutation = useUpdateCategoryMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        name: selectedData[0]?.name ?? null,
        metaDescription: selectedData[0]?.metaDescription ?? null,
        pageTitle: selectedData[0]?.pageTitle ?? null,
        required: selectedData[0]?.required ?? null,
        slug: selectedData[0]?.slug ?? null,
        special: selectedData[0]?.special ?? null,
      });
    }
  }, [selectedData, isUpdateModal, form]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
      name: values.name ?? null,
      metaDescription: values.metaDescription ?? null,
      pageTitle: values.pageTitle ?? null,
      required: values.required ?? null,
      slug: values.slug ?? null,
      special: values.special ?? null,
    };

    try {
      if (isUpdateModal && selectedData) {
        await updateMutation.mutateAsync({
          apiUrl: `/api/category/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "Category Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/category",
          data,
        });

        toast({
          title: "Category created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      // Reset form and clear states if needed
      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Category Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating Category",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Meta Description"
          name="metaDescription"
          rules={[
            { required: true, message: "Please input the meta description!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Page Title"
          name="pageTitle"
          rules={[{ required: true, message: "Please input the page title!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Slug"
          name="slug"
          rules={[{ required: true, message: "Please input the slug!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Required" name="required" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item label="Special" name="special" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isUpdateModal ? "Update Category" : "Create Category"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CategoryForm;
