import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";

const ChakraTabs = ({ tabData }) => {
  return (
    <Tabs>
      <TabList>
        {tabData.map((tab, index) => (
          <Tab key={index}>{tab.label}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabData.map((tab, index) => (
          
          <TabPanel key={index} p={0}>
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default ChakraTabs;
