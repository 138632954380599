import React, { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../components/GlobalModal";

import BreedTables from "./components/BreedTables";
import BreedForms from "./BreedForms";

const Breeds = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className="my-10">
      <div className="flex flex-1 flex-col">
        <button
          onClick={onOpen}
          className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white"
        >
          Add New Record
        </button>

        <GlobalModalV1 onClose={onClose} isOpen={isOpen} width={"800px"}>
          <BreedForms onClose={onClose} isOpen={isOpen} />
        </GlobalModalV1>
      </div>
      <BreedTables />
    </div>
  );
};

export default Breeds;
