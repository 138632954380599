import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useToast } from "@chakra-ui/react";
import {
  useCreateTEAMS,
  useUpdateTEAMSMutation,
} from "../../../features/Teams/teamsService";
import { uploadFiles } from "../../../utils/uploadFile";
import { useAllBreeds } from "../../../features/Breeds/Breeds";
import { ReactSortable } from "react-sortablejs";
import { deleteImages, handleBeforeUpload } from "../../../utils";

const TeamsForm = ({ onClose, selectedData, isUpdateModal }) => {
  const [form] = Form.useForm();
  const toast = useToast();
  const [media, setMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [favoriteBreed, setFavoriteBreeds] = useState(null);
  const createMutation = useCreateTEAMS();
  const updateMutation = useUpdateTEAMSMutation();
  const { data: breeds, isLoadingBreed, error } = useAllBreeds("/api/breeds");
  console.log("breeds", breeds);

  console.log(selectedData, "selectedData");

  useEffect(() => {
    if (breeds) {
      const breedNames = breeds.map(
        (breed) => breed.breedInformation.breedName,
      );
      setFavoriteBreeds(breedNames);
    }
  }, [breeds]);

  console.log(favoriteBreed);

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        name: selectedData[0]?.name ?? null,
        title: selectedData[0]?.title ?? null,
        displayOrder: selectedData[0]?.displayOrder ?? null,
        description: selectedData[0]?.description ?? null,
        quote: selectedData[0]?.quote ?? null,
        authorBlock: selectedData[0]?.authorBlock ?? null,
        active: selectedData[0]?.active ?? false,
        showOnTeamPage: selectedData[0]?.showOnTeamPage ?? false,
        favoriteBreed: selectedData[0]?.favoriteBreed ?? null,
      });
      // setMedia(selectedData[0]?.media ?? []);
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const data = {
        name: values.name ?? null,
        title: values.title ?? null,
        displayOrder: values.displayOrder ?? null,
        description: values.description ?? null,
        quote: values.quote ?? null,
        authorBlock: values.authorBlock ?? null,
        active: values.active ?? false,
        showOnTeamPage: values.showOnTeamPage ?? false,
        favoriteBreed: values.favoriteBreed ?? null,
      };

      let photoUrls = [];
      if (media?.length) {
        const newPhotos = media.filter((photo) => !photo.url);
        const existingPhotos = media
          .filter((photo) => photo.url)
          .map((photo) => photo.url);

        if (newPhotos.length) {
          const uploadedPhotoUrls = await uploadFiles(
            newPhotos.map((photo) => photo.originFileObj),
          );
          photoUrls = [...existingPhotos, ...uploadedPhotoUrls];
          data.media = photoUrls;
        } else {
          photoUrls = existingPhotos;
        }
      }

      if (isUpdateModal && selectedData) {
        await updateMutation.mutateAsync({
          apiUrl: `/api/team/${selectedData[0]._id}`,
          data,
        });

        toast({
          title: "Team Member Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/team",
          data,
        });
        setMedia([]);
        toast({
          title: "Team Member created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title:
          error.response?.data?.details ||
          error.message ||
          "Error processing request",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // const handleBeforeUpload = (file) => {
  //   const newFile = {
  //     ...file,
  //     uid: file.uid,
  //     name: file.name,
  //     status: "done",
  //     originFileObj: file,
  //   };

  //   setMedia((prev) => [...prev, newFile]);
  //   return false;
  // };

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <h2 className="pb-10 text-2xl font-bold">Team</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
      >
        <div>
          <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
            Member Information
          </div>
          <Form.Item label="Name" name="name">
            <Input placeholder="Name" disabled={isLoading} />
          </Form.Item>
          <Form.Item label="Title" name="title">
            <Input placeholder="Title" disabled={isLoading} />
          </Form.Item>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <Form.Item label="Display Order" name="displayOrder">
              <InputNumber
                style={{ width: "100%" }}
                placeholder="0"
                disabled={isLoading}
              />
            </Form.Item>
            <Form.Item name="active" valuePropName="checked">
              <Checkbox disabled={isLoading}>Active</Checkbox>
            </Form.Item>
            <Form.Item name="showOnTeamPage" valuePropName="checked">
              <Checkbox disabled={isLoading}>Show On Team Page</Checkbox>
            </Form.Item>
          </div>
          <Form.Item label="Description" name="description">
            <TextArea
              rows={3}
              placeholder="Add description"
              disabled={isLoading}
            />
          </Form.Item>
          <Form.Item label="Quote" name="quote">
            <TextArea rows={3} placeholder="Add quote" disabled={isLoading} />
          </Form.Item>
          <Form.Item label="Author Block" name="authorBlock">
            <TextArea
              rows={3}
              placeholder="Add author block"
              disabled={isLoading}
            />
          </Form.Item>

          <div>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Favorite Breeds
            </div>
            <Form.Item name="favoriteBreed" label="Favorite Breeds">
              <Select mode="multiple" placeholder="Select Restricted Breeds">
                {favoriteBreed?.map((breed, index) => (
                  <Select.Option key={index} value={breed}>
                    {breed}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <Form.Item
            valuePropName="fileList"
            getValueFromEvent={normFile}
            name="media"
            className="flex items-center justify-center"
          >
            <ReactSortable
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              list={media}
              setList={setMedia}
              className="flex cursor-move flex-wrap gap-4 pb-4"
            >
              {media.map((file) => (
                <Upload
                  key={file.uid}
                  listType="picture-card"
                  fileList={[file]}
                  showUploadList={{
                    showPreviewIcon: true,
                    showRemoveIcon: true,
                  }}
                  onRemove={() => deleteImages(file, setMedia)}
                  disabled={isLoading}
                />
              ))}
            </ReactSortable>
            {media.length < 5 && (
              <Upload
                listType="picture-card"
                beforeUpload={(file) => handleBeforeUpload(file, setMedia)}
                showUploadList={false}
                multiple
                disabled={isLoading}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Select Files</div>
                </div>
              </Upload>
            )}
            <span className="text-red-600">
              *Image dimensions should be 1200x800
            </span>
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            {isUpdateModal ? "Update" : "Create"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TeamsForm;
