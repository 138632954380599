import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GET_ALL_EMAIL } from "./emailAndSmsTypes";
import { create, Delete, fetchAll, update } from "../Apis/Apis";

export const useAllEmail = (apiUrl) => {
  return useQuery({
    queryKey: [GET_ALL_EMAIL],
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: create,
    onMutate: (new_email) => {
      queryClient.invalidateQueries(GET_ALL_EMAIL);
    },
    onError: (error, context, new_email) => {
      console.log("error creating email service", error);
    },
    onSuccess: (data) => {
      queryClient.cancelQueries(GET_ALL_EMAIL);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_EMAIL);
    },
  });
};

export const useUpdateEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: update,
    onMutate: (new_email) => {
      queryClient.invalidateQueries(GET_ALL_EMAIL);
    },
    onError: (error, context, new_email) => {
      console.log("error updating email service", error);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_EMAIL);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_EMAIL);
    },
  });
};

export const useDeleteEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: Delete,
    onMutate: (new_email) => {
      queryClient.invalidateQueries(GET_ALL_EMAIL);
    },
    onError: (error, context, new_email) => {
      console.log("error deleting email service", error);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_EMAIL);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_EMAIL);
    },
  });
};
