import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
} from "./actionTypes";

const initialState = {
  user: null,
  isUserAuth: false,
  isAdminAuth: false,
  error: null,
  isPending: false,
};

const authReducer = ( state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isUserAuth: false,
        isAdminAuth: false,
        error: null,
        isPending: false,
      };
    case REGISTER_FAILURE:
    case LOGIN_FAILURE:
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        user: null,
        isUserAuth: false,
        isAdminAuth: false,
        error: action.payload,
        isPending: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isUserAuth: action.payload.role === "user",
        isAdminAuth: action.payload.role === "admin",
        error: null,
        isPending: false,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          accessToken: action.payload.accessToken,
        },
        error: null,
        isPending: false,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        isUserAuth: false,
        isAdminAuth: false,
        error: null,
        isPending: false,
      };
    default:
      return state;
  }
};

export default authReducer;
