import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { GET_ALL_TEAMS } from "./teamsTypes.js";
import { create, Delete, fetchAll, update } from "../Apis/Apis.js";

export const useAllTEAMS = (apiUrl) => {
  return useQuery({
    queryKey: [GET_ALL_TEAMS],
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateTEAMS = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_TEAMS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating TEAMS:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_TEAMS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_TEAMS);
    },
  });
};

export const useDeleteTEAMSMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_TEAMS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating TEAMS:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_TEAMS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_TEAMS);
    },
  });
};

export const useUpdateTEAMSMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_TEAMS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating TEAMS:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_TEAMS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_TEAMS);
    },
  });
};

