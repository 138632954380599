import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
} from "react-icons/ai";
import {
  useAllFAQS,
  useAllFAQS_CATEGORY,
  useDeleteFAQS_CATEGORYMutation,
  useDeleteFAQSMutation,
  useUpdateFAQS_CATEGORYMutation,
  useUpdateFAQSMutation,
} from "../../../features/Faqs/faqsService.js";
import GlobalLoader from "../../../components/GlobalLoader";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../../components/GlobalModal.jsx";
import FaqsForm from "./FaqsForm.jsx";
import AntModal from "../../../components/AntModal.jsx";
import EditBtn from "../../../components/EditBtn.jsx";
import DeleteBtn from "../../../components/DeleteBtn.jsx";
import CategoriesForm from "./CategoriesForm.jsx";

const ConfirmCancelButtons = ({ onConfirm, onCancel }) => (
  <div className="flex gap-5 pt-2">
    <button
      className="rounded-full bg-[#35f8fe] p-1 text-white"
      onClick={onConfirm}
    >
      <AiFillCheckCircle />
    </button>
    <button
      className="rounded-full bg-[#EE5D50] p-1 text-white"
      onClick={onCancel}
    >
      <AiFillCloseCircle />
    </button>
  </div>
);

const Categories = () => {
  const [rowData, setRowData] = useState([]);
  const [newData, setNewData] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [isOpen2, setIsOpen2] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState(null);

  const onOpen2 = () => setIsOpen2(true);
  const onClose2 = () => setIsOpen2(false);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: categories, isLoading, error } = useAllFAQS_CATEGORY("/api/faq-categories");

  console.log(categories, "categories");


  const updateMutation = useUpdateFAQS_CATEGORYMutation();
  const deleteMutation = useDeleteFAQS_CATEGORYMutation();

  const handleConfirm = () => {
    setIsModalVisible(false);
    console.log("confirm");
    deleteMutation.mutate(
      { apiUrl: `/api/faq-category/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast({
            title: "Faqs Category deleted successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error deleting Faqs Category",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
    setActionType(null);
    setSelectedId(null);
  };

  const btnComponent = (props) => {
    const handleEdit = () => {
      setSelectedIdEdit(props?.data?._id);
    };

    const handleDeleteClick = () => {
      setSelectedId(props?.data?._id);
    };

    return (
      <div className="flex gap-5 pt-2">
        <EditBtn onClick={handleEdit} />
        <DeleteBtn onClick={handleDeleteClick} />
      </div>
    );
  };

  const handleDelete = () => {
    setActionType("delete");
    setIsModalVisible(true);
  };

  const handleUpdate = () => {
    const filter = categories?.filter((item) => item?._id === selectedIdEdit);
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
    onOpen2(true);
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
    }
    if (selectedIdEdit) {
      handleUpdate();
    }
  }, [selectedId, selectedIdEdit]);

  useEffect(() => {
    if (categories) {
      setRowData(categories);
    }
  }, [categories]);

  const gridRef = useRef();

  


  const columns = [

    { headerName: "Name", field: "category", editable: false },
    
    { headerName: "Action", cellRenderer: btnComponent, pinned: 'right' , width: '100%' },
  ];

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    flex: 1,
    floatingFilter: true,
  };

  return (
    <div className="my-5">
      <button
        onClick={onOpen}
        className="my-2 self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white"
      >
        + Add New Record
      </button>
      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          ref={gridRef}
          modules={[ClientSideRowModelModule]}
          rowData={rowData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="single"
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
          onCellValueChanged={(params) => {
            setNewData(params.data);
          }}
        />
      </div>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"800px"}>
        <CategoriesForm
          onClose={onClose}
      
        />
      </GlobalModalV1>

      <GlobalModalV1 isOpen={isOpen2} onClose={onClose2} width={"800px"}>
        <CategoriesForm
          onClose={onClose2}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>

      <AntModal
        centered
        isOpen={isModalVisible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={
          actionType === "approve" ? "Confirm Approval" : "Confirm Deletion"
        }
        content={
          actionType === "approve"
            ? "Are you sure you want to approve this faqs?"
            : "Are you sure you want to delete this faqs?"
        }
        okText={"Confirm"}
      />
    </div>
  );
};

export default Categories;
