import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Plan from "./components/Plan";
import ChakraTabs from "../../components/ChakraTabs";

const Deliveries = () => {
  const tabData = [
    {
      label: "Plan",
      content: <Plan />,
    },
  ];
  return (
    <div>
      <ChakraTabs tabData={tabData} />
    </div>
  );
};

export default Deliveries;
