import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css"; // Add this line
import React, { useEffect, useRef, useState } from "react";

import { Delta } from "quill/core";
import TextEditor from "./components/TextEditor";
import TestForm from "./components/TestForm";
// Remove the import of the custom Editor component

// Add this import for the custom CSS

const Tiptap = () => {
  return (
    <div className="mt-48">
      <TestForm />
    </div>
  );
};

export default Tiptap;
