import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { GET_ALL_SPECIAL_REQUEST } from "./specialRequestTypes"
import { create, Delete, fetchAll, update } from "../Apis/Apis.js";


export const useAllSpecialRequest = (apiUrl)=> {
    return useQuery({
        queryKey: [GET_ALL_SPECIAL_REQUEST],
        queryFn: () => fetchAll(apiUrl)
    })
}

export const useCreateSpecialRequest = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: create,
        onMutate: (new_SpecialRequest)=> {
            queryClient.invalidateQueries(GET_ALL_SPECIAL_REQUEST)
        },
        onError: (error, context, new_SpecialRequest) => {
            console.log('error creating special request', error)
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_SPECIAL_REQUEST)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_SPECIAL_REQUEST)
        }
    })
}

export const useUpdateSpecialRequest = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: update,
        onMutate: (new_SpecialRequest) => {
            queryClient.invalidateQueries(GET_ALL_SPECIAL_REQUEST)
        },
        onError: (new_SpecialRequest, error, context) => {
            console.log('error updating special request')
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_SPECIAL_REQUEST)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_SPECIAL_REQUEST)
        }
    })
}

export const useDeleteSpecialRequest = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: Delete,
        onMutate: (new_SpecialRequest) => {
            queryClient.invalidateQueries(GET_ALL_SPECIAL_REQUEST)
        },
        onError: (error, context, new_SpecialRequest) => {
            console.log('error deleting special request', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_SPECIAL_REQUEST)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_SPECIAL_REQUEST)
        }
    })
}