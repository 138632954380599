import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from "redux-persist";
import { setupAxiosInterceptors } from "../api/axiosInstance";
import cartReducer from "../features/cart/cartReducer";
import buyNowReducer from "../features/buy-now/buyNowReducer";

const authPersistConfig = {
  key: "auth",
  storage,
};

const cartPersistConfig = {
  key: "cart",
  storage,
};

const buyNowPersistConfig = {
  key: "buyNow",
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  cart: persistReducer(cartPersistConfig, cartReducer),
  buyNow: persistReducer(buyNowPersistConfig, buyNowReducer),
});

const store = configureStore({
  reducer: rootReducer,
});

setupAxiosInterceptors(store);

const persistor = persistStore(store);

export { store, persistor };
