import React from "react";
import { AiFillInstagram } from "react-icons/ai";
import { FaSquareFacebook } from "react-icons/fa6";

import { footerInfo } from "../utils/data";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="poppins-regular nunito-sans min-h-[682px] w-full bg-primaryBlack-theme py-4">
      {/* <div className="relative mt-8 flex flex-col px-6 py-8 sm:px-32">
        <div className="flex flex-col">
          <a href="#" className="flex gap-3">
            <img src="/images/lOGO-white.png" alt="logo" width={120} />
          </a>
        </div>
        <div className="mt-24 grid grid-cols-1 gap-6 text-white sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
          <div className="flex gap-2 text-white">
            <AiFillInstagram size={26} />
            <p className="font-semibold"> Follow Us </p>
          </div>
          <div className="flex flex-col gap-8">
            <div>
              <h3 className="pb-6 text-xl font-bold">Contact Info</h3>
              <p className="text-lg font-normal">Payment Agents 803-830-6109</p>
            </div>
            <div>
              <h3 className="pb-6 text-xl font-bold">Our Address</h3>
              <p className="text-lg font-normal">
                187 Conifer Drive Prosperity SC 29127
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div>
              <h3 className="pb-6 text-xl font-bold">Simply Southern Pups</h3>
              <div className="space-y-3 text-lg font-normal">
                <p>Available Puppy Listing</p>
                <p>Local Pickup</p>
                <p>Customer Reviews</p>
                <p>Puppy Photo Gallery</p>
                <p>Our Team</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="text-lg font-normal">
              <h3 className="pb-6 text-xl font-bold">Learn About Our Breeds</h3>
              <p>Breed Information</p>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div>
              <h3 className="pb-6 text-xl font-bold">Puppy Health</h3>
              <div className="space-x-3 text-lg font-normal">
                <p>10 Year Health Guarantee</p>
                <p>Pet Insurance</p>
                <p>Pre-Screen Blood Tests</p>
                <p>DNA Testing</p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute -bottom-24 right-[280px] flex gap-[250px] py-6 text-xl font-semibold text-white">
          <p>Terms Of Use</p>
          <p>Privacy Policy</p>
        </div>
      </div> */}
      <div className="relative flex flex-col px-6 pt-4 sm:px-32 md:mt-8 md:pt-8">
        <div className="flex flex-col items-start">
          <div className="flex w-full flex-col items-center justify-between md:flex-row">
            <a href="/" className="flex gap-3">
              <img src="/images/logo-invert.svg" alt="logo-left" width={120} />
            </a>
            <a
              href="/"
              className="mt-20 flex flex-row items-center gap-3 text-white md:mt-0 md:flex-col"
            >
              <img
                src="/images/amazon_logo_icon_147320.png"
                alt="Amazon logo"
                width={80}
              />
              <span className="text-wrap text-base">
                Our Companion Care Favorites
              </span>
            </a>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-6 text-white sm:grid-cols-2 md:mt-24 md:grid-cols-3 lg:grid-cols-5">
          <div className="block">
            <a
              href="https://www.instagram.com/simplycavapoos"
              target="_blank"
              rel="noopener noreferrer"
              className="mb-4 flex items-center gap-2 text-white"
            >
              <AiFillInstagram size={28} />
              <p className="font-semibold"> Follow Us </p>
            </a>
            <a
              href="https://www.facebook.com/simplycavapoos"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-white"
            >
              <FaSquareFacebook size={28} />
              <p className="font-semibold"> Follow Us </p>
            </a>
          </div>

          {footerInfo.map((section, index) => (
            <div key={index} className="flex flex-col gap-8">
              <div>
                <h3 className="pb-6 text-xl font-bold">{section.title}</h3>
                <div className="space-y-3 text-lg font-normal">
                  {section.items.map((item, itemIndex) => (
                    <p key={itemIndex}>
                      {item.value ? (
                        <>
                          <span>{item.label}: </span>
                          <a href={item.link} className="hover:underline">
                            {item.value}
                          </a>
                        </>
                      ) : (
                        <a href={item.link} className="hover:underline">
                          {item.label}
                        </a>
                      )}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center gap-52 pb-4 pt-20 text-sm font-semibold text-white">
          <div>
            <p>&copy; 2024 Simply Cavapoos. All rights reserved.</p>
          </div>
          <div className="flex gap-6">
            <Link to="/shipping" target="_blank" rel="noopener noreferrer">
              Terms Of Use
            </Link>
            <Link to="/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
