import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { create, Delete, fetchAll, update } from "../Apis/Apis";
import {
  CREATE_EMAIL_CAMPAIGN,
  GET_ALL_CUSTOMER_EMAILS,
} from "./emailCampaignTypes";

export const useAllEmailList = (apiUrl) => {
  return useQuery({
    queryKey: [GET_ALL_CUSTOMER_EMAILS],
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateEmailCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: create,
    onMutate: (email_campaign) => {
      queryClient.invalidateQueries(CREATE_EMAIL_CAMPAIGN);
    },
    onError: (error, context, email_campaign) => {
      console.log("error creating email campaign", error);
    },
    onSuccess: (data) => {
      queryClient.cancelQueries(CREATE_EMAIL_CAMPAIGN);
    },
    onSettled: () => {
      queryClient.invalidateQueries(CREATE_EMAIL_CAMPAIGN);
    },
  });
};

// export const useUpdateEmailList = () => {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: update,
//     onMutate: (new_email) => {
//       queryClient.invalidateQueries(GET_ALL_EMAIL);
//     },
//     onError: (error, context, new_email) => {
//       console.log("error updating email service", error);
//     },
//     onSuccess: (data) => {
//       queryClient.invalidateQueries(GET_ALL_EMAIL);
//     },
//     onSettled: () => {
//       queryClient.invalidateQueries(GET_ALL_EMAIL);
//     },
//   });
// };

// export const useDeleteEmailList = () => {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: Delete,
//     onMutate: (new_email) => {
//       queryClient.invalidateQueries(GET_ALL_EMAIL);
//     },
//     onError: (error, context, new_email) => {
//       console.log("error deleting email service", error);
//     },
//     onSuccess: (data) => {
//       queryClient.invalidateQueries(GET_ALL_EMAIL);
//     },
//     onSettled: () => {
//       queryClient.invalidateQueries(GET_ALL_EMAIL);
//     },
//   });
// };
