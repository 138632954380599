import React from "react";
import { getPuppyAgeInWeeks } from "../../../../../../../utils";

const PupDetails = ({ data }) => {


  console.log("data pup details", data);

  const totalProductPrice = data?.products?.reduce((total, product) => {
    return total + (product.price || 0); 
  }, 0);

  const totalPuppyPrice = data?.puppies?.reduce((total, puppy) => {
    return total + (puppy.listingInformation?.price || 0); 
  }, 0);

  const grandTotal = (totalProductPrice || 0) + (totalPuppyPrice || 0);
  const discount = data?.orderSummary?.discount || 0;
  const salesTax = data?.orderSummary?.salesTax || 0;

  const orderTotal = grandTotal - discount + salesTax;

  

  return (
    <div className="my-5">
      <div className="flex justify-between">
        <div className="flex items-center justify-center gap-5">
          <div className="h-40 w-40 overflow-hidden">
            <img
              src={data?.puppies[0]?.listingInformation.photos[0]}
              alt="breed"
              className="h-40 w-40 rounded-2xl object-cover"
            />
          </div>
          <div className="flex">
            <div className="flex w-full flex-col leading-6">
              <p className="text-lg font-bold text-[#DE0796]">
                {data?.puppies[0]?.listingInformation?.heading}
              </p>
              <p className="font-bold">
                {data?.puppies[0]?.puppyInformation?.gender} -{" "}
                <span>{data?.puppies[0]?.listingInformation?.name}</span>{" "}
              </p>
              <p>
                1lb 6oz @{" "}
                <span>
                  {getPuppyAgeInWeeks(
                    data?.puppies[0]?.puppyInformation?.dateOfBirth,
                  )}
                </span>{" "}
                weeks
              </p>
              <p>
                Est Adult:
                <span>
                  {data?.puppies[0]?.listingInformation?.adultWeight} lb
                </span>
              </p>
              <br />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {data?.puppies[0]?.listingInformation?.photos.map((image, index) => (
            <div key={index} className="h-20 w-20 overflow-hidden">
              <img
                src={image}
                alt="breed"
                className="h-20 w-20 rounded-2xl object-cover"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="my-5">
        <div className="mb-4">
          <h4 className="py-6 text-lg font-bold">Acquisition Info</h4>
          <div className="">
            <table className="w-full min-w-[300px] table-auto sm:min-w-[400px] lg:min-w-[600px]">
              <tbody>
                <tr>
                  <td>Puppy Price</td>
                  <td>{data?.puppies[0]?.listingInformation?.price}</td>
                </tr>
          
                {data?.products.map((product, index) => {
                  return (
                    <tr key={index}>
                      <td>{product?.name}</td>
                      <td>{product?.price}</td>
                    </tr>
                  );
                })}

                <tr>
                  <td className="py-6 font-bold">Sub Total</td>
                  <td className="py-6 font-bold">$ {grandTotal}</td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>$ {data?.orderSummary?.discount}</td>
                </tr>
                <tr>
                  <td>Sales Tax</td>
                  <td className="pb-4">$ {data?.orderSummary?.salesTax}</td>
                </tr>

                <tr>
                  <td className="font-bold">Order Total</td>
                  <td className="font-bold">$ {orderTotal.toFixed(2)}</td>
                </tr>
                {/* <tr>
                  <td>Payment Received</td>
                  <td>$ 758.00</td>
                </tr>
                <tr>
                  <td>Remaining Balance</td>
                  <td>$ 86.01</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PupDetails;
