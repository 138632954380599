import React, { useState, useEffect } from "react";
import { Button, DatePicker, Dropdown, Space, Modal } from "antd";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { LiaTimesSolid } from "react-icons/lia";
import { MdKeyboardArrowDown } from "react-icons/md";
import GlobalLoader from "../../../components/GlobalLoader.jsx";
import { AiFillEdit } from "react-icons/ai";
import { useDisclosure, useToast } from "@chakra-ui/react";

import { GlobalModalV1 } from "../../../components/GlobalModal.jsx";

import {
  useAllInsurance,
  useDeleteInsurance,
} from "../../../features/Insurance/insuranceServices.js";
import { getDate } from "../../../utils/index.js";
import InsuranceForms from "./insuranceForms.jsx";
import EditBtn from "../../../components/EditBtn.jsx";
import DeleteBtn from "../../../components/DeleteBtn.jsx";

const InsuranceTables = () => {
  const {
    data: insurances,
    isLoading,
    error,
  } = useAllInsurance("/api/insurances");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const filterProduct = insurances?.map((product, index) => ({
    ...product,
    id: index + 1,
  }));

  console.log(filterProduct, "filterProduct");

  console.log(insurances, "insurances");
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const toast = useToast();

  const createDeleteMutation = useDeleteInsurance();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    setSelectedId(itemToDelete);
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const handleDelete = () => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/insurance/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "Insurance Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted Insurance");
          toast({
            title: "Error deleted Insurance",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = filterProduct?.filter(
      (item) => item?._id === selectedIdEdit,
    );
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
      onOpen();
    };
    return (
      <div className="flex gap-5 pt-2">
        <EditBtn onClick={handle} />
        <DeleteBtn onClick={() => handleDeleteClick(props.data._id)} />
      </div>
    );
  };

  const columns = [
    {
      headerName: "ID",
      field: "id",
    },
    {
      headerName: "Policy Number",
      field: "policyNumber",
    },
    {
      headerName: "Policy Company",
      field: "policyCompany",
    },
    {
      headerName: "Notes",
      field: "notes",
    },
    {
      headerName: "First Name",
      field: "orders.destinationAddress.firstName",
    },
    {
      headerName: "Last Name",
      field: "orders.destinationAddress.lastName",
    },

    {
      headerName: "Cell phone",
      field: "orders.contactInformation.cellPhone",
    },
    {
      headerName: "Email",
      field: "orders.contactInformation.emailAddress",
    },
    {
      headerName: "Order Date",
      valueGetter: (params) => getDate(params.data.orderDate),
    },
    {
      headerName: "Disposition Date",
      valueGetter: (params) => getDate(params.data.dispositionDate),
    },
    {
      headerName: "Order Id",
      field: "orders.orderId",
    },
    { headerName: "Action", cellRenderer: btnComponent, pinned: 'right' , width: '100%' },
  ];

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
  };

  const items = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
      danger: true,
    },
    {
      label: "4th menu item",
      key: "4",
      danger: true,
      disabled: true,
    },
  ];

  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading Insurance</p>;

  return (
    <div className="my-10">
      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={filterProduct}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <InsuranceForms
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>

      <Modal
        title="Confirm Delete"
        open={showDeleteConfirm}
        onOk={handleConfirmDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Yes, delete"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to delete this insurance record?</p>
      </Modal>
    </div>
  );
};

export default InsuranceTables;
