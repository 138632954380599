import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { GET_ALL_REVIEWS } from "./reviewsType.js";

import { create, Delete, fetchAll, update } from "../Apis/Apis.js";
import { GET_ALL_BREEDS } from "../Breeds/BreedsTypes.js";

export const useAllReviews = (apiUrl) => {
  return useQuery({
    queryKey: GET_ALL_REVIEWS,
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateReviews = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_REVIEWS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Reviews:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_REVIEWS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_REVIEWS);
    },
  });
};

export const useDeleteReviewsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_REVIEWS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error deleting Reviews:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_REVIEWS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_REVIEWS);
    },
  });
}

export const useUpdateReviewMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_REVIEWS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error Updating Reviews", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_REVIEWS)
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_REVIEWS)
    },
  });
}