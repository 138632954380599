import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../utils/uploadFile";

import moment from "moment";
import dayjs from "dayjs";

import {
  useCreateSpecialRequest,
  useUpdateSpecialRequest,
} from "../../../features/Special-request/specialRequestServices";

const { TextArea } = Input;

const SpecialRequestForm = ({ onClose, selectedData, isUpdateModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  console.log("selectedData", selectedData)



  const createMutation = useCreateSpecialRequest();
  const UpdateMutation = useUpdateSpecialRequest();

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        // media: selectedData[0]?.media[0] ?? null,
        // puppyInformation: {
        //   breeder: selectedData[0]?.puppyInformation.breeder ?? null,
          // dogName: selectedData[0]?.order?.puppies[0]?.puppyInformation?.breed?.breedInformation?.breedName ?? null,
          // chip: selectedData[0]?.order?.puppies[0]?.puppyInformation?.idNumber ?? null,
        //   breed: selectedData[0]?.puppyInformation.breed ?? null,
        //   dateOfBirth: selectedData[0]?.puppyInformation.dateOfBirth ?? null,
        // },
        customerPhotos: {
          // name: selectedData[0]?.customerPhotos.name ?? null,
          email: selectedData[0]?.customerPhotos?.email ?? null,
          phone: selectedData[0]?.order?.contactInformation?.cellPhone?? null,
          lastName: selectedData[0]?.customerPhotos?.lastName ?? null,
          pupSoldTo: selectedData[0]?.customerPhotos?.lastName ?? null,
        },
        requestInformation: {
          notes: selectedData[0]?.requestInformation?.notes ?? null,
          sentBy: selectedData[0]?.requestInformation?.sentBy ?? null,
          video: selectedData[0]?.requestInformation?.video ?? null,
          birthCertificate:
            selectedData[0]?.requestInformation?.birthCertificate ?? null,
          breederInfo: selectedData[0]?.requestInformation?.breederInfo ?? null,
          appt: selectedData[0]?.requestInformation?.appt ?? null,
          asap: selectedData[0]?.requestInformation?.asap ?? null,
          photo: selectedData[0]?.requestInformation?.photo ?? null,
          puppyHealth: selectedData[0]?.requestInformation?.puppyHealth ?? null,
          parentPhotos:
            selectedData[0]?.requestInformation?.parentPhotos ?? null,
          inHouse: selectedData[0]?.requestInformation?.inHouse ?? null,
          faceTime: selectedData[0]?.requestInformation?.faceTime ?? null,
          ofa: selectedData[0]?.requestInformation?.ofa ?? null,
          parentHealth:
            selectedData[0]?.requestInformation?.parentHealth ?? null,
          needsUpdate: selectedData[0]?.requestInformation?.needsUpdate ?? null,
        },
        cost: {
          price: selectedData[0]?.cost.price ?? null,
          chargeRemaining: selectedData[0]?.cost?.chargeRemaining ?? null,
        },
        sentBirthCertificate: selectedData[0]?.sentBirthCertificate ?? null
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
        // puppyInformation: {
        //     breeder: values?.puppyInformation.breeder ?? null,
        //     dogName: values?.puppyInformation.dogName ?? null,
        //     chip: values?.puppyInformation.chip ?? null,
        //     breed: values?.puppyInformation.breed ?? null,
        //     dateOfBirth: values?.puppyInformation.dateOfBirth ?? null,
        //   },
          customerPhotos: {
            email: values?.customerPhotos?.email ?? null,
            // phone: values?.customerPhotos.phone ?? null,
            lastName: values?.customerPhotos?.lastName ?? null,
            // pupSoldTo: values?.customerPhotos.pupSoldTo ?? null,
          },
          requestInformation: {
            notes: values?.requestInformation?.notes ?? null,
            sentBy: values?.requestInformation?.sentBy ?? null,
            video: values?.requestInformation?.video ?? null,
            birthCertificate:
              values?.requestInformation?.birthCertificate ?? null,
            breederInfo: values?.requestInformation?.breederInfo ?? null,
            appt: values?.requestInformation?.appt ?? null,
            asap: values?.requestInformation?.asap ?? null,
            photo: values?.requestInformation.photo ?? null,
            puppyHealth: values?.requestInformation.puppyHealth ?? null,
            parentPhotos:
              values?.requestInformation?.parentPhotos ?? null,
            inHouse: values?.requestInformation?.inHouse ?? null,
            faceTime: values?.requestInformation?.faceTime ?? null,
            ofa: values?.requestInformation.ofa ?? null,
            parentHealth:
              values?.requestInformation.parentHealth ?? null,
            needsUpdate: values?.requestInformation?.needsUpdate ?? null,
          },
          cost: {
            price: values?.cost.price ?? null,
            chargeRemaining: values?.cost.chargeRemaining ?? null,
          },
          sentBirthCertificate: values?.sentBirthCertificate ?? null
    };

    if (media) {
      const primaryPhotoUrls = await uploadFiles(media);
      data.media = primaryPhotoUrls.length ? primaryPhotoUrls[0] : null;
    }
    try {
      await UpdateMutation.mutateAsync({
        apiUrl: `/api/special-request/${selectedData[0]?._id}`,
        data,
      })

      toast({
        title: "Special Request Updated successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      

      // Reset form and clear states if needed
      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Special Request Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating Special Request",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [media, setMedia] = useState([]);

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Special Requests
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <Form.Item
              label="media"
              name={"media"}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                listType="picture-card"
                fileList={media}
                beforeUpload={(file) => {
                  setMedia((prev) => [...prev, file]);
                  return false; // Prevent automatic upload
                }}
                onRemove={(file) => {
                  setMedia(media.filter((f) => f.uid !== file.uid));
                }}
                multiple={true} // Ensure only one file can be uploaded
                disabled={isLoading}
              >
                {media?.length < 5 && (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>

            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Puppy Information
            </div>

            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                name={["puppyInformation", "breeder"]}
                label="Breeder"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                disabled
              >
                <Input placeholder="Breeder" disabled />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "dogName"]}
                label="Dog Name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Dog Name" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "chip"]}
                label="Chip"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Chip" disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name={["puppyInformation", "breed"]}
                label="Breed"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Breed" disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name={["puppyInformation", "dateOfBirth"]}
                label="Date Of Birth"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="dateOfBirth" disabled={isLoading} />
              </Form.Item>
            </div>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Customer Information
            </div>
            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                name={["customerPhotos", "email"]}
                label="Email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Email" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["customerPhotos", "phone"]}
                label="Phone"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Phone" disabled />
              </Form.Item>

              <Form.Item
                name={["customerPhotos", "lastName"]}
                label="Last Name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Last Name" disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name={["customerPhotos", "pupSoldTo"]}
                label="Pup Sold To"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Pup Sold To" disabled/>
              </Form.Item>
            </div>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Request Information
            </div>
            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                name={["requestInformation", "notes"]}
                label="Notes"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Notes" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["requestInformation", "sentBy"]}
                label="Sent By"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Sent By" disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name={["requestInformation", "video"]}
                valuePropName="checked"
                label="Video"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "birthCertificate"]}
                valuePropName="checked"
                label="Birth Certificate"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "breederInfo"]}
                valuePropName="checked"
                label="Breeder Info"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "appt"]}
                valuePropName="checked"
                label="Appt"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "asap"]}
                valuePropName="checked"
                label="asap"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "photo"]}
                valuePropName="checked"
                label="photo"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "puppyHealth"]}
                valuePropName="checked"
                label="Puppy Health"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "parentPhotos"]}
                valuePropName="checked"
                label="Parent Photos"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "inHouse"]}
                valuePropName="checked"
                label="In House"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "faceTime"]}
                rules={[{ required: true }]}
                valuePropName="checked"
                label="Face Time"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "ofa"]}
                valuePropName="checked"
                label="ofa"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "parentHealth"]}
                valuePropName="checked"
                label="parentHealth"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name={["requestInformation", "needsUpdate"]}
                valuePropName="checked"
                label="needsUpdate"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
              <Form.Item
                name="sentBirthCertificate"
                valuePropName="checked"
                label="sentBirthCertificate"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 1 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>
            </div>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Cost
            </div>
            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                name={["cost", "price"]}
                label="Price"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Price" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["cost", "chargeRemaining"]}
                label="Charge Remaining"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>

             
            </div>
    
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button
              className="!bg-[#001219] !text-white"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default SpecialRequestForm;
