import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import {
  useCreateBreeder,
  useUpdateBreederMutation,
} from "../../../features/Breeder/breederService";
import { useAllBreeds } from "../../../features/Breeds/Breeds";

const BreederForm = ({ onClose, selectedData, isUpdateModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const { data: breeds } = useAllBreeds("/api/breeds/");

  const createMutation = useCreateBreeder();
  const UpdateMutation = useUpdateBreederMutation();

  const [form] = Form.useForm();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      console.log("Selected Data:", selectedData[0]);

      form.setFieldsValue({

        firstName: selectedData[0]?.firstName ?? null,
        lastName: selectedData[0]?.lastName ?? null,
        email: selectedData[0]?.email ?? null,
        phoneNo: selectedData[0]?.phoneNo ?? null,
        breeds: selectedData[0]?.breeds ?? null
      });
    }
  }, [selectedData, isUpdateModal, form]);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const data = {
        firstName: values.firstName ?? null,
        lastName: values.lastName ?? null,
        email: values.email ?? null,
        phoneNo: values.phoneNo ?? null,
        breeds: values.breeds ?? null
      };

      if (isUpdateModal && selectedData) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/breeder/${selectedData[0]?._id}`,
          data,
        });
        toast({
          title: "Breeder Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/breeder",
          data,
        });
        toast({
          title: "Breeder created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      form.resetFields();
      onClose();
    } catch (error) {
      console.error("Error details:", error);
      const errorMessage =
        error.response?.data?.message ||
        (error.response?.data?.details?.includes("duplicate")
          ? "Breeder Already Exists"
          : error.message || "Error creating Breeder");

      toast({
        title: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const breedOptions = breeds?.map((breed) => ({
    value: breed.breedInformation?.breedName,
    label: breed?.breedInformation?.breedName,
  }));

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">Breeder</h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="First Name" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Last Name" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Email" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="phoneNo"
              label="Cell Phone"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Cell Phone" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name={"breeds"}
              label="Breed"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                placeholder="Select a breed"
                style={{ width: "100%" }}
                allowClear
                options={breedOptions}
                disabled={isLoading}
              />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button
              className="!bg-[#001219] !text-white"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default BreederForm;
