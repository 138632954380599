import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import dayjs from "dayjs";
import { useCreateRegistration, useUpdateRegistration } from "../../features/Bookings/BookingService";




const { TextArea } = Input;

const RegistrationForms = ({ onClose, selectedData, isUpdateModal,  setSelectedData }) => {

  const isValidDateOfBirth = dayjs(selectedData[0]?.dateOfBirth).isValid();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreateRegistration();
  const UpdateMutation = useUpdateRegistration();

  const [form] = Form.useForm();

  console.log('selected===>',selectedData)
  console.log('isupdate', isUpdateModal)

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      const data = selectedData[0] || {};
      form.setFieldsValue({
        name: selectedData[0]?.name ?? null,
        breeder: selectedData[0]?.breeder ?? null,
        usdaName: selectedData[0]?.usdaName ?? null,
        breed: selectedData[0]?.breed?? null,
        dateOfBirth: selectedData[0]?.dateOfBirth ? dayjs(selectedData[0].dateOfBirth) : null,
        dispositionDate: selectedData[0]?.dispositionDate ? dayjs(selectedData[0].dispositionDate) : null,
        usdaId: selectedData[0]?.usdaId ?? null,
        gender: selectedData[0]?.gender ?? null,
        color: selectedData[0]?.color ?? null,
        registration: selectedData[0]?.registration ?? null,
        sent: selectedData[0]?.sent ? dayjs(selectedData[0].sent) :null,
        registrationNumber: selectedData[0]?.registrationNumber ?? null,
        sProgram: selectedData[0]?.sProgram ?? null,
        onlinePin: selectedData[0]?.onlinePin ?? null,
        rtPin: selectedData[0]?.rtPin ?? null,
        notes: selectedData[0]?.notes ?? null,
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
        name: values.name ?? null,
        breeder: values.breeder ?? null,
        usdaName: values.usdaName ?? null,
        breed: values.breed ?? null,
        dateOfBirth: values.dateOfBirth ?? null,
        usdaId: values.usdaId ?? null,
        gender: values.gender ?? null,
        color: values.color ?? null,
        registrationNumber: values.registrationNumber ?? null,
        registration: values.registration ?? null,
        sProgram: values.sProgram ?? null,
        dispositionDate: values.dispositionDate ?? null,
        sent: values.sent ?? null,
        onlinePin: values.onlinePin ?? null,
        rtPin: values.rtPin ?? null,
        notes: values.notes ?? null,
    };

    
     try {
      if (selectedData && selectedData.length > 0 && isUpdateModal) {
        
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/registration/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "registration Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/registration",
          data,
        });

        toast({
          title: "Registration created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
     }

      // Reset form and clear states if needed
      form.resetFields();
      onClose();
      setSelectedData([])

    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating Registration",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
            Add New Record
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
          <Form.Item
              name="name"
              label="name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Name" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="usdaName"
              label="usdaName"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="usdaName" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="breeder"
              label="breeder"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="breeder" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="breed"
              label="breed"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="breed" disabled={isLoading} />
            </Form.Item>
            

           

            <Form.Item
              name="usdaId"
              label="usdaId"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="usdaId" disabled={isLoading} />
            </Form.Item>
           
       

            <Form.Item
              name="color"
              label="color"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="color" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="registrationNumber"
              label="Registration Number"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="TA1223" disabled={isLoading} />
            </Form.Item>

            <div className="grid grid-cols-2 gap-5">
             
               <Form.Item
                name="dateOfBirth"
                label="Birth Date"
                
              >
                <DatePicker style={{ width: "100%" }} disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name="sent"
                label="sent"
              >
                <DatePicker style={{ width: "100%" }} disabled={isLoading} />
              </Form.Item>


              <Form.Item
                name="registration"
                label="Registration"
              >
                <Select
                  placeholder="Registration"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "n/a", value: "n/a" },
                    { label: "AKC", value: "AKC" },
                    { label: "ACA", value: "ACA" },
                    { label: "AKC/", value: "AKC/" },
                    { label: "CKC", value: "CKC" },
                    { label: "ICA", value: "ICA" },
                    { label: "Grad", value: "Grad" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>
            

              <Form.Item
                name="sProgram"
                label="S-Program"
              >
                <Select
                  placeholder="S-Program"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "PS", value: "PS" },
                    { label: "R", value: "R" },
                    { label: "Keep", value: "Keep" },
                    { label: "Pass", value: "pass" },
                    { label: "Sold", value: "Sold" },
                    { label: "Reqs", value: "Reqs" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name="onlinePin"
                label="Online Pin"
              >
                <Input placeholder="Online Pin" disabled={isLoading} />
              </Form.Item>

             
              <Form.Item
                name="gender"
                label="Gender"
       
              >
                <Select
                  placeholder="Male or Female"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name="dispositionDate"
                label="Disposition Date"
              >
                <DatePicker style={{ width: "100%" }} disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name="rtPin"
                label="RT Pin"
              >
                <Input placeholder="RT Pin" disabled={isLoading} />
              </Form.Item>


            </div>

            <Form.Item
              name="notes"
              label="Notes"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea placeholder="Notes" disabled={isLoading} />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button className="!bg-[#001219] !text-white" type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default RegistrationForms;
