import React, { useState, useEffect } from "react";
import {
  Button,
  DatePicker,
  Dropdown,
  Space,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { LiaTimesSolid } from "react-icons/lia";
import { MdCheck, MdKeyboardArrowDown } from "react-icons/md";
import GlobalLoader from "../../../components/GlobalLoader.jsx";
import { AiFillEdit } from "react-icons/ai";
import { useDisclosure, useToast } from "@chakra-ui/react";
import moment from "moment";
import { GlobalModalV1 } from "../../../components/GlobalModal";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  useAllOrders,
  useDeleteOrdersMutation,
  useUpdateOrdersMutation,
} from "../../../features/Orders/OrdersService.js";
import { getDate } from "../../../utils/index.js";
import OrdersForm from "./OrdersForm.jsx";
import CustomButton from "../../../components/Button.jsx";
import EditBtn from "../../../components/EditBtn.jsx";
import DeleteBtn from "../../../components/DeleteBtn.jsx";
import ViewBtn from "../../../components/ViewBtn.jsx";

const { TextArea } = Input;

const Sales = () => {
  const { data: orders, isLoading, error } = useAllOrders("/api/orders");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [form] = Form.useForm();
  const toast = useToast();

  // State management
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  // Mutations
  const updateMutation = useUpdateOrdersMutation();
  const createDeleteMutation = useDeleteOrdersMutation();
  const navigate = useNavigate();

  const transformOrders =
    orders?.map((order, index) => ({
      _id: order?._id,
      id: index + 1,
      firstName: order?.destinationAddress?.firstName ?? "",
      lastName: order?.destinationAddress?.lastName ?? "",
      cellPhone: order?.contactInformation?.cellPhone ?? "",
      orderDate: moment(order?.createdAt).format("DD-MM-YY") ?? "",
      subtotal: order?.orderSummary?.subtotal ?? 0,
      discount: order?.orderSummary?.discount ?? 0,
      salesTax: order?.orderSummary?.salesTax ?? 0,
      orderTotal: order?.orderSummary?.orderTotal ?? 0,
      coupon: order?.orderSummary?.coupon ?? "",
      orderStatus: order?.orderStatus ?? "",
      orderId: order.orderId ?? "",
      gender: order?.puppies?.[0]?.puppyInformation?.gender || "",
      createdAt: order?.createdAt ?? "",
    })) ?? [];

  // Delete handlers
  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    setSelectedId(itemToDelete);
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const handleDelete = () => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/order/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast({
            title: "Order Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          toast({
            title: "Error deleting order",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  console.log(transformOrders, "transformOrders");
  // Update handlers
  const handleUpdate = () => {
    const filter = transformOrders?.filter(
      (item) => item?._id === selectedIdEdit,
    );
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  console.log(selectedData, "selectedData");

  useEffect(() => {
    if (selectedId) {
      handleDelete();
    }
    if (selectedIdEdit) {
      handleUpdate();
    }
  }, [selectedId, selectedIdEdit]);

  // Form submission handler
  const onFinish = async (values) => {
    try {
      await updateMutation.mutateAsync({
        apiUrl: `/api/order/${selectedData[0]?._id}`,
        data: values,
      });

      toast({
        title: "Order Updated Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.message || "Error updating order",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const btnComponent = (props) => {
    const handle = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedIdEdit(props?.data?._id);
      onOpen();
    };

    return (
      <div
        className="flex gap-5 pt-2"
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <ViewBtn onClick={()=>viewOrder(props?.data?._id)} />
        <EditBtn onClick={handle} />
        <DeleteBtn
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDeleteClick(props.data._id);
          }}
        />

      </div>
    );
  };

  const columns = [
    { headerName: "ID", field: "id" },
    { headerName: "Order ID", field: "orderId" },
    { headerName: "First Name", field: "firstName" },
    { headerName: "Last Name", field: "lastName" },
    { headerName: "Cell Phone", field: "cellPhone" },
    {
      headerName: "Order Date",
      valueGetter: (params) => getDate(params.data.orderDate),
    },
    { headerName: "Subtotal", field: "subtotal" },
    { headerName: "Discount", field: "discount" },
    { headerName: "Sales Tax", field: "salesTax" },
    { headerName: "Order Total", field: "orderTotal" },
    { headerName: "Order Status", field: "orderStatus" },
    { headerName: "Coupon", field: "coupon" },
    { headerName: "Gender", field: "gender" },
    { headerName: "Action", cellRenderer: btnComponent, pinned: 'right' , width: '100%' },
  ];

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    floatingFilter: true,
  };

  const items = [
    { label: "1st menu item", key: "1" },
    { label: "2nd menu item", key: "2" },
    { label: "3rd menu item", key: "3", danger: true },
    { label: "4th menu item", key: "4", danger: true, disabled: true },
  ];

  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  const getFilteredOrders = (status) => {
    return transformOrders?.filter((order) => order.orderStatus === status);
  };

  if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading orders</p>;

  console.log(transformOrders, "transformOrders");
  console.log(orders, "orders");

  // const onRowClicked = (event) => {
  //   // Check if the click originated from an action button
  //   if (event.event.target.closest(".flex.gap-5.pt-2")) {
  //     return;
  //   }

  //   const rowData = event.data;
  //   const filterOrder = orders?.filter((item) => item._id === rowData._id);

  //   if (filterOrder?.length > 0) {
  //     const orderData = filterOrder[0];
  //     navigate(`/admin/orders/${orderData?._id}`, {
  //       state: { orderData },
  //     });
  //   }
  // };

  const viewOrder = (orderId) => {
    const filterOrder = orders?.filter((item) => item?._id === orderId);

    if (filterOrder?.length > 0) {
      const orderData = filterOrder[0];
      navigate(`/admin/orders/${orderData?._id}`, {
        state: { orderData },
      });
    }
  };

  return (
    <div className="my-10">
      <div
        className="ag-theme-quartz"
        style={{ height: 700, overflowX: "auto" }}
      >
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={getFilteredOrders("success")}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
          // onRowClicked={onRowClicked}
        />
      </div>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <OrdersForm
          onClose={onClose}
          selectedData={selectedData}
          isUpdateModal={true}
          setSelectedData={setSelectedData}
        />
      </GlobalModalV1>

      <Modal
        title="Confirm Delete"
        open={showDeleteConfirm}
        onOk={handleConfirmDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Yes, delete"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to delete this order?</p>
      </Modal>
    </div>
  );
};

export default Sales;
