import React from "react";
import { getPuppyAgeInWeeks } from "../../../../../../../utils";
import { useAllBreeds } from "../../../../../../../features/Breeds/Breeds";

const BreedDetails = ({ data }) => {
  const { data: breeds, isLoading, error } = useAllBreeds("/api/breeds/");

  // console.log("Breed details====>", breeds);
  const breedDetail = breeds?.find(
    (brd) =>
      brd._id === data?.puppies?.[0]?.puppyInformation?.breed ??
      "No Breed Details Found",
  );

  // const breedDetail = Array.isArray(data?.puppies?.[0]?.puppyInformation?.breed)
  // ? data.puppies[0].puppyInformation.breed.find((brd) => brd === breeds?._id)
  // : undefined;

  console.log("Filtered Breeds===>", breedDetail);

  // console.log("data breed details", data);

  const totalProductPrice = data?.products?.reduce((total, product) => {
    return total + (product.price || 0);
  }, 0);

  const totalPuppyPrice = data?.puppies?.reduce((total, puppy) => {
    return total + (puppy.listingInformation?.price || 0);
  }, 0);

  const grandTotal = (totalProductPrice || 0) + (totalPuppyPrice || 0);
  const discount = data?.orderSummary?.discount || 0;
  const salesTax = data?.orderSummary?.salesTax || 0;

  const orderTotal = grandTotal - discount + salesTax;

  return (
    <div className="my-5">
      {breedDetail ? (
        <div>
          <div className="flex justify-between">
            <div className="flex items-center justify-center gap-5">
              <div className="h-40 w-40 overflow-hidden">
                <img
                  src={breedDetail?.breedInformation.breedPhotos[0]}
                  alt="breed"
                  className="h-40 w-40 rounded-2xl object-cover"
                />
              </div>
              <div className="flex">
                <div className="flex w-full flex-col leading-6">
                  <p className="text-lg font-bold text-[#DE0796]">
                    {breedDetail?.breedDetails?.heading}
                  </p>
                  {breedDetail?.breedInformation.breedName && (
                    <p className="font-bold">
                      {breedDetail?.breedInformation.breedName}
                    </p>
                  )}

                  {breedDetail?.adultWeight && (
                    <p>
                      Est Adult:
                      <span>{breedDetail?.adultWeight} lb</span>
                    </p>
                  )}
                  <br />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              {breedDetail?.breedInformation.breedPhotos.map((image, index) => (
                <div key={index} className="h-20 w-20 overflow-hidden">
                  <img
                    src={image}
                    alt="breed"
                    className="h-20 w-20 rounded-2xl object-cover"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="my-5">
            <div className="mb-4">
              <h4 className="py-6 text-lg font-bold">Acquisition Info</h4>
              <div className="">
                <table className="w-full min-w-[300px] table-auto sm:min-w-[400px] lg:min-w-[600px]">
                  <tbody>
                    {/* Breed Details */}
                    {breedDetail?.breedDetails?.adultHeight && (
                      <tr>
                        <td>Adult Height:</td>
                        <td>{breedDetail.breedDetails.adultHeight}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.appearance && (
                      <tr>
                        <td>Appearance</td>
                        <td>{breedDetail.breedDetails.appearance}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.breedTypeMix && (
                      <tr>
                        <td>Breed Type Mix</td>
                        <td>{breedDetail.breedDetails.breedTypeMix}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.breeders && (
                      <tr>
                        <td>Breeders</td>
                        <td>{breedDetail.breedDetails.breeders}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.care && (
                      <tr>
                        <td>Care</td>
                        <td>{breedDetail.breedDetails.care}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.characteristics && (
                      <tr>
                        <td>Characteristics</td>
                        <td>{breedDetail.breedDetails.characteristics}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.energyLevel && (
                      <tr>
                        <td>Energy Level</td>
                        <td>{breedDetail.breedDetails.energyLevel}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.history && (
                      <tr>
                        <td>History</td>
                        <td>{breedDetail.breedDetails.history}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.insights && (
                      <tr>
                        <td>Insights</td>
                        <td>{breedDetail.breedDetails.insights}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.lifespan && (
                      <tr>
                        <td>Lifespan</td>
                        <td>{breedDetail.breedDetails.lifespan}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.metaDescription && (
                      <tr>
                        <td>Meta Description</td>
                        <td>{breedDetail.breedDetails.metaDescription}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.overview && (
                      <tr>
                        <td>Overview</td>
                        <td>{breedDetail.breedDetails.overview}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.shedding && (
                      <tr>
                        <td>Shedding</td>
                        <td>{breedDetail.breedDetails.shedding}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.slug && (
                      <tr>
                        <td>Slug</td>
                        <td>{breedDetail.breedDetails.slug}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.temperament && (
                      <tr>
                        <td>Temperament</td>
                        <td>{breedDetail.breedDetails.temperament}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.temperamentDetails && (
                      <tr>
                        <td>Temperament Details</td>
                        <td>{breedDetail.breedDetails.temperamentDetails}</td>
                      </tr>
                    )}
                    {breedDetail?.breedDetails?.training && (
                      <tr>
                        <td>Training</td>
                        <td>{breedDetail.breedDetails.training}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-[400px]">
          <h3 className="text-2xl font-semibold">Currently No Breed Details Found</h3>
        </div>
      )}
    </div>
  );
};

export default BreedDetails;
