import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";


import { create, Delete, fetchAll, update } from "../Apis/Apis";
import { GET_ALL_INVOICE } from "./invoiceTypes";

export const useAllFAQS = (apiUrl) => {
  return useQuery({
    queryKey: [GET_ALL_INVOICE],
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateINVOICE = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_INVOICE);
    },
    onError: (err, newProduct, context) => {
      console.log("Error Creating email invoice:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_INVOICE);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_INVOICE);
    },
  });
};
