import React, { Suspense } from "react";
import MainLayout from "../components/Layout/MainLayout";
import GlobalLoader from "../components/GlobalLoader";

const PublicRouteWrapper = ({ element }) => {
  return (
    <MainLayout>
      <Suspense fallback={<GlobalLoader />}>{element}</Suspense>
    </MainLayout>
  );
};

export default PublicRouteWrapper;
