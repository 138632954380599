import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../utils/uploadFile";

import { CiSquareRemove } from "react-icons/ci";
import {
  useCreatePups,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";
import { useAllBreeds } from "../../../features/Breeds/Breeds";
import moment from "moment";
import dayjs from "dayjs";
import {
  useCreateProducts,
  useUpdateProductsMutation,
} from "../../../features/Products/productsService";

const { TextArea } = Input;

const OrdersForm = ({ onClose, selectedData, isUpdateModal, setSelectedData }) => {
  const { data: products } = useAllBreeds("/api/products/");

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreateProducts();
  const UpdateMutation = useUpdateProductsMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        orderId: selectedData[0]?.orderId ?? null,
        firstName: selectedData[0]?.firstName ?? null,
        lastName: selectedData[0]?.lastName ?? null,
        cellPhone: selectedData[0]?.cellPhone ?? null,
        orderDate: selectedData[0]?.createdAt ? moment(selectedData[0].createdAt) : null,
        subtotal: selectedData[0]?.subtotal ?? 0,
        discount: selectedData[0]?.discount ?? 0,
        salesTax: selectedData[0]?.salesTax ?? 0,
        orderTotal: selectedData[0]?.orderTotal ?? 0,
        orderStatus: selectedData[0]?.orderStatus ?? 'pending',
        coupon: selectedData[0]?.coupon ?? 'none',
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);

    const data = {
      contactInformation: {
        firstName: values.firstName ?? null,
        lastName: values.lastName ?? null,
        cellPhone: values.cellPhone ?? null,
      },
      orderSummary: {
        subtotal: values.subtotal ?? 0,
        discount: values.discount ?? 0,
        salesTax: values.salesTax ?? 0,
        orderTotal: values.orderTotal ?? 0,
        coupon: values.coupon ?? 'none',
      },
      orderStatus: values.orderStatus ?? 'pending',
    };

    try {
      if (isUpdateModal && selectedData) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/order/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "Order Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/order",
          data,
        });

        toast({
          title: "Order created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      // Reset form and clear states if needed
      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Order Already Exists"
          : error.response?.data?.details ||
          error.message ||
          "Error creating Order",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Order Information
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            {isUpdateModal && (
              <Form.Item
                name="orderId"
                label="Order ID"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input disabled={true} />
              </Form.Item>
            )}

            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="First Name" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Last Name" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="cellPhone"
              label="Cell Phone"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Cell Phone" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="orderDate"
              label="Order Date"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <DatePicker className="w-full" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="subtotal"
              label="Subtotal"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input type="number" placeholder="Subtotal" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="discount"
              label="Discount"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input type="number" placeholder="Discount" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="salesTax"
              label="Sales Tax"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input type="number" placeholder="Sales Tax" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="orderTotal"
              label="Order Total"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input type="number" placeholder="Order Total" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="orderStatus"
              label="Order Status"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select placeholder="Select Order Status" disabled={isLoading}>
                <Select.Option value="pending">Pending</Select.Option>
                <Select.Option value="processing">Processing</Select.Option>
                <Select.Option value="completed">Completed</Select.Option>
                <Select.Option value="cancelled">Cancelled</Select.Option>
              </Select>
            </Form.Item>


            <Form.Item
              name="coupon"
              label="Coupon"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Coupon Code" disabled={isLoading} />
            </Form.Item>
          </div>
        </div>

        <div className="">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default OrdersForm;
