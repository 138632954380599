import React from "react";
import Table from "../../../components/Table";
import Table3 from "../../../components/Table3";
import TableDeliveries from "./TableDeliveries";

const Plan = () => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-4 lg:grid-cols-7">
        <div className="col-span-2 rounded-[20px] bg-white">
          <div className="flex flex-col p-12">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Display Week</p>
            </div>
            <div className="flex justify-between gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">2</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  This Week
                </p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">2</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Next Week
                </p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">-</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Week After
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 rounded-[20px] bg-white">
          <div className="flex flex-col p-12">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Wholesale & Customer Orders</p>
            </div>
            <div className="flex justify-between gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">4</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  NK Delivery Service
                </p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">2</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Store Delivery
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3 rounded-[20px] bg-white">
          <div className="flex flex-col p-12">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Wholesale & Customer Orders</p>
            </div>
            <div className="flex justify-between gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">1</p>
                <p className="text-sm font-semibold text-[#001219]/50">Plan</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">1</p>
                <p className="text-sm font-semibold text-[#001219]/50">NY</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">1</p>
                <p className="text-sm font-semibold text-[#001219]/50">CA</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">1</p>
                <p className="text-sm font-semibold text-[#001219]/50">FL</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">1</p>
                <p className="text-sm font-semibold text-[#001219]/50">AZ</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">1</p>
                <p className="text-sm font-semibold text-[#001219]/50">MI</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">1</p>
                <p className="text-sm font-semibold text-[#001219]/50">CHI</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">1</p>
                <p className="text-sm font-semibold text-[#001219]/50">OH</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableDeliveries />
    </div>
  );
};

export default Plan;
