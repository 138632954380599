import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { GET_ALL_PRODUCTS } from "./productsTypes.js";
import axios from "axios";
import { create, Delete, fetchAll, update } from "../Apis/Apis";

export const useAllProducts = (apiUrl) => {
  return useQuery({
    queryKey: [GET_ALL_PRODUCTS],
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateProducts = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_PRODUCTS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Products:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_PRODUCTS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_PRODUCTS);
    },
  });
};

export const useDeleteProductsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_PRODUCTS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Products:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_PRODUCTS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_PRODUCTS);
    },
  });
};

export const useUpdateProductsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_PRODUCTS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating products:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_PRODUCTS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_PRODUCTS);
    },
  });
};
