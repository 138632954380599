import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import dayjs from "dayjs";


import { useCreateBooking, useUpdateBooking } from "../../features/Bookings/BookingService";


const { TextArea } = Input;

const BookingForms = ({ onClose, selectedData, isUpdateModal, setSelectedData }) => {

  const isValidDateOfBirth = dayjs(selectedData[0]?.dateOfBirth).isValid();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreateBooking();
  const UpdateMutation = useUpdateBooking();

  const [form] = Form.useForm();

  console.log('selected===>',selectedData)
  console.log('isupdate', isUpdateModal)

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      const data = selectedData[0] || {};
      form.setFieldsValue({
        breeder: selectedData[0]?.breeder ?? null,
        usdaName: selectedData[0]?.usdaName ?? null,
        breed: selectedData[0]?.breed?? null,
        dateOfBirth: selectedData[0]?.dateOfBirth ? dayjs(selectedData[0].dateOfBirth) : null,
        dispositionDat: selectedData[0]?.dispositionDat ? dayjs(selectedData[0].dispositionDat) : null,
        psWeek: selectedData[0]?.psWeek ? dayjs(selectedData[0].psWeek) : null,
        usdaId: selectedData[0]?.usdaId ?? null,
        gender: selectedData[0]?.gender ?? null,
        color: selectedData[0]?.color ?? null,
        type: selectedData[0]?.type ?? null,
        registration: selectedData[0]?.registration ?? null,
        dam: selectedData[0]?.dam?? null,
        sire: selectedData[0]?.sire ?? null,
        sProgram: selectedData[0]?.sProgram ?? null,
        psAge: selectedData[0]?.psAge ?? null,
        errorCheck: selectedData[0]?.errorCheck ?? null,
        notes: selectedData[0]?.notes ?? null,
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
        breeder: values.breeder ?? null,
        usdaName: values.usdaName ?? null,
        breed: values.breed ?? null,
        dateOfBirth: values.dateOfBirth ?? null,
        usdaId: values.usdaId ?? null,
        gender: values.gender ?? null,
        color: values.color ?? null,
        type: values.type ?? null,
        registration: values.registration ?? null,
        dam: values.dam ?? null,
        sire: values.sire ?? null,
        sProgram: values.sProgram ?? null,
        dispositionDat: values.dispositionDat ?? null,
        psWeek: values.psWeek ?? null,
        psAge: values.psAge ?? null,
        errorCheck: values.errorCheck ?? null,
        notes: values.notes ?? null,
    };

    
     try {
      if (selectedData && selectedData.length > 0 && isUpdateModal) {
        
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/booking/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "booking Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/booking",
          data,
        });

        toast({
          title: "Booking created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
     }

      // Reset form and clear states if needed
      form.resetFields();
      onClose();
      setSelectedData([])

    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Product Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating booking",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
            Add New Record
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <Form.Item
              name="breeder"
              label="breeder"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="breeder" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="usdaName"
              label="usdaName"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="usdaName" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="breed"
              label="breed"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="breed" disabled={isLoading} />
            </Form.Item>

           

            <Form.Item
              name="usdaId"
              label="usdaId"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="usdaId" disabled={isLoading} />
            </Form.Item>
           
       

            <Form.Item
              name="color"
              label="color"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="color" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="psAge"
              label="Ps Age"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="0.0 weeks" disabled={isLoading} />
            </Form.Item>

            <div className="grid grid-cols-2 gap-5">
             
               <Form.Item
                name="dateOfBirth"
                label="Date of Birth"
                
              >
                <DatePicker style={{ width: "100%" }} disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name="type"
                label="Type"
              >
                <Select
                  placeholder="All"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "F1", value: "F1" },
                    { label: "F1B", value: "F1B" },
                    { label: "F2", value: "F2" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>


              <Form.Item
                name="registration"
                label="Registration"
              >
                <Select
                  placeholder="Registration"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "n/a", value: "n/a" },
                    { label: "AKC", value: "AKC" },
                    { label: "ACA", value: "ACA" },
                    { label: "AKC/", value: "AKC/" },
                    { label: "CKC", value: "CKC" },
                    { label: "ICA", value: "ICA" },
                    { label: "Grad", value: "Grad" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>
            

              <Form.Item
                name="sProgram"
                label="S-Program"
              >
                <Select
                  placeholder="S-Program"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "PS", value: "PS" },
                    { label: "R", value: "R" },
                    { label: "Keep", value: "Keep" },
                    { label: "Pass", value: "pass" },
                    { label: "Sold", value: "Sold" },
                    { label: "Reqs", value: "Reqs" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name="errorCheck"
                label="Error Check"
              >
                <Select
                  placeholder="Error Check"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
        
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

             
              <Form.Item
                name="gender"
                label="Gender"
       
              >
                <Select
                  placeholder="Male or Female"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name="dispositionDate"
                label="Disposition Date"
              >
                <DatePicker style={{ width: "100%" }} disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name="psWeek"
                label="ps week"
              >
                <DatePicker style={{ width: "100%" }} disabled={isLoading} />
              </Form.Item>


            </div>

            <div className="grid grid-cols-2 gap-5">

              <Form.Item
                name="dam"
                label="Dam"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="0" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name="sire"
                label="Sire"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="0" disabled={isLoading} />
              </Form.Item>



            </div>

            <Form.Item
              name="notes"
              label="Notes"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea placeholder="Notes" disabled={isLoading} />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button className="!bg-[#001219] !text-white" type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default BookingForms;
