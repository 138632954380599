import React from "react";

import { useDisclosure } from "@chakra-ui/react";
import BreederForm from "./BreederForm";
import BreederTable from "./BreederTable";
import { GlobalModalV1 } from "../../../components/GlobalModal";

const Breeder = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  return (
    <div className="my-10">
    <div className="flex flex-1 flex-col">
      <button
        onClick={onOpen}
        className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white"
      >
        Add Breeder
      </button>

      <GlobalModalV1 onClose={onClose} isOpen={isOpen} width={"800px"}>
        <BreederForm onClose={onClose} isOpen={isOpen} />
      </GlobalModalV1>
    </div>
    <BreederTable />
  </div>
  );
};

export default Breeder;
