import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Radio, Select, Upload } from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../../utils/uploadFile";

import { CiSquareRemove } from "react-icons/ci";
import { useAllCategory } from "../../../../features/Category/Category";
import { useCreateParent, useUpdateProductsMutation } from "../../../../features/parent/parentService";

const { TextArea } = Input;

const ParentForm = ({ onClose, selectedData, isUpdateModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  // const { data: categories } = useAllCategory("/api/categories/");

  const createMutation = useCreateParent(); // Initialize the mutation
  const UpdateMutation = useUpdateProductsMutation(); // Initialize the mutation

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [primaryPhoto, setPrimaryPhoto] = useState([]);
  const [characteristicsPhoto, setCharacteristicsPhoto] = useState([]);
  const [avatarPhoto, setAvatarPhoto] = useState([]);
  const [coverSales, setCoverSales] = useState([]);
  const [coverInfo, setCoverInfo] = useState([]);
  const [parentPhoto, setParentPhoto] = useState([]);
  const [video, setVideo] = useState([]);
  const [showFAQS, setShowFAQS] = useState(false);
  const [FAQS, setFAQS] = useState([]);
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();

  useEffect(() => {
    console.log(selectedData, "selectedData");

    if (selectedData && isUpdateModal) {
      console.log("Selected Data for edit:", selectedData[0]); // Debug log

      // Handle Primary Photo
      const existingPrimaryPhoto = selectedData[0]?.parentInformation?.primaryPhoto?.url ? [{
        uid: '-1',
        name: 'primary-photo',
        status: 'done',
        url: selectedData[0].parentInformation.primaryPhoto.url,
        type: 'image/jpeg',
      }] : [];
      setPrimaryPhoto(existingPrimaryPhoto);

      // Handle Characteristics Photo
      const existingCharacteristicsPhoto = selectedData[0]?.parentInformation?.characteristicsPhoto?.url ? [{
        uid: '-2',
        name: 'characteristics-photo',
        status: 'done',
        url: selectedData[0].parentInformation.characteristicsPhoto.url,
        type: 'image/jpeg',
      }] : [];
      setCharacteristicsPhoto(existingCharacteristicsPhoto);

      // Handle Avatar Photo
      const existingAvatarPhoto = selectedData[0]?.parentInformation?.avatarPhoto?.url ? [{
        uid: '-3',
        name: 'avatar-photo',
        status: 'done',
        url: selectedData[0].parentInformation.avatarPhoto.url,
        type: 'image/jpeg',
      }] : [];
      setAvatarPhoto(existingAvatarPhoto);

      // Handle Cover Sales
      const existingCoverSales = selectedData[0]?.parentInformation?.coverSales?.url ? [{
        uid: '-4',
        name: 'cover-sales',
        status: 'done',
        url: selectedData[0].parentInformation.coverSales.url,
        type: 'image/jpeg',
      }] : [];
      setCoverSales(existingCoverSales);

      // Handle Cover Info
      const existingCoverInfo = selectedData[0]?.parentInformation?.coverInfo?.url ? [{
        uid: '-5',
        name: 'cover-info',
        status: 'done',
        url: selectedData[0].parentInformation.coverInfo.url,
        type: 'image/jpeg',
      }] : [];
      setCoverInfo(existingCoverInfo);

      // Handle Breed Photos (multiple)
      const existingBreedPhotos = selectedData[0]?.parentInformation?.parentPhotos?.map((url, index) => ({
        uid: `-${index + 6}`,
        name: `parent-photo-${index}`,
        status: 'done',
        url: url,
        type: 'image/jpeg',
      })) || [];
      setParentPhoto(existingBreedPhotos);

      // Handle Video
      if (selectedData[0]?.parentDetails?.video) {
        const videoFile = {
          uid: '-7',
          name: 'parent-video',
          status: 'done',
          url: selectedData[0].parentDetails.video,
          type: 'video/mp4',
        };
        setVideo([videoFile]);
      }

      if (selectedData[0]?.parentDetails?.video) {
        const videoFile = {
          uid: '-7',
          name: 'breed-video',
          status: 'done',
          url: selectedData[0].parentDetails.video,
          type: 'video/mp4',
        };
        setVideo([videoFile]);
      }
      // Set form values
      form.setFieldsValue({
        parentInformation: {
          ...selectedData[0]?.parentInformation,
          primaryPhoto: existingPrimaryPhoto,
          characteristicsPhoto: existingCharacteristicsPhoto,
          avatarPhoto: existingAvatarPhoto,
          coverSales: existingCoverSales,
          coverInfo: existingCoverInfo,
          parentPhotos: existingBreedPhotos,
        },
        parentDetails: {
          ...selectedData[0]?.parentDetails,
          parentCategory: selectedData[0]?.parentDetails?.parentCategory,
          slug: selectedData[0]?.parentDetails?.slug,
          pageTitle: selectedData[0]?.parentDetails?.pageTitle,
          metaDescription: selectedData[0]?.parentDetails?.metaDescription,
          video: video,
        }
      });
    }
  }, [selectedData, isUpdateModal]);

  const handleClickFAQS = () => {
    setFAQS((prev) => [...prev, { question: question, answer, answer }]);
    setQuestion(null);
    setAnswer(null);
    setShowFAQS(false);
  };

  const handleDeleteFAQS = (id) => {
    const filter = FAQS.filter((data, index) => index !== id);
    setFAQS(filter);
    setQuestion(null);
    setAnswer(null);
    setShowFAQS(false);
  };

  console.log(FAQS, "FAQS");

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      // Handle Primary Photo
      let primaryPhotoUrl = null;
      if (primaryPhoto?.length) {
        if (primaryPhoto[0].originFileObj) {
          // This is a new file that needs to be uploaded
          const uploadedFiles = await uploadFiles([primaryPhoto[0].originFileObj]);
          primaryPhotoUrl = uploadedFiles[0];
        } else {
          // This is an existing file, use its URL
          primaryPhotoUrl = primaryPhoto[0].url;
        }
      }

      // Handle Characteristics Photo
      let characteristicsPhotoUrl = null;
      if (characteristicsPhoto?.length) {
        if (characteristicsPhoto[0].originFileObj) {
          const uploadedFiles = await uploadFiles([characteristicsPhoto[0].originFileObj]);
          characteristicsPhotoUrl = uploadedFiles[0];
        } else {
          characteristicsPhotoUrl = characteristicsPhoto[0].url;
        }
      }

      // Handle Avatar Photo
      let avatarPhotoUrl = null;
      if (avatarPhoto?.length) {
        if (avatarPhoto[0].originFileObj) {
          const uploadedFiles = await uploadFiles([avatarPhoto[0].originFileObj]);
          avatarPhotoUrl = uploadedFiles[0];
        } else {
          avatarPhotoUrl = avatarPhoto[0].url;
        }
      }

      // Handle Cover Sales
      let coverSalesUrl = null;
      if (coverSales?.length) {
        if (coverSales[0].originFileObj) {
          const uploadedFiles = await uploadFiles([coverSales[0].originFileObj]);
          coverSalesUrl = uploadedFiles[0];
        } else {
          coverSalesUrl = coverSales[0].url;
        }
      }

      // Handle Cover Info
      let coverInfoUrl = null;
      if (coverInfo?.length) {
        if (coverInfo[0].originFileObj) {
          const uploadedFiles = await uploadFiles([coverInfo[0].originFileObj]);
          coverInfoUrl = uploadedFiles[0];
        } else {
          coverInfoUrl = coverInfo[0].url;
        }
      }

      // Handle Breed Photos (multiple)
      let parentPhotoUrls = [];
      if (parentPhoto?.length) {
        const newPhotos = parentPhoto.filter(photo => photo.originFileObj);
        const existingPhotos = parentPhoto.filter(photo => !photo.originFileObj).map(photo => photo.url);
        
        if (newPhotos.length) {
          const uploadedFiles = await uploadFiles(newPhotos.map(photo => photo.originFileObj));
          parentPhotoUrls = [...existingPhotos, ...uploadedFiles];
        } else {
          parentPhotoUrls = existingPhotos;
        }
      }

      // Handle Video
      let videoUrl = null;
      if (video?.length) {
        if (video[0].originFileObj) {
          const uploadedFiles = await uploadFiles([video[0].originFileObj]);
          videoUrl = uploadedFiles[0];
        } else {
          videoUrl = video[0].url;
        }
      }

      const data = {
        parentInformation: {
          ...values.parentInformation,
          primaryPhoto: primaryPhotoUrl ? {
            url: primaryPhotoUrl,
            alt: values.parentInformation?.primaryPhotoAlt || null,
            title: values.parentInformation?.primaryPhotoTitle || null,
          } : null,
          characteristicsPhoto: characteristicsPhotoUrl ? {
            url: characteristicsPhotoUrl,
            alt: values.parentInformation?.characteristicsPhotoAlt || null,
            title: values.parentInformation?.characteristicsPhotoTitle || null,
          } : null,
          avatarPhoto: avatarPhotoUrl ? {
            url: avatarPhotoUrl,
            alt: values.parentInformation?.avatarAlt || null,
            title: values.parentInformation?.avatarTitle || null,
          } : null,
          coverSales: coverSalesUrl ? {
            url: coverSalesUrl,
            alt: values.parentInformation?.coverSalesAlt || null,
            title: values.parentInformation?.coverSalesTitle || null,
          } : null,
          coverInfo: coverInfoUrl ? {
            url: coverInfoUrl,
            alt: values.parentInformation?.coverInfoAlt || null,
            title: values.parentInformation?.coverInfoTitle || null,
          } : null,
          parentPhotos: parentPhotoUrls,
        },
        parentDetails: {
          ...values.parentDetails,
          video: videoUrl,
        },
      };

      if (isUpdateModal && selectedData) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/parent/${selectedData[0]?._id}`,
          data,
        });
        toast({
          title: "Parent Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/parent",
          data,
        });
        toast({
          title: "Parent Created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      setFileList([]);
      setPrimaryPhoto([]);
      setCharacteristicsPhoto([]);
      setAvatarPhoto([]);
      setCoverSales([]);
      setCoverInfo([]);
      setParentPhoto([]);
      setVideo([]);
      setFAQS([]);
      setQuestion("");
      setAnswer("");
      form.resetFields();
    

      onClose();

    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Parent Already Exist"
          : error.response?.data?.details ||
            error.message ||
            "Error Updating Breed",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const categoryOptions = categories?.map((category) => ({
  //   value: category._id,
  //   label: category.name,
  // }));


  // Update the beforeUpload handler to preserve original file information
  const handleBeforeUpload = (file, fileList, setFileState) => {
    const newFile = {
      uid: file.uid,
      name: file.name,
      status: 'done',
      url: URL.createObjectURL(file),
      originFileObj: file,
      type: file.type,
    };

    setFileState((prev) => [...prev, newFile]);
    return false;
  };

  return (
    <div className="auto mx-0 mt-10 min-h-screen overflow-y-auto">
      <Form
        form={form}
        // wrapperCol={{ span:  }}
        layout="horizontal"
        className="m-auto flex w-[90%] flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Parent Details
        </h1>
        <div className="m-auto grid w-[80%] grid-cols-2 gap-20 pt-10">
          <div className="w-full">
            <div>
              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Parent Information
              </div>
              <Form.Item
                name={["parentInformation", "parentName"]}
                label="Parent Name"
                rules={[{ required: true }]}
              >
                <Input placeholder="Golden Retriever" disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name={["parentDetails", "parentCategory"]}
                label="Category"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select a category"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{value: 'cavapooMoms', label: 'Cavapoo Moms'}, {value: 'CavalierMoms', label: 'Cavalier Moms'},{
                    value :'PoodleDads', label: 'Poodle Dads'
                  }]}
                  disabled={isLoading}
                />
              </Form.Item>

              {/* <Form.Item
                name={["parentInformation", "category"]}
                label="Category"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select a category"
                  style={{ width: "100%" }}
                  allowClear
                  options={categoryOptions}
                  disabled={isLoading}
                />
              </Form.Item> */}

              <Form.Item
                name={["parentInformation", "petPlanParentId"]}
                label="Pet Plan Breed ID"
              >
                <Input placeholder="12345" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "alternateNames"]}
                label="Alternate Names"
              >
                <Input placeholder="Goldie" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "safeAlternateNames"]}
                label="Safe Alternate Names"
              >
                <Input placeholder="Golden" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "relatedBreeds"]}
                label="Related Breeds"
              >
                <Input placeholder="Labrador Retriever" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "isActive"]}
                label="Active"
                valuePropName="checked"
              >
                <Checkbox defaultChecked={true} />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Primary Photo{" "}
              </div>

              <Form.Item
                label="Video"
                name={["parentInformation", "primaryPhotos"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={primaryPhoto}
                  beforeUpload={(file) => handleBeforeUpload(file, primaryPhoto, setPrimaryPhoto)}
                  onRemove={(file) => {
                    setPrimaryPhoto(primaryPhoto.filter((f) => f.uid !== file.uid));
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {primaryPhoto?.length < 1 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>

              <Form.Item
                name={["parentInformation", "primaryPhotoAlt"]}
                label="Primary Photo Alt"
              >
                <Input placeholder="Golden Retriever" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "primaryPhotoTitle"]}
                label="Primary Photo Title"
              >
                <Input placeholder="Golden Retriever" disabled={isLoading} />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Characteristics Photo
              </div>

              <Form.Item
                label="Characteristics Photo"
                name={["parentInformation", "characteristicsPhoto"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={characteristicsPhoto}
                  beforeUpload={(file) => handleBeforeUpload(file, characteristicsPhoto, setCharacteristicsPhoto)}
                  onRemove={(file) => {
                    setCharacteristicsPhoto(characteristicsPhoto.filter((f) => f.uid !== file.uid));
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {characteristicsPhoto?.length < 1 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
                <span className="text-red-600">
                *Image dimensions should be 1200x800
              </span>
              </Form.Item>

              <Form.Item
                name={["parentInformation", "characteristicsPhotoAlt"]}
                label="Characteristics Photo Alt"
              >
                <Input
                  placeholder="Golden Retriever Characteristics"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "characteristicsPhotoTitle"]}
                label="Characteristics Photo Title"
              >
                <Input
                  placeholder="Golden Retriever Characteristics"
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Avatar Photo
              </div>

              <Form.Item
                label="Avatar Photo"
                name={["parentInformation", "avatarPhoto"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={avatarPhoto}
                  beforeUpload={(file) => handleBeforeUpload(file, avatarPhoto, setAvatarPhoto)}
                  onRemove={(file) => {
                    setAvatarPhoto(avatarPhoto.filter((f) => f.uid !== file.uid));
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {avatarPhoto?.length === 0 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
                <span className="text-red-600">
                *Image dimensions should be 1200x800
              </span>
              </Form.Item>

              <Form.Item
                name={["parentInformation", "avatarAlt"]}
                label="Avatar Photo Alt"
              >
                <Input
                  placeholder="Golden Retriever Avatar"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "avatarTitle"]}
                label="Avatar Photo Title"
              >
                <Input
                  placeholder="Golden Retriever Avatar"
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Cover Sales
              </div>

              <Form.Item
                label="Cover Sales"
                name={["parentInformation", "coverSales"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={coverSales}
                  beforeUpload={(file) => handleBeforeUpload(file, coverSales, setCoverSales)}
                  onRemove={(file) => {
                    setCoverSales(coverSales.filter((f) => f.uid !== file.uid));
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {coverSales?.length < 1 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
                <span className="text-red-600">
                *Image dimensions should be 1200x800
              </span>
              </Form.Item>

              <Form.Item
                name={["parentInformation", "coverSalesAlt"]}
                label="Cover Sales Alt"
              >
                <Input
                  placeholder="Golden Retriever Sales"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "coverSalesTitle"]}
                label="Cover Sales Title"
              >
                <Input
                  placeholder="Golden Retriever Sales"
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Cover Info
              </div>

              <Form.Item
                label="Cover Info"
                name={["parentInformation", "coverInfo"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={coverInfo}
                  beforeUpload={(file) => handleBeforeUpload(file, coverInfo, setCoverInfo)}
                  onRemove={(file) => {
                    setCoverInfo(coverInfo.filter((f) => f.uid !== file.uid));
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {coverInfo?.length < 1 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
                <span className="text-red-600">
                *Image dimensions should be 1200x800
              </span>
              </Form.Item>

              <Form.Item
                name={["parentInformation", "coverInfoAlt"]}
                label="Cover Info Alt"
              >
                <Input
                  placeholder="Golden Retriever Info"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "coverInfoTitle"]}
                label="Cover Info Title"
              >
                <Input
                  placeholder="Golden Retriever Info"
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Parent Photo
              </div>

              <Form.Item
                label="Parent Photo"
                name={["parentInformation", "parentPhoto"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={parentPhoto}
                  beforeUpload={(file) => handleBeforeUpload(file, parentPhoto, setParentPhoto)}
                  onRemove={(file) => {
                    setParentPhoto(parentPhoto.filter((f) => f.uid !== file.uid));
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {parentPhoto?.length < 1 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
                <span className="text-red-600">
                *Image dimensions should be 1200x800
              </span>
              </Form.Item>

              <Form.Item
                name={["parentInformation", "parentPhotoAlt"]}
                label="Breed Photo Alt"
              >
                <Input placeholder="Golden Retriever" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "breedPhotoTitle"]}
                label="Breed Photo Title"
              >
                <Input placeholder="Golden Retriever" disabled={isLoading} />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                FAQ&apos;s
              </div>

              {FAQS?.length > 0 &&
                FAQS.map((faq, index) => (
                  <div
                    key={index}
                    className="mb-2 flex w-full items-center justify-between rounded-md bg-gray-100 p-2"
                  >
                    <div>
                      <div className="font-semibold">{faq.question}</div>
                      <div className="text-gray-500">{faq.answer}</div>
                    </div>
                    <Button
                      type="link"
                      danger
                      onClick={() => handleDeleteFAQS(index)}
                      disabled={isLoading}
                    >
                      Delete
                    </Button>
                  </div>
                ))}

              <Button
                type="dashed"
                onClick={() => setShowFAQS(!showFAQS)}
                disabled={isLoading}
                className="mb-3 w-full"
              >
                {showFAQS ? "Hide FAQs Form" : "Add FAQs"}
              </Button>

              {showFAQS && (
                <div className="mb-4 w-full rounded-md bg-gray-100 p-4">
                  <Form.Item label="Question" name="question">
                    <Input
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      disabled={isLoading}
                    />
                  </Form.Item>

                  <Form.Item label="Answer" name="answer">
                    <TextArea
                      rows={4}
                      value={answer}
                      onChange={(e) => setAnswer(e.target.value)}
                      disabled={isLoading}
                    />
                  </Form.Item>

                  <Button
                    type="primary"
                    onClick={handleClickFAQS}
                    disabled={!question || !answer || isLoading}
                    className="w-full"
                  >
                    Add FAQ
                  </Button>
                </div>
              )}

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Locations City Info
              </div>
              {/* Locations City Info */}
              <Form.Item
                name={["parentInformation", "locationsCityInfo", "title"]}
                label="Locations City Info Title"
              >
                <Input
                  placeholder="Golden Retriever in City"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={[
                  "parentInformation",
                  "locationsCityInfo",
                  "metaDescription",
                ]}
                label="Locations City Info Meta Description"
              >
                <TextArea
                  placeholder="Information about Golden Retrievers in the city"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "locationsCityInfo", "heading"]}
                label="Locations City Info Heading"
              >
                <Input
                  placeholder="Golden Retriever City Info"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "locationsCityInfo", "description"]}
                label="Locations City Info Description"
              >
                <TextArea
                  placeholder="Golden Retrievers are popular in urban areas."
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Breeder City Info
              </div>

              {/* Breeder City Info */}
              <Form.Item
                name={["parentInformation", "breederCityInfo", "title"]}
                label="Breeder City Info Title"
              >
                <Input
                  placeholder="Golden Retriever Breeders"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={[
                  "parentInformation",
                  "breederCityInfo",
                  "metaDescription",
                ]}
                label="Breeder City Info Meta Description"
              >
                <TextArea
                  placeholder="Find Golden Retriever breeders"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "breederCityInfo", "heading"]}
                label="Breeder City Info Heading"
              >
                <Input
                  placeholder="Golden Retriever Breeders Info"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "breederCityInfo", "description"]}
                label="Parent City Info Description"
              >
                <TextArea
                  placeholder="List of reputable Golden Retriever breeders."
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Parent City Info
              </div>
              {/* Breed City Info */}
              <Form.Item
                name={["parentInformation", "parentCityInfo", "title"]}
                label="Parent City Info Title"
              >
                <Input
                  placeholder="Golden Retriever City Info"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "parentCityInfo", "metaDescription"]}
                label="Parent City Info Meta Description"
              >
                <TextArea
                  placeholder="Information about Golden Retrievers in different cities"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "parentCityInfo", "heading"]}
                label="Parent City Info Heading"
              >
                <Input
                  placeholder="Golden Retriever City Information"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["parentInformation", "parentCityInfo", "description"]}
                label="Parent City Info Description"
              >
                <TextArea
                  placeholder="Golden Retrievers are adaptable to various urban settings."
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>
            </div>
          </div>

          <div className="w-full">
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Parent Details
            </div>
            {/* Parent Details */}

            <Form.Item
              name={["parentDetails", "slug"]}
              label="Slug"
              rules={[{ required: true }]}
            >
              <Input placeholder="golden-retriever" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "pageTitle"]}
              label="Page Title"
              rules={[{ required: true }]}
            >
              <Input placeholder="Golden Retriever" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "metaDescription"]}
              label="Meta Description"
              rules={[{ required: true }]}
            >
              <TextArea
                placeholder="Learn more about Golden Retrievers"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["parentDetails", "heading"]} label="Heading">
              <Input placeholder="Golden Retriever" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "parentTypeMix"]}
              label="Breed Type Mix"
            >
              <Input placeholder="Purebred" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "energyLevel"]}
              label="Energy Level"
            >
              <Input placeholder="High" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={["parentDetails", "shedding"]} label="Shedding">
              <Input placeholder="Moderate" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={["parentDetails", "training"]} label="Training">
              <Input placeholder="Easy" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "temperament"]}
              label="Temperament"
            >
              <Input
                placeholder="Friendly, Intelligent, Devoted"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "adultWeight"]}
              label="Adult Weight"
            >
              <Input placeholder="55-75 lbs" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "adultHeight"]}
              label="Adult Height"
            >
              <Input placeholder="21.5-24 inches" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={["parentDetails", "lifespan"]} label="Lifespan">
              <Input placeholder="10-12 years" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "compareCaption"]}
              label="Compare Caption"
            >
              <Input
                placeholder="Compare Golden Retrievers"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["parentDetails", "overview"]} label="Overview">
              <TextArea
                placeholder="Golden Retrievers are known for their friendly and tolerant attitudes."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "characteristics"]}
              label="Characteristics"
            >
              <TextArea
                placeholder="Golden Retrievers are known for their loyal and friendly nature."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["parentDetails", "breeders"]} label="Breeders">
              <TextArea
                placeholder="Find reputable Golden Retriever breeders."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["parentDetails", "appearance"]} label="Appearance">
              <TextArea
                placeholder="Golden Retrievers have a dense, water-repellant outer coat with a thick undercoat."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "temperamentDetails"]}
              label="Temperament Details"
            >
              <TextArea
                placeholder="Golden Retrievers are friendly, intelligent, and devoted."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["parentDetails", "insights"]} label="Insights">
              <TextArea
                placeholder="Golden Retrievers are often used in search and rescue operations."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["parentDetails", "history"]} label="History">
              <TextArea
                placeholder="Golden Retrievers were originally bred in Scotland in the mid-19th century."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["parentDetails", "care"]} label="Care">
              <TextArea
                placeholder="Golden Retrievers need regular grooming and exercise."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              label="Video"
              name={["parentDetails", "video"]}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                listType="text"
                fileList={video}
                beforeUpload={(file) => {
                  // Check if file is video
                  if (!file.type.startsWith('video/')) {
                    toast({
                      title: "Please upload a video file",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    return Upload.LIST_IGNORE;
                  }
                  
                  const newFile = {
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file,
                    type: file.type,
                  };
                  
                  setVideo([newFile]);
                  return false;
                }}
                onRemove={() => {
                  setVideo([]);
                }}
                accept="video/*"
                maxCount={1}
                disabled={isLoading}
                itemRender={(originNode, file) => {
                  // Custom render for video preview
                  return (
                    <div style={{ marginBottom: 8 }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {file.url && (
                          <video 
                            src={file.url} 
                            style={{ maxWidth: '200px', marginRight: 8 }} 
                            controls
                          />
                        )}
                        <span style={{ marginLeft: 8 }}>{file.name}</span>
                      </div>
                    </div>
                  );
                }}
              >
                {video?.length < 1 && (
                  <Button icon={<PlusOutlined />}>
                    Upload Video
                  </Button>
                )}
              </Upload>
            </Form.Item>

            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Parent Sales Details
            </div>

            {/* Parent Sales Details */}
            <Form.Item
              name={["parentDetails", "parentSalesDetails", "title"]}
              label="Sales Details Title"
            >
              <Input
                placeholder="Golden Retriever Puppies for Sale"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "parentSalesDetails", "metaDescription"]}
              label="Sales Details Meta Description"
            >
              <TextArea
                placeholder="Find Golden Retriever puppies for sale"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "parentSalesDetails", "heading"]}
              label="Sales Details Heading"
            >
              <Input
                placeholder="Golden Retriever Puppies"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "parentSalesDetails", "subheading"]}
              label="Sales Details Subheading"
            >
              <Input
                placeholder="Find your perfect Golden Retriever puppy"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "parentSalesDetails", "topDescription"]}
              label="Sales Details Top Description"
            >
              <TextArea
                placeholder="Golden Retrievers are friendly and easy to train."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "parentSalesDetails", "parentDescription"]}
              label="Parent Details Description"
            >
              <TextArea
                placeholder="Golden Retrievers make excellent family pets."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "parentSalesDetails", "maintenance"]}
              label="Sales Details Maintenance"
            >
              <Input placeholder="4" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "parentSalesDetails", "training"]}
              label="Sales Details Training"
            >
              <Input placeholder="5" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "parentSalesDetails", "shedding"]}
              label="Sales Details Shedding"
            >
              <Input placeholder="3" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "parentSalesDetails", "adaptability"]}
              label="Sales Details Adaptability"
            >
              <Input placeholder="4" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "parentSalesDetails", "active"]}
              label="Sales Details Active"
            >
              <Input placeholder="5" disabled={isLoading} />
            </Form.Item>

            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              SEO
            </div>

            <Form.Item
              name={["parentDetails", "seoDetails", "title"]}
              label=" Title"
            >
              <Input
                placeholder="Golden Retriever Puppies for Sale"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "seoDetails", "metadescription"]}
              label="Meta Description"
            >
              <TextArea
                placeholder="Find Golden Retriever puppies for sale"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "seoDetails", "heading"]}
              label="Heading"
            >
              <Input
                placeholder="Golden Retriever Puppies"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["parentDetails", "seoDetails", "description"]}
              label="Description"
            >
              <TextArea
                placeholder="Find Golden Retriever puppies for sale"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button className="!bg-[#001219] !text-white" type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ParentForm;
