import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { GET_ALL_INSURANCE } from "./insuranceTypes"
import { create, Delete, fetchAll, update } from "../Apis/Apis.js";

export const useAllInsurance = (apiUrl)=> {
    return useQuery({
        queryKey: [GET_ALL_INSURANCE],
        queryFn: ()=> fetchAll(apiUrl)
    })
}

export const useCreateInsurance = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: create,
        onMutate: (new_insurance)=>{
            queryClient.invalidateQueries(GET_ALL_INSURANCE)
        },
        onError: (error, context, new_insurance)=> {
           console.log('error creating insurance', error)
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_INSURANCE)
        },
        onSettled: ()=>{
            queryClient.invalidateQueries(GET_ALL_INSURANCE)
        }
    })
}

export const useUpdateInsurance = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: update,
        onMutate: (new_insurance) => {
            queryClient.invalidateQueries(GET_ALL_INSURANCE)
        },
        onError: (error, context, new_insurance)=> {
            console.log('error updating insurance')
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_INSURANCE)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_INSURANCE)
        }
    })
}

export const useDeleteInsurance = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: Delete,
        onMutate: (new_insurance)=> {
            queryClient.invalidateQueries(GET_ALL_INSURANCE)
        },
        onError: (error, context, new_insurance) => {
            console.log('error deleting insurance')
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_INSURANCE)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_INSURANCE)
        }
    })
}