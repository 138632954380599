import React from "react";
import { getPuppyAgeInWeeks } from "../../../../../../../utils";
import { useAllParents } from "../../../../../../../features/parent/parentService";
import BreedDetails from "./Breeder";

const ParentInfo = ({ data }) => {
  const { data: parents, isLoading, error } = useAllParents("/api/parents");

  console.log("Parent Data===>", parents);

  const parentDetail = parents?.find(
    (prnt) =>
      prnt._id === data?.puppies?.[0]?.puppyInformation?.parent ??
      "No Parent Details Found",
  );

  console.log("Parent Details alll===>", parentDetail);

  const totalProductPrice = data?.products?.reduce((total, product) => {
    return total + (product.price || 0);
  }, 0);

  const totalPuppyPrice = data?.puppies?.reduce((total, puppy) => {
    return total + (puppy.listingInformation?.price || 0);
  }, 0);

  const grandTotal = (totalProductPrice || 0) + (totalPuppyPrice || 0);
  const discount = data?.orderSummary?.discount || 0;
  const salesTax = data?.orderSummary?.salesTax || 0;

  const orderTotal = grandTotal - discount + salesTax;

  return (
    <div className="my-5">
      {parentDetail ? (
        <div>
          <div className="flex justify-between">
            <div className="flex items-center justify-center gap-5">
              <div className="h-40 w-40 overflow-hidden">
                <img
                  src={parentDetail?.parentInformation.parentPhotos[0]}
                  alt="breed"
                  className="h-40 w-40 rounded-2xl object-cover"
                />
              </div>
              <div className="flex">
                <div className="flex w-full flex-col leading-6">
                  <p className="text-lg font-bold text-[#DE0796]">
                    {parentDetail?.parentDetails?.heading}
                  </p>
                  <p className="font-bold">
                    <span>{parentDetail?.parentInformation?.parentName}</span>{" "}
                  </p>
                  <p>
                    <span>
                      {parentDetail?.parentInformation?.petPlanParentId}
                    </span>{" "}
                  </p>
                  <br />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              {parentDetail?.parentInformation.parentPhotos.map(
                (image, index) => (
                  <div key={index} className="h-20 w-20 overflow-hidden">
                    <img
                      src={image}
                      alt="breed"
                      className="h-20 w-20 rounded-2xl object-cover"
                    />
                  </div>
                ),
              )}
            </div>
          </div>

          <div className="my-5">
            <div className="mb-4">
              <h4 className="py-6 text-lg font-bold">Information</h4>
              <div className="">
                <table className="w-full min-w-[300px] table-auto sm:min-w-[400px] lg:min-w-[600px]">
                  <tbody>
                    {parentDetail?.parentInformation?.breederCityInfo
                      ?.description && (
                      <tr>
                        <td>Description</td>
                        <td>
                          {
                            parentDetail?.parentInformation?.breederCityInfo
                              ?.description
                          }
                        </td>
                      </tr>
                    )}
                    {parentDetail?.parentDetails?.parentCategory && (
                      <tr>
                        <td>Parent Category</td>
                        <td>{parentDetail?.parentDetails?.parentCategory}</td>
                      </tr>
                    )}
                    {parentDetail?.parentDetails?.pageTitle && (
                      <tr>
                        <td>Page Title</td>
                        <td>{parentDetail?.parentDetails?.pageTitle}</td>
                      </tr>
                    )}
                    {parentDetail?.parentDetails?.metaDescription && (
                      <tr>
                        <td>Meta Description</td>
                        <td>{parentDetail?.parentDetails?.metaDescription}</td>
                      </tr>
                    )}
                    {parentDetail?.parentDetails?.slug && (
                      <tr>
                        <td>Slug</td>
                        <td>{parentDetail?.parentDetails?.slug}</td>
                      </tr>
                    )}

                    {/* <tr>
                  <td>Payment Received</td>
                  <td>$ 758.00</td>
                </tr>
                <tr>
                  <td>Remaining Balance</td>
                  <td>$ 86.01</td>
                </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-[400px] items-center justify-center">
          <h3 className="text-2xl font-semibold">
            Currently No Parent Details Found
          </h3>
        </div>
      )}
    </div>
  );
};

export default ParentInfo;
