// bookings
export const GET_ALL_BOOKING = 'GET_ALL_BOOKING';
export const CREATE_BOOKING = 'CREATE_BOOKING';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';

// booking registration
export const GET_ALL_REGISTRATION = 'GET_ALL_REGISTRATION'
export const CREATE_REGISTRATION = 'CREATE_REGISTRATION'
export const UPDATE_REGISTRATION = 'UPDATE_REGISTRATION'
export const DELETE_REGISTRATION = 'DELETE_REGISTRATION'

// booking breeds
export const CREATE_BOOKING_BREED = 'CREATE_BOOKING_BREED'
export const GET_ALL_BOOKING_BREED = 'GET_ALL_BOOKINg_BREED'
export const UPDATE_BOOKING_BREED = 'UPDATE_BOOKING_BREED'
export const DELETE_BOOKING_BREED = 'DELETE_BOOKING_BREED'