import React, { useMemo } from "react";
import Pending from "./components/Pending";
import Completed from "./components/Completed";
import Deleted from "./components/Deleted";
import ChakraTabs from "../../components/ChakraTabs";
import { useAllSpecialRequest } from "../../features/Special-request/specialRequestServices";


const SpecialRequest = () => {

  const {
    data: requests = [],
    isLoading,
    error,
  } = useAllSpecialRequest("/api/special-requests");
  console.log("requests tabs===>", requests);

  const pendingData = useMemo(
    () => requests?.filter((request) => request.status === "pending"),
    [requests],
  );

  const completedData = useMemo(
    () => requests?.filter((request) => request.status === "complete"),
    [requests],
  );
  const deletedData = useMemo(
    () => requests?.filter((request) => request.status === "delete"),
    [requests],
  );

  if (error) {
    return <div>Error loading special requests: {error.message}</div>;
  }

  console.log("Pending Data===>",pendingData)
  console.log("Completed Data===>",completedData)
  console.log("Deleted Data===>",deletedData)

  const tabData = [
    {
      label: "Pending",
      content: <Pending/>,
    },
    {
      label: "Completed",
      content: <Completed/>,
    },
    {
      label: "Deleted",
      content: <Deleted />,
    },
  ];
  return (
    <div>
    
      <ChakraTabs tabData={tabData} />
    </div>
  );
};

export default SpecialRequest;
