import { lazy } from "react";
import Signup from "../dashboard/Signup";
import Signin from "../dashboard/Signin";
import ForgetPassword from "../dashboard/ForgetPassword";
import AccountVerification from "../dashboard/AccountVerification"


// const AccountVerification = lazy(
//   () => import("../dashboard/AccountVerification"),
// );
const CreateAdmin = lazy(() => import("../dashboard/createAdmin"));
const DashboardHome = lazy(() => import("../dashboard/DashboardHome"));
// const ForgetPassword = lazy(() => import("../dashboard/ForgetPassword"));

export const adminAuthRoutes = [
  { path: "/admin/signup", element: <Signup /> },
  { path: "/admin/signin", element: <Signin /> },
  {
    path: "/verify-account",
    element: <AccountVerification />,
  },
  {
    path: "/password-reset",
    element: <ForgetPassword />,
  },
];

export const adminRoutes = [
  {
    path: "/admin",
    element: <DashboardHome />,
    exact: true,
    redirect: "/admin/signin",
  },

  {
    path: "/admin/create-employee",
    element: <CreateAdmin />,
    redirect: "/admin/signin",
  },
  {
    path: "/admin/:subpage",
    element: <DashboardHome />,
    redirect: "/admin/signin",
  },
  {
    path: "/admin/:subpage/:subsubpage",
    element: <DashboardHome />,
    redirect: "/admin/signin",
  },
];
