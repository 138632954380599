import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../utils/uploadFile";

import { CiSquareRemove } from "react-icons/ci";
import {
  useCreatePups,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";
import { useAllBreeds } from "../../../features/Breeds/Breeds";
import moment from "moment";
import dayjs from "dayjs";
import {
  useCreateProducts,
  useUpdateProductsMutation,
} from "../../../features/Products/productsService";
import { useAllFAQS_CATEGORY } from "../../../features/Faqs/faqsService";

const { TextArea } = Input;

const FaqsForm = ({ onClose, selectedData, isUpdateModal }) => {
  const { data: products } = useAllBreeds("/api/products/");
  const { data: faqsCategory } = useAllFAQS_CATEGORY("/api/faq-categories");

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreateProducts();
  const UpdateMutation = useUpdateProductsMutation();

  const [form] = Form.useForm();

  console.log(selectedData,"selectedData")
  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        // media: selectedData[0]?.media[0] ?? null,
        question: selectedData[0]?.question ?? null,
        answer: selectedData[0]?.answer ?? null,
        featured: selectedData[0]?.featured ?? null,
        category: selectedData[0]?.category?._id ?? null,
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
      category: values.category ?? null,
      question: values.question ?? null,
      answer: values.answer ?? null,
      featured: values.featured ?? null,
    };

    try {
      if (isUpdateModal && selectedData) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/faq/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "Faq Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/Faq",
          data,
        });

        toast({
          title: "Faq created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      // Reset form and clear states if needed
      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "FAQ Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating FAQ",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [media, setMedia] = useState([]);

  const breedOptions = faqsCategory?.map((breed) => ({
    value: breed._id,
    label: breed?.category,
  }));

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Faqs Information
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <Form.Item
              name={"category"}
              label="category"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select a breed"
                style={{ width: "100%" }}
                allowClear
                options={breedOptions}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={"question"} label="Question">
              <Input placeholder="Question" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={"answer"} label="Answer">
              <Input placeholder="Answer" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={"featured"}
              valuePropName="checked"
              label="Featured"
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default FaqsForm;
