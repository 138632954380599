import Quill from "quill";
import React, { useEffect, useRef, useState } from "react";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css"; // Add this line

import { Delta } from "quill/core";
// Remove the import of the custom Editor component

// Add this import for the custom CSS

const TextEditor = ({ value, onChange }) => {
  const [range, setRange] = useState();
  const [lastChange, setLastChange] = useState();
  const [readOnly, setReadOnly] = useState(false);
  const quillRef = useRef();
  const editorRef = useRef();

  useEffect(() => {
    if (editorRef.current) {
      const quill = new Quill(editorRef.current, {
        theme: "snow",
        readOnly: readOnly,
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ align: [] }],
            ["link", "image", "video"],
            ["clean"],
          ],
        },
      });

      if (value) {
        quill.setContents(quill.clipboard.convert(value));
      }

      quill.on("text-change", (delta, oldDelta, source) => {
        // setLastChange(delta);
        if (source === "user") {
          const content = quill.root.innerHTML;
          if (onChange) {
            onChange(content);
          }
        }
      });

      // quill.on("selection-change", (range, oldRange, source) => {
      //   setRange(range);
      // });

      quillRef.current = quill;
    }
  }, []);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current;
      const editorContent = quill.root.innerHTML;
      // quillRef.current.enable(!readOnly);
      if (value !== editorContent) {
        quill.root.innerHTML = value || "";
      }
    }
  }, [value]);

  return (
    <div className="editor-container">
      <div className="editor-wrapper">
        <div ref={editorRef} />
      </div>
      {/* <div class="controls">
        <label>
          Read Only:{" "}
          <input
            type="checkbox"
            value={readOnly}
            onChange={(e) => setReadOnly(e.target.checked)}
          />
        </label>
        <button
          className="controls-right"
          type="button"
          onClick={() => {
            alert(quillRef.current?.getLength());
          }}
        >
          Get Content Length
        </button>
      </div>
      <div className="state">
        <div className="state-title">Current Range:</div>
        {range ? JSON.stringify(range) : "Empty"}
      </div>
      <div className="state">
        <div className="state-title">Last Change:</div>
        {lastChange ? JSON.stringify(lastChange.ops) : "Empty"}
      </div> */}
    </div>
  );
};

export default TextEditor;
