import React from "react";
import AdminLayout from "../components/Layout/AdminLayout";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminRouteWrapper = ({ element, redirect }) => {
  const isAdminAuth = useSelector((state) => state.auth.isAdminAuth);

  return isAdminAuth ? (
    <AdminLayout>{element}</AdminLayout>
  ) : (
    <Navigate to={redirect} />
  );
};

export default AdminRouteWrapper;
