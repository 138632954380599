import React from "react";
import Table3 from "../../components/Table3";
import TableReviews from "./components/TableReviews";

const Reviews = () => {
  return (
    <div>
      {/* <Table3 /> */}
      <TableReviews />
    </div>
  );
};

export default Reviews;
