import React, { useEffect, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import {
  useCreateDeliveryOption,
  useAllDeliveryOptions,
  useDeleteDeliveryOptionMutation,
} from "../../../features/delivery-option/deliveryOptionsService";

import { useDisclosure, useToast } from "@chakra-ui/react";
import toast from "react-hot-toast";
import { GlobalModalV1 } from "../../../components/GlobalModal";

import moment from "moment";
import AntModal from "../../../components/AntModal";
import OfferForm from "./OfferForm";
import { useAllOffer } from "../../../features/Offer/OfferService";

const TableOffer = ({offers, isLoading, error}) => {

  const deleteMutation = useDeleteDeliveryOptionMutation();
  console.log('offers', offers)
  

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState(null);
  const toast = useToast();

  const handleDelete = () => {
    setActionType("delete");
    setIsModalVisible(true);
  };

  // const handleUpdate = () => {
  //   const filter = offers?.filter(
  //     (item) => item?._id === selectedIdEdit,
  //   );
  //   setSelectedData(filter);
  //   setSelectedIdEdit(null);
  //   onOpen();
  // };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
    }
    if (selectedIdEdit) {
      // handleUpdate();
    }
  }, [selectedId, selectedIdEdit]);

  const handleConfirm = async () => {
    setIsModalVisible(false);
    deleteMutation.mutate(
      { apiUrl: `/api/offer/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast({
            title: "Offer Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted offer");
          toast({
            title: "Error deleted offer",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setActionType(null);
    setSelectedId(null);
  };

  const btnComponent = (props) => {
    const handleEdit = () => {
      setSelectedIdEdit(props?.data?._id);
    };

    const handleDeleteClick = () => {
      setSelectedId(props?.data?._id);
      console.log(props?.data);
    };

    return (
      <div className="flex gap-5 pt-2">
        <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          onClick={handleEdit}
        >
          <AiFillEdit />
        </button>

        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
          onClick={handleDeleteClick}
        >
          <LiaTimesSolid />
        </button>
      </div>
    );
  };

  const tableColumnData = [
    {
      headerName: "First Name",
      valueGetter: (params)=> params?.data?.orders?.destinationAddress?.firstName
     
    },
    {
      headerName: "Last Name",
      field: "lastName",
      
    },
    {
      headerName: "Phone",
      field: "phone",
      
    },
    {
      headerName: "Order Date",
      field: "orderData",
      
    },
    {
      headerName: "Description",
      field: "description",
      
    },
    {
      headerName: "Shipping Method",
      field: "shippingMethod",
      
    },
    {
      headerName: "Cage",
      field: "cage",
      
    },
    {
      headerName: "F&T",
      field: "FAndT",
      
    },
    {
      headerName: "MC",
      field: "MC",
      
    },
    {
      headerName: "PK",
      field: "PK",
      
    },
    {
      headerName: "Offer",
      field: "offer",
      
    },
    {
      headerName: "Offered Price",
      field: "OfferedPrice",
      
    },
    {
      headerName: "Pup Price",
      field: "pupPrice",
      
    },
    {
      headerName: "Price",
      field: "price",
      
    },
    {
      headerName: "Acceptable Amount",
      field: "acceptableAmount",
      
    },
    {
      headerName: "Purchaser",
      field: "purchaser",
      
    },
    {
      headerName: "Offer Notes",
      field: "offerNotes",
      
    },
    {
      headerName: "Actions",
      cellRenderer: btnComponent,
      floatingFilter: false,
      
    },
  ];

  const tableRowData =
    offers?.map((offer, index) => ({
        _id: offer._id,
        firstName: offer?.orders?.orders?.destinationAddress?.firstName ?? "",
        lastName: offer?.orders?.billingAddress?.lastName ?? "",
        phone: offer?.orders?.contactInformation?.cellPhone ?? "",
        orderDate: offer?.orders?.createdAt?? "",
        description: offer?.puppies?.listingInformation?.description ?? "",
        // shippingMethod: deliveryOption?.state ?? "",
        cage: offer?.puppies?.puppyInformation?.cageNumber ?? "",
        // FAndT: deliveryOption?.noSalesTax ?? "",
        // MC: deliveryOption?.taxRate ?? "",
        // PK: deliveryOption?.chargeTaxOnDeliveryService ?? "",
        // offer: deliveryOption?.pickUp ?? "",
        OfferedPrice: offer?.puppies?.listingInformation?.offerPrice ?? "",
        pupPrice: offer?.puppies?.puppyInformation?.cost?? "",
        price: offer?.puppies?.listingInformation?.price ?? "",
        // acceptableAmount: deliveryOption?.zone ?? "",
        purchaser: offer?.puppies?.puppyInformation?.purchaser?? "",
        // offerNotes: deliveryOption?.zone ?? "",
    })) ??
     [];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: false,
  
    floatingFilter: true,
  };

  return (
    <div className="ag-theme-quartz my-5" style={{ height: 500 }}>
      <AgGridReact
        debug={true}
        modules={[ClientSideRowModelModule]}
        rowData={tableRowData}
        columnDefs={tableColumnData}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 25, 50]}
      />

      <AntModal
        centered
        isOpen={isModalVisible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={
          actionType === "approve" ? "Confirm Approval" : "Confirm Deletion"
        }
        content={
          actionType === "approve"
            ? "Are you sure you want to approve this offer?"
            : "Are you sure you want to delete this offer?"
        }
        okText={"Confirm"}
      />

{/* <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <OfferForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1> */}

    </div>
  );
};

export default TableOffer;
