import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, useToast } from "@chakra-ui/react";
import { sendPasswordResetEmail } from "../features/auth/authService";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const ForgetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { isUserAuth, isAdminAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { email } = data;
    setIsLoading(true);
    try {
      const response = await sendPasswordResetEmail(email);
      const responseData = response.data;

      toast({
        title: "Email Sent",
        description: responseData.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Error",
        description:
          error.response?.data?.message ||
          "An error occurred while sending the email.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isUserAuth) {
      navigate("/");
    } else if (isAdminAuth) {
      navigate("/admin/watchlist");
    }
  }, [isUserAuth, isAdminAuth, navigate]);

  if (isAdminAuth || isUserAuth) return null;

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="grid w-full grid-cols-1 items-center justify-between md:grid-cols-2">
        <div className="hidden items-center justify-center md:flex">
          <img src="/images/logo.svg" alt="logo" width={400} />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex min-h-screen w-full flex-col items-center justify-center gap-6 bg-[#001219]"
        >
          <img
            src="/images/logo.svg"
            alt="logo"
            className="flex md:hidden"
            width={200}
          />
          <h1 className="text-3xl text-white">Reset your password</h1>
          <div className="flex w-full flex-col items-center justify-center gap-6 px-4 sm:px-0">
            <label htmlFor="email" className="w-1/2 text-white">
              Enter your user account&apos;s verified email address and we will
              send you a password reset link.
            </label>

            <div className="flex w-full flex-col items-center">
              <div className="w-[50%] py-1">
                <input
                  type="email"
                  placeholder="Enter email"
                  className="w-full rounded-lg p-3 outline-none"
                  // onChange={(e) => setUserEmail(e.target.value)}
                  // disabled={isLoading}

                  {...register("email")}
                />

                {errors?.email && (
                  <p
                    style={{
                      marginTop: "5px",
                      color: "red",
                      fontWeight: 600,
                    }}
                  >
                    {errors?.email?.message}
                  </p>
                )}
              </div>
            </div>
            <Button
              isLoading={isLoading}
              loadingText="Submitting"
              bg="#0C96CC"
              color="white"
              p={3}
              borderRadius="lg"
              width="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              type="submit"
              _hover={{ bg: "#0B84A3" }}
            >
              Send password reset email
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
