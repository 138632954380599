import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { GET_ALL_BOOKING, GET_ALL_BOOKING_BREED, GET_ALL_REGISTRATION } from "./BookingType"
import { create, Delete, fetchAll, update } from "../Apis/Apis"


export const useAllBooking = (apiUrl) => {
    return useQuery({
        queryKey: [GET_ALL_BOOKING],
        queryFn: ()=> fetchAll(apiUrl)
    })
};

export const useCreateBooking = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: create,
        onMutate: async (newBooking) => {
            queryClient.invalidateQueries(GET_ALL_BOOKING);
        },
        onError: (error, newBooking, context)=> {
            console.log('error creating booking', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_BOOKING)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_BOOKING)
        }
    })
}

export const useUpdateBooking = ()=>{
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: update,
        onMutate: async (newBooking) =>{
            queryClient.invalidateQueries(GET_ALL_BOOKING)
        },
        onError: (error, newBooking, context) => {
            console.log('error updating booking', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_BOOKING)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_BOOKING)
        }
    })
}

export const useDeleteBooking = ()=> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: Delete,
        onMutate: async (newBooking)=> {
            queryClient.invalidateQueries(GET_ALL_BOOKING)
        },
        onError: (error, newBooking, context) => {
            console.log('error deleting booking', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_BOOKING)
        },
        onSettled: ()=>{
            queryClient.invalidateQueries(GET_ALL_BOOKING)
        }
    })
}

/// registration
export const useAllRegistration = (apiUrl)=> {
    return useQuery({
        queryKey: [GET_ALL_REGISTRATION],
        queryFn: ()=>fetchAll(apiUrl)
    })
}

export const useCreateRegistration = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: create,
        onMutate: async (newBooking) => {
            queryClient.invalidateQueries(GET_ALL_REGISTRATION);
        },
        onError: (error, newBooking, context)=> {
            console.log('error creating registration', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_REGISTRATION)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_REGISTRATION)
        }
    })
}

export const useUpdateRegistration = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: update,
        onMutate: (newRegistration)=>{
            queryClient.invalidateQueries(GET_ALL_REGISTRATION)
        },
        onError: (newRegistration, error, context)=>{
            console.log('error updating registration', error)
        },
        onSuccess: (data)=>{
            queryClient.invalidateQueries(GET_ALL_REGISTRATION)
        },
        onSettled: ()=>{
            queryClient.invalidateQueries(GET_ALL_REGISTRATION)
        }
    })
}


export const useDeleteRegistration  = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: Delete,
        onMutate: async (newRegistration) => {
            queryClient.invalidateQueries(GET_ALL_REGISTRATION)
        },
        onError: (newRegistration,error,context) => {
            console.log('error deleting registration', error)
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_REGISTRATION)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_REGISTRATION)
        }
    })
}


// BOOKING BREEDS
export const useAllBookingBreeds = (apiUrl)=>{
    return useQuery({
        queryKey: [GET_ALL_BOOKING_BREED],
        queryFn: ()=> fetchAll(apiUrl)
    })
}

export const useCreateBookingBreed = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: create,
        onMutate: async (newBooking) => {
            queryClient.invalidateQueries(GET_ALL_BOOKING_BREED);
        },
        onError: (error, newBooking, context)=> {
            console.log('error creating registration', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_BOOKING_BREED)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_BOOKING_BREED)
        }
    })
}

export const useUpdateBookingBreed = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: update,
        onMutate: (newRegistration)=>{
            queryClient.invalidateQueries(GET_ALL_BOOKING_BREED)
        },
        onError: (newRegistration, error, context)=>{
            console.log('error updating registration', error)
        },
        onSuccess: (data)=>{
            queryClient.invalidateQueries(GET_ALL_BOOKING_BREED)
        },
        onSettled: ()=>{
            queryClient.invalidateQueries(GET_ALL_BOOKING_BREED)
        }
    })
}


export const useDeleteBookingBreed  = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: Delete,
        onMutate: async (newRegistration) => {
            queryClient.invalidateQueries(GET_ALL_BOOKING_BREED)
        },
        onError: (newRegistration,error,context) => {
            console.log('error deleting registration', error)
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_BOOKING_BREED)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_BOOKING_BREED)
        }
    })
}