import React, { useState, useEffect } from "react";
import { Button, DatePicker, Dropdown, Space } from "antd";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { LiaTimesSolid } from "react-icons/lia";

import GlobalLoader from "../../../components/GlobalLoader.jsx";
import { AiFillEdit } from "react-icons/ai";
import { useDisclosure, useToast } from "@chakra-ui/react";

import { GlobalModalV1 } from "../../../components/GlobalModal.jsx";

import { useAllWholeSales, useDeleteWholeSales } from "../../../features/wholesale/wholesalesService.js";
import WholesalesForms from "./WholesalesForms.jsx";
import { getDate } from "../../../utils/index.js";

const WholesalesTables = () => {
  const { data: wholesales, isLoading, error } = useAllWholeSales("/api/wholesales");
  const { isOpen, onOpen, onClose } = useDisclosure();


  const filterProduct = wholesales?.map((product, index) => ({
    ...product,
    id: index + 1, 
    status: product?.status ? 'paid' : 'owe'

  }));
  
  console.log(filterProduct, "filterProduct");

  console.log(wholesales, "wholesales");
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const toast = useToast();

  const createDeleteMutation = useDeleteWholeSales();

  const handleDelete = () => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/wholesale/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "wholesales Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted wholesales");
          toast({
            title: "Error deleted wholesales",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = filterProduct?.filter(
      (item) => item?._id === selectedIdEdit,
    );
    console.log(filter, "filter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
      onOpen();
    };
    return (
      <div className="flex gap-5 pt-2">
        <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          onClick={handle}
        >
          <AiFillEdit />
        </button>

        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
          onClick={() => setSelectedId(props.data._id)}
        >
          <LiaTimesSolid />
        </button>
      </div>
    );
  };

  const columns = [
    {
      headerName: "ID",
      field: "id",
    },
    {
      headerName: "Transfer Date",
      valueGetter: (params)=> getDate(params.data.transferDate)
    },
    {
      headerName: "Purchaser",
      field: "purchaser",
    },
    {
      headerName: "quantity",
      field: "quantity",
    },
    {
      headerName: "Route",
      field: "route",
    },
    {
      headerName: "Arrival Location",
      field: "arrivalLocation",
    },
    {
      headerName: "Flight",
      field: "flight",
    },
    {
      headerName: "Tracking",
      field: "tracking",
    },
    {
      headerName: "Disposition Date",
      valueGetter: (params)=> getDate(params.data.dispositionDate)
    },
    {
      headerName: "Airlines",
      field: "airLines",
    },
    {
      headerName: "Depart",
      field: "depart",
    },
    {
      headerName: "Status",
      field: "status",
    },
    
  

    {
      headerName: "Action",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    floatingFilter: true,
  };

  const items = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
      danger: true,
    },
    {
      label: "4th menu item",
      key: "4",
      danger: true,
      disabled: true,
    },
  ];

  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading Wholesale</p>;

  return (
    <div className="my-10">

      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={filterProduct}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <WholesalesForms
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>
    </div>
  );
};

export default WholesalesTables;
