import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { useSelector } from "react-redux";

const MainLayout = ({ children }) => {

  return (
    <div>
      <Navbar  />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default MainLayout;
