import React from 'react';

const AdminLayout = ({ children }) => {
    return (
        <div>
            {/* <Navbar /> */}
            <main>{children}</main>
            {/* <Footer /> */}
        </div>
    );
};

export default AdminLayout;
