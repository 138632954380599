import { Route, Routes, useLocation, Navigate } from "react-router-dom";

import Signup from "./dashboard/Signup";
import Signin from "./dashboard/Signin";
import DashboardHome from "./dashboard/DashboardHome";

import MainLayout from "./components/Layout/MainLayout";
import AdminLayout from "./components/Layout/AdminLayout";
import NotFound from "./components/NotFound";
import { useSelector } from "react-redux";
import CreateAdmin from "./dashboard/createAdmin";
import { lazy, Suspense, useEffect } from "react";
import GlobalLoader from "./components/GlobalLoader";
import BuyNow from "./pages/BuyNow";
import Tiptap from "./Tiptap";
import OrderConfirmedPage from "./pages/OrderConfirmedPage.jsx";
import Teams from "./pages/Teams.jsx";
import Warranty from "./pages/Warranty.jsx";
import PetInsurance from "./pages/PetInsurance.jsx";
import Privacy from "./pages/Privacy.jsx";
import PublicRouteWrapper from "./wrappers/PublicRouteWrapper.js";
import AdminRouteWrapper from "./wrappers/AdminRouteWrapper.js";
import { publicRoutes } from "./routes/publicRoutes.js";
import { adminAuthRoutes, adminRoutes } from "./routes/adminRoutes.js";

function App() {
  const isUserAuth = useSelector((state) => state.auth.isUserAuth);
  const isAdminAuth = useSelector((state) => state.auth.isAdminAuth);

  console.log(isUserAuth, "isUserAuth");
  console.log(isAdminAuth, "isAdminAuth");

  const { pathname } = useLocation();

  console.log("Pathname===>", pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  const Breeds = lazy(() => import("./pages/Breeds"));
  const PupsDetails = lazy(() => import("./pages/PupsDetails"));
  const Testimonials = lazy(() => import("./pages/Testimonials"));
  const Faq = lazy(() => import("./pages/Faq"));
  const Contact = lazy(() => import("./pages/Contact"));
  const CustomerPhotos = lazy(() => import("./pages/CustomerPhotos"));
  const ReviewDetails = lazy(() => import("./pages/ReviewDetails"));
  const ForgetPassword = lazy(() => import("./dashboard/ForgetPassword"));
  const AccountVerification = lazy(
    () => import("./dashboard/AccountVerification"),
  );
  const Home = lazy(() => import("./pages/Home"));
  const CustomerReviews = lazy(() => import("./pages/CustomerReviews"));
  const Shipping = lazy(() => import("./pages/Shipping"));
  const Cavapoo101 = lazy(() => import("./pages/Cavapoo101.jsx"));

  return (
    <>
      <Routes>
        {/* <Route
          path="/verify-account"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <AccountVerification />{" "}
            </Suspense>
          }
        />
        <Route
          path="/password-reset"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <ForgetPassword />
            </Suspense>
          }
        /> */}

        {/* Public Routes / Home Routes */}
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Suspense fallback={<GlobalLoader />}>
                <PublicRouteWrapper element={route.element} />
              </Suspense>
            }
          />
        ))}

        {/* Admin auth routes */}
        {adminAuthRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}

        {/* Admin Routes / Dashboard Routes / Protected Routes */}
        {adminRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Suspense fallback={<GlobalLoader />}>
                <AdminRouteWrapper
                  element={route.element}
                  redirect={route?.redirect}
                />
              </Suspense>
            }
          />
        ))}

        {/* 404 Route */}
        <Route path="*" element={<NotFound />} />

        {/* <Route path="/" element={<PublicRouteWrapper element={<Home />} />} />
        <Route
          path="/breeds"
          element={<PublicRouteWrapper element={<Breeds />} />}
        />
        <Route
          path="/breeds/pups-details/:id"
          element={<PublicRouteWrapper element={<PupsDetails />} />}
        />
        <Route
          path="/breeds/:productName/buy-now/:id"
          element={<PublicRouteWrapper element={<BuyNow />} />}
        />

        <Route
          path="/testimonials"
          element={<PublicRouteWrapper element={<Testimonials />} />}
        />
        <Route path="/faq" element={<PublicRouteWrapper element={<Faq />} />} />
        <Route
          path="/contact"
          element={<PublicRouteWrapper element={<Contact />} />}
        />
        <Route
          path="/customer-photos/details/:id"
          element={<PublicRouteWrapper element={<ReviewDetails />} />}
        />
        <Route
          path="/customer-reviews"
          element={<PublicRouteWrapper element={<CustomerReviews />} />}
        />
        <Route
          path="/customer-photos"
          element={<PublicRouteWrapper element={<CustomerPhotos />} />}
        />
        <Route
          path="/cavapoo101"
          element={<PublicRouteWrapper element={<Cavapoo101 />} />}
        />
        <Route
          path="/test"
          element={<PublicRouteWrapper element={<Tiptap />} />}
        />
        <Route
          path="/shipping"
          element={<PublicRouteWrapper element={<Shipping />} />}
        />

        <Route
          path="/confirm"
          element={<PublicRouteWrapper element={<OrderConfirmedPage />} />}
        />
        <Route
          path="/teams"
          element={<PublicRouteWrapper element={<Teams />} />}
        />
        <Route
          path="/health-guarantee"
          element={<PublicRouteWrapper element={<Warranty />} />}
        />
        <Route
          path="/pet-insurance"
          element={<PublicRouteWrapper element={<PetInsurance />} />}
        />
        <Route
          path="/privacy"
          element={<PublicRouteWrapper element={<Privacy />} />}
        /> */}

        {/* Admin Routes */}
        {/* <Route path="/admin/signup" element={<Signup />} />
        <Route path="/admin/signin" element={<Signin />} />
        <Route
          path="/admin"
          element={
            <AdminRouteWrapper
              element={<DashboardHome />}
              redirect="/admin/signin"
            />
          }
        >
          <Route path=":subpage" element={<DashboardHome />} />
          <Route path=":subpage/:subsubpage" element={<DashboardHome />} />
        </Route>
        <Route
          path="/admin/create-admin"
          element={
            <AdminRouteWrapper
              element={<CreateAdmin />}
              redirect={"/admin/signin"}
            />
          }
        /> */}

        {/* 404 Route */}
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </>
  );
}

export default App;
