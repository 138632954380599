import React from "react";
import PupDetails from "./innertabpages/PupDetails.jsx";
import Breeder from "./innertabpages/Breeder.jsx";
import ParentInfo from "./innertabpages/ParentInfo.jsx";
import RegistrationPaper from "./innertabpages/RegistrationPaper.jsx";
import HealthcareCertificate from "./innertabpages/HealthcareCertificate.jsx";
import NNCertificate from "./innertabpages/NNCertificate.jsx";
import ChakraTabs from "../../../../../../components/ChakraTabs.jsx"

const PupInfo = ({data}) => {
  const tabDataPrimary = [
    {
      label: "Pup Details",
      content: <PupDetails data={data} />,
    },
    {
      label: "Breed Details",
      content: <Breeder data={data} />,
    },
    {
      label: "Parent Info",
      content: <ParentInfo data={data} />,
    },
    // {
    //   label: "Registration Paper",
    //   content: <RegistrationPaper />,
    // },
  ];

  const tabDataSecondary = [
    {
      label: "Health Certificate",
      content: <HealthcareCertificate />,
    },
    {
      label: "New Name Certificate",
      content: <NNCertificate />,
    },
  ];
  return (
    <div className="my-10 grid grid-cols-1 gap-10 md:grid-cols-5">
      <div className="col-span-3 min-h-[800px] w-full rounded-[20px] bg-white p-10">
        <ChakraTabs tabData={tabDataPrimary} />
      </div>
      {/* <div className="col-span-2 h-[300px] w-full overflow-y-auto rounded-[20px] bg-white p-10">
        <ChakraTabs tabData={tabDataSecondary} />
      </div> */}
    </div>
  );
};

export default PupInfo;
