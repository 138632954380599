import React, { useState } from "react";
import SidebarCustom from "./Sidebar";
import {
  MdOutlineNotifications,
  MdOutlinePowerSettingsNew,
} from "react-icons/md";
import { VscKey } from "react-icons/vsc";
import { Link, useNavigate, useParams } from "react-router-dom";
import Watchlist from "./watchlist/Watchlist";
import Offers from "./offers/Offers";
import Insurance from "./insurance/Insurance";
import Deliveries from "./deliveries/Deliveries";
import SpecialRequest from "./special-request/SpecialRequest";
import { LOGIN_SUCCESS, LOGOUT } from "../features/auth/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import SalesDashboard from "./sales-dashboard/SalesDashboard";
import Reviews from "./reviews/Reviews";
import Products from "./products/Products";
import BreedInfo from "./breedInfo/BreedInfo";
import Pups from "./Pups/Pups";
import Orders from "./orders/Orders";
import Faqs from "./faqs/Faqs";
import Pages from "./pages/Pages";
import Teams from "./teams/Teams";
import TargetCities from "./target-cities/TargetCities";
import Discounts from "./discounts/Discounts";
import DeliveryOptions from "./delivery-options/DeliveryOptions";
import Gallery from "./gallery/Gallery";
import Booking from "./bookings/Booking";
import NkDashboards from "./nk-dashboard/NkDashboards";
import Wholesale from "./wholesale/Wholesale";
import HealthServices from "./health-services/HealthServices";
import GrossMargin from "./gm/GrossMargin";
import EmailAndSms from "./emailAndSms/EmailAndSms";
import ParentInfo from "./ParentInfo/ParentInfo";
import Email from "./email/Email";
import AntModal from "../components/AntModal";
import Cavapoo101 from "./cavapoo101/Cavapoo101";

const DashboardHome = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();
  const param = useParams();

  const subpage = param.subpage ? param.subpage : "sales-dashboard";
  console.log("Subpage==>", subpage);

  const pageName = subpage ? subpage.replace("-", " ") : "sales-dashboard";
  const dispatch = useDispatch();

  //Menu Toggle Handler
  const handleMenuToggle = () => {
    setMenuToggle(!menuToggle);
  };

  const handleConfirm = () => {
    dispatch({ type: LOGOUT });
    setIsModalVisible(!isModalVisible);
  };
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div className="nunito-sans flex bg-[#EDEDE9]">
      <SidebarCustom subpage={subpage} className="flex-shrink-0 bg-gray-800" />
      <div className="min-h-screen w-screen px-10">
        <header className="mx-auto flex h-auto w-full items-center justify-between py-4">
          <div className="flex items-center gap-4">
            <button
              onClick={() => window.history.back()}
              className="flex items-center justify-center rounded-full bg-white p-2 text-gray-500 hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </button>
            <h1 className="text-2xl font-semibold capitalize">{pageName}</h1>
          </div>
          <div className="flex items-center gap-4 rounded-[50px] bg-white p-2 text-gray-500">
            {/* <input
              type="text"
              placeholder="Search"
              className="rounded-3xl bg-[#EDEDE9] px-4 py-2 focus:outline-none"
            /> */}
            {/* <MdOutlineNotifications size={22} className="cursor-pointer" /> */}
            <MdOutlinePowerSettingsNew
              size={22}
              className="cursor-pointer font-bold duration-200 hover:text-red-600"
              onClick={() => setIsModalVisible(true)}
            />
            {/* <VscKey size={22} className="cursor-pointer" /> */}
            {/* <RxAvatar size={22} className="cursor-pointer" /> */}
            <img
              onClick={handleMenuToggle}
              src="/images/Avatar.svg"
              alt="user-avatar"
              className="cursor-pointer rounded-full"
            />
          </div>
        </header>
        {menuToggle && (
          <div className="flex">
            <div
              className={`absolute right-20 z-20 mx-auto my-2 min-h-[250px] min-w-[380px] overflow-hidden rounded-2xl bg-white bg-opacity-70 text-black backdrop-blur-lg backdrop-filter transition-all duration-300 md:right-10 ${
                menuToggle
                  ? "top-20 translate-y-0"
                  : "top-0 translate-y-[-100%]"
              }`}
            >
              <ul className="flex list-none flex-col items-start justify-end p-6 tracking-wider">
                <li
                  onClick={() => setMenuToggle(!menuToggle)}
                  className="bg-primary w-full cursor-pointer py-2 text-[18px] font-medium transition-all hover:font-bold"
                >
                  <Link to={"/"}>User Home</Link>
                </li>
                <li
                  onClick={() => setMenuToggle(!menuToggle)}
                  className="hover:bg-primary w-full cursor-pointer py-2 text-[18px] font-medium transition-all hover:font-bold"
                >
                  {/* <Link to="/admin/signin">Sign In</Link> */}
                </li>
                <li
                  onClick={() => setMenuToggle(!menuToggle)}
                  className="hover:bg-primary w-full cursor-pointer py-2 text-[18px] font-medium transition-all hover:font-bold"
                >
                  <Link to="/admin/create-employee">Create New Employee</Link>
                </li>
                {/* <li className="border-t border-gray-400 pt-4">
                  Experimental Features here
                </li> */}
              </ul>
            </div>
          </div>
        )}
        {/* Sales & Services */}
        {subpage === "watchlist" && <Watchlist />}
        {subpage === "sales-dashboard" && <SalesDashboard />}
        {subpage === "orders" && <Orders />}
        {subpage === "offers" && <Offers />}
        {subpage === "insurance" && <Insurance />}
        {subpage === "deliveries" && <Deliveries />}
        {subpage === "health-services" && <HealthServices />}
        {subpage === "special-requests" && <SpecialRequest />}
        {subpage === "reviews" && <Reviews />}

        {/* Pup Listing */}
        {subpage === "products" && <Products />}
        {subpage === "pups-listing" && <Pups />}

        {/* Tracking */}
        {subpage === "wholesales" && <Wholesale />}

        {/* NK / Breeder Management */}
        {subpage === "nk-dashboards" && <NkDashboards />}
        {subpage === "bookings" && <Booking />}

        {/* Checkout */}
        {subpage === "delivery-options" && <DeliveryOptions />}
        {subpage === "discounts" && <Discounts />}

        {/* Website Content */}
        {subpage === "gallery" && <Gallery />}
        {subpage === "breed-info" && <BreedInfo />}
        {subpage === "parent-info" && <ParentInfo />}
        {subpage === "faqs" && <Faqs />}
        {subpage === "pages" && <Pages />}
        {subpage === "teams" && <Teams />}
        {subpage === "target-cities" && <TargetCities />}
        {subpage === "cavaPoo101" && <Cavapoo101 />}

        {/* Settings */}
        {subpage === "email-sms-settings" && <EmailAndSms />}
        {subpage === "email" && <Email />}

        {/* Utils */}
        {subpage === "gross-margin" && <GrossMargin />}
      </div>

      <AntModal
        centered
        isOpen={isModalVisible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        okText={"Yes"}
        content={"Are you sure want to logout?"}
        title={"Logout"}
      />
    </div>
  );
};

export default DashboardHome;
