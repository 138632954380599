import { Button, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../features/auth/authService";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const AccountVerification = () => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { isUserAuth, isAdminAuth } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  // Extract token from query parameter
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (isUserAuth) {
      navigate("/");
    } else if (isAdminAuth) {
      navigate("/admin/watchlist");
    }
  }, [isUserAuth, isAdminAuth, navigate]);

  const schema = yup
    .object({
      password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character",
        )
        .required("Password is required"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    })
    .required();  

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAccountVerification = async (data) => {
    setIsLoading(true);
    try {
      const response = await resetPassword(token, data.password);
      const responseData = response.data;

      toast({
        title: "Password Reset",
        description: responseData.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      reset();
      setIsLoading(false);
      navigate("/admin/signin");
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Error",
        description:
          error.response?.data?.message ||
          "An error occurred while resetting the password.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (isAdminAuth || isUserAuth) return null;

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="grid w-full grid-cols-1 items-center justify-between md:grid-cols-2">
        <div className="hidden items-center justify-center md:flex">
          <img src="/images/logo.svg" alt="logo" width={400} />
        </div>
        <form
          onSubmit={handleSubmit(handleAccountVerification)}
          className="flex min-h-screen w-full flex-col items-center justify-center gap-6 bg-[#001219]"
        >
          <img
            src="/images/logo.svg"
            alt="logo"
            className="flex md:hidden"
            width={200}
          />
          <h1 className="text-3xl text-white">Reset Account</h1>

          <div className="flex w-full flex-col items-center">
            <div className="w-[50%] py-4">
              <input
                type="password"
                placeholder="Enter password"
                className="w-full rounded-lg p-3 outline-none"
                // onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
                {...register("password")}
              />
              {errors?.password && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.password?.message}
                </p>
              )}
            </div>
            <div className="w-[50%] py-4">
              <input
                type="password"
                placeholder="Enter password"
                className="w-full rounded-lg p-3 outline-none"
                // onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
                {...register("confirmPassword")}
              />
              {errors?.confirmPassword && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.confirmPassword?.message}
                </p>
              )}
            </div>
          </div>
          <Button
            isLoading={isLoading}
            loadingText="Submitting"
            type="submit"
            w="50%"
            borderRadius="lg"
            p={3}
            color="white"
            backgroundColor="#0C96CC"
            _hover={{ backgroundColor: "#0A82B6" }}
          >
            Reset
          </Button>

          <p className="text-center text-sm text-gray-200">
            OR <br />
            <br />
            Click here to{" "}
            <Link
              to="/signin"
              className="cursor-pointer font-semibold uppercase underline"
            >
              Signin
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default AccountVerification;
