import { useToast } from "@chakra-ui/react";
import { Button, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import {
  useCreatePage,
  useUpdatePage,
} from "../../../features/Page/pageServices";
import TextEditor from "../../../components/TextEditor";

const AddPage = ({ pageHandler, selectedData, setSelectedData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const toast = useToast();
  const createMutation = useCreatePage();
  const UpdateMutation = useUpdatePage();

  useEffect(() => {
    if (selectedData) {
      form.setFieldsValue({
        content: selectedData[0]?.content ?? null,
        slug: selectedData[0]?.slug ?? null,
        heading: selectedData[0]?.heading ?? null,
        pageTitle: selectedData[0]?.pageTitle ?? null,
        metaDescription: selectedData[0]?.metaDescription ?? null,
      });
    }
  }, [selectedData]);

  console.log("selectedData==>", selectedData);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
      content: values.content ?? null,
      slug: values.slug ?? null,
      heading: values.heading ?? null,
      pageTitle: values.pageTitle ?? null,
      metaDescription: values.metaDescription ?? null,
    };

    try {
      if (selectedData && selectedData[0]?._id) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/page/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "page Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setSelectedData(null);
        pageHandler();
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/page",
          data,
        });

        toast({
          title: "Booking Breed created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      // Reset form and clear states if needed
      form.resetFields();
      setSelectedData(null);
      pageHandler();
      // onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Booking Breed Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating Booking Breed",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="grid w-full grid-cols-1 gap-5 md:grid-cols-3"
    >
      <div className="md:col-span-2">
        <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
          Content
        </div>
        <Form.Item
          name="content"
          label="Description"
          valuePropName="value"
          trigger="onChange"
        >
          {/* <TextArea rows={32} placeholder="Description" /> */}
          <TextEditor placeholder="Write your content here..." />
        </Form.Item>
      </div>
      <div className="flex flex-col gap-6 md:col-span-1">
        <div className="w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
          Meta
        </div>
        <Form.Item name="slug" label="Slug">
          <TextArea rows={3} placeholder="Slug" />
        </Form.Item>
        <Form.Item name="heading" label="Heading">
          <TextArea rows={3} placeholder="Heading" />
        </Form.Item>
        <Form.Item name="pageTitle" label="Page Title">
          <TextArea rows={3} placeholder="Page Title" />
        </Form.Item>
        <Form.Item name="metaDescription" label="Meta Description">
          <TextArea rows={3} placeholder="Meta Description" />
        </Form.Item>
      </div>

      <div className="flex items-center justify-center md:col-span-3">
        <div className="flex gap-4">
          <Button
            className="!bg-[#001219] !text-white"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
          <Button
            onClick={pageHandler}
            disabled={isLoading}
            iconPosition="end"
            size="lg"
          >
            Cancel
          </Button>
          {/* <button
            type="submit"
            className="rounded-md bg-[#001219] px-5 py-2 text-white"
          >
            Save
          </button>
          <button
            onClick={pageHandler}
            type="button"
            className="rounded-md bg-gray-300 px-5 py-2 text-black"
          >
            Cancel
          </button> */}
        </div>
      </div>
    </Form>
  );
};

export default AddPage;
