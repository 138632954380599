import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { create, Delete, fetchAll, update } from "../Apis/Apis.js";

import { GET_ALL_AKC } from "./AkcTypes";

export const useAllAkc = (apiUrl) => {
  return useQuery({
    queryKey: [GET_ALL_AKC],
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateAkc= () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_AKC);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Akc:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_AKC);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_AKC);
    },
  });
};

export const useDeleteAkcMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_AKC);
    },
    onError: (err, newProduct, context) => {
      console.log("Error Delete Akc:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_AKC);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_AKC);
    },
  });
};

export const useUpdateAkcMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_AKC);
    },
    onError: (err, newProduct, context) => {
      console.log("Error Updating Akc:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_AKC);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_AKC);
    },
  });
};

