import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, useToast } from "@chakra-ui/react";
import { register as signup } from "../features/auth/authService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const CreateAdmin = () => {
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const { isUserAuth, isAdminAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isUserAuth) {
      navigate("/");
    }
  }, [isUserAuth, isAdminAuth, navigate]);

  const schema = yup
    .object({
      name: yup.string().required("Name is required"),
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSignUp = async (data) => {
    setisLoading(true);
    const signupData = {
      name: data.name,
      email: data.email,
      password: data.password,
      role: "admin",
    };

    try {
      const response = await signup(signupData);
      const responseData = response.data;

      reset();

      toast({
        title: "Admin Invitation Sent",
        description:
          "Please ask the recipient to check their email to accept the admin role.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      toast({
        title: "Error",
        description:
          error.response?.data?.message ||
          "An error occurred during registration.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (isUserAuth) return null;

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="grid w-full grid-cols-1 items-center justify-between md:grid-cols-2">
        <div className="hidden items-center justify-center md:flex">
          <img src="/images/logo.svg" alt="logo" width={400} />
        </div>
        <form
          onSubmit={handleSubmit(handleSignUp)}
          className="flex min-h-screen w-full flex-col items-center justify-center gap-6 bg-[#001219]"
        >
          <img
            src="/images/logo.svg"
            alt="logo"
            className="flex md:hidden"
            width={200}
          />

          <h1 className="text-3xl text-white">Create Employee Account</h1>
          <div className="flex w-full flex-col items-center">
            <div className="w-[50%] py-4">
              <input
                type="text"
                placeholder="Enter name"
                className="w-full rounded-lg p-3 outline-none"
                // onChange={(e) => setUserEmail(e.target.value)}
                disabled={isLoading}

                {...register("name")}
              />

              {errors?.name && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.name?.message}
                </p>
              )}
            </div>
            <div className="w-[50%] py-4">
              <input
                type="email"
                placeholder="Enter email"
                className="w-full rounded-lg p-3 outline-none"
                // onChange={(e) => setUserEmail(e.target.value)}
                disabled={isLoading}

                {...register("email")}
              />

              {errors?.email && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.email?.message}
                </p>
              )}
            </div>
            <div className="w-[50%] py-4">
              <input
                type="password"
                placeholder="Enter password"
                className="w-full rounded-lg p-3 outline-none"
                // onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
                {...register("password")}
              />
              {errors?.password && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.password?.message}
                </p>
              )}
            </div>
          </div>

          <Button
            isLoading={isLoading}
            loadingText="Submitting"
            w="50%"
            rounded="lg"
            bg="#0C96CC"
            color="white"
            p={3}
            type="submit"
            _hover={{ backgroundColor: "#0A82B6" }}
          >
            Create
          </Button>
          <p className="text-center text-sm text-gray-200">
            OR <br />
            <br />
            If you already have an account, click to{" "}
            <Link
              to="/admin/signin"
              className="cursor-pointer font-semibold uppercase underline"
            >
              Signin
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default CreateAdmin;
