import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ChakraTabs from "../../components/ChakraTabs";
import HealthCare from "./components/HealthCare";

const HealthServices = () => {

  return (
    <div>
      <HealthCare />
    </div>
  );
};

export default HealthServices;
