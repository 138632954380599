import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { GET_ALL_CATEGORY } from "./CategoryTypes";
import axios from "axios";
import { create, Delete, fetchAll, update } from "../Apis/Apis";

export const useAllCategory = (apiUrl) => {
  return useQuery({
    queryKey: GET_ALL_CATEGORY,
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_CATEGORY);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Category:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_CATEGORY);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_CATEGORY);
    },
  });
};

export const useDeleteCategoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_CATEGORY);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Category:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_CATEGORY);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_CATEGORY);
    },
  });
};

export const useUpdateCategoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_CATEGORY);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Category:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_CATEGORY);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_CATEGORY);
    },
  });
};
