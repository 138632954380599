import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { create, Delete, fetchAll, update } from "../Apis/Apis.js";
import { GET_ALL_BREEDER } from "./breederTypes";

export const useAllBreeder = (apiUrl) => {
  return useQuery({
    queryKey: [GET_ALL_BREEDER],
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateBreeder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_BREEDER);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Breeders:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_BREEDER);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_BREEDER);
    },
  });
};

export const useDeleteBreederMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_BREEDER);
    },
    onError: (err, newProduct, context) => {
      console.log("Error Delete Breeder:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_BREEDER);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_BREEDER);
    },
  });
};

export const useUpdateBreederMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_BREEDER);
    },
    onError: (err, newProduct, context) => {
      console.log("Error Updating Breeder:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_BREEDER);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_BREEDER);
    },
  });
};

