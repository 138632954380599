import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from "@chakra-ui/react";
import { GlobalModalV1 } from "../../components/GlobalModal";
import ParentTable from "./components/Parent/ParentTable";
import ParentForm from "./components/Parent/ParentForm";
import AvailableParent from "./components/AvailableParent";
import DeletedParent from "./components/DeletedParent";
import ChakraTabs from "../../components/ChakraTabs";


const ParentInfo = () => {
  const [isShowForm, setIsShowForm] = useState(false);
  const tabData = [
    {
      label: "Parent",
      content: <Parent />,
    },
    {
      label: "Available Parent",
      content: <AvailableParent />,
    },
    {
      label: "Deleted Parent",
      content: <DeletedParent />,
    },
  ];

  return (
    <div>
      <ChakraTabs tabData={tabData} />
    </div>
  );
};

export default ParentInfo;

const Parent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <button
        onClick={onOpen}
        className="my-4 self-start rounded-lg border-2 border-black bg-transparent px-4 py-2"
      >
        Add Parent
      </button>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <ParentForm onClose={onClose} isOpen={isOpen} />
      </GlobalModalV1>

      <ParentTable />
    </>
  );
};

