import React from "react";
import HealthCareTable from "./HealthCareTable";

const HealthCare = () => {
  const dataArray = [
    {
      main: "Follow-Up",
      children: [
        { label: "Emergency", value: "-" },
        { label: "Today", value: "-" },
        { label: "Tomorrow", value: "-" },
        { label: "Day After", value: "-" }
      ]
    },
    {
      main: "Status",
      children: [
        { label: "Working", value: "295" },
        { label: "Waiting", value: "1" },
        { label: "Stuck", value: "-" },
        { label: "Alert", value: "11" },
        { label: "Review", value: "-" },
        { label: "Complete", value: "894" }
      ]
    },
    {
      main: "Alerts & Assists",
      children: [
        { label: "Health MGR", value: "-" },
        { label: "Dr. Rybka", value: "-" },
        { label: "Dan", value: "-" },
        { label: "Troy", value: "-" }
      ]
    }
  ];

  const dataArray2 = [
    {
      main: "Health Services",
      children: [
        { label: "Extended Hold", value: "-" },
        { label: "Check Call 1", value: "-" },
        { label: "Check Call 2", value: "-" },
        { label: "Check Call 3", value: "-" },
        { label: "Giardia", value: "-" },
        { label: "Coccidia", value: "-" },
        { label: "Ortho", value: "-" },
        { label: "Liver", value: "-" },
        { label: "Neuro Seizures", value: "-" },
        { label: "Hernia", value: "-" },
        { label: "Cough", value: "-" },
        { label: "Feeding", value: "-" },
        { label: "Vomit Diarrhea", value: "-" },
        { label: "Teeth", value: "-" },
        { label: "Eyes", value: "-" },
        { label: "Behavioral", value: "-" },
        { label: "DNA", value: "-" },
        { label: "Insurance", value: "-" },
        { label: "Autoimmune", value: "-" },
        { label: "Replace", value: "-" },
        { label: "Misc", value: "-" },
        { label: "Blood Test", value: "892" },
        { label: "Manager Alert", value: "-" },
        { label: "Vet Review", value: "-" }
      ]
    }
  ];

  const [selectedOption, setSelectedOption] = React.useState("Disclosure");

  return (
    <div>
      <div className="col-span-2 flex gap-4 flex-wrap mb-12">
        {dataArray.map((item, index) => (
          <div className="flex flex-col p-12 bg-white rounded-[20px] " key={index} >
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">{item.main}</p>
            </div>
            <div className="flex flex-wrap justify-between gap-6 pt-8">
              {item.children.map((child, childIndex) => (
                <div key={childIndex} className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">{child.value}</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    {child.label}
                  </p>
                </div>
              ))}
            </div>

          </div>

        ))}

      </div>


      {dataArray2.map((item, index) => (
        <div className="flex flex-col p-12 bg-white rounded-[20px] w-full" key={index}>
          <div className="flex items-center gap-2">
            <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
            <p className="font-bold">{item.main}</p>
          </div>
          <div className="flex flex-wrap gap-6 pt-8">
            {item.children.map((child, childIndex) => (
              <div key={childIndex} className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">{child.value}</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  {child.label}
                </p>
              </div>
            ))}
          </div>
          <div className="flex w-full mt-6 gap-6">
            <div
              className={`flex flex-col items-center w-1/2 justify-center py-2 rounded-10 cursor-pointer ${selectedOption === 'Disclosure' ? 'bg-gray-300' : 'bg-gray-100'
                }`}
              onClick={() => setSelectedOption('Disclosure')}
            >
              <p className="text-sm font-semibold text-[#001219]/50">-</p>
              <p className="font-bold">Disclosure</p>
            </div>
            <div
              className={`flex flex-col items-center w-1/2 justify-center py-2 rounded-10 cursor-pointer ${selectedOption === 'Facial Test' ? 'bg-gray-300' : 'bg-gray-100'
                }`}
              onClick={() => setSelectedOption('Facial Test')}
            >
              <p className="text-sm font-semibold text-[#001219]/50">-</p>
              <p className="font-bold">Facial Test</p>
            </div>
          </div>
        </div>
      ))}

      <HealthCareTable />
    </div>
  );
};

export default HealthCare;
