import React from 'react'
import TableOffer from './TableOffer';


const RejectedOffers = ({filterData, isLoading, error}) => {
  return (
    <div>
      {/* <Table3 /> */}
      <TableOffer offers={filterData} isLoading={isLoading} error={error} />
    </div>
  );
};

export default RejectedOffers