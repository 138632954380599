import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useCreateDiscount, useUpdateDISCOUNTMutation } from "../../../features/DiscountsCoupon/discountServise";
import { useToast } from "@chakra-ui/react";
import moment from 'moment'; // Import moment for date handling
import { useAllBreeds } from "../../../features/Breeds/Breeds";

const DiscountsForm = ({ onClose, selectedData, isUpdateModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [restrictedBreeds, setRestrictedBreeds] = useState([])
  const toast = useToast();
  const createMutation = useCreateDiscount();
  const UpdateMutation = useUpdateDISCOUNTMutation();

  //get all breeds
  const {data: breeds, isLoadingBreed, error} = useAllBreeds('/api/breeds');
  console.log("breeds",breeds);

  const items = [
    {
      value: "fixedValue",
      label: "Fixed Value",
    },
    {
      label: "Percentage",
      value: "percentage",
    },
  ];

  const options = [
    {
      label: "Shipping Only",
      value: "shippingOnly",
      name: "shippingOnly",
    },
    {
      label: "With Hand Delivery Also",
      value: "withHandDelivery",
      name: "withHandDeliveryAlso",
    },
    {
      label: "In-House Only",
      value: "inHouse",
      name: "inHouseOnly",
    },
    {
      label: "Pre-Sale Only",
      value: "preSale",
      name: "preSaleOnly",
    },
    {
      label: "One-time only",
      value: "oneTime",
      name: "oneTimeOnly",
    },
    {
      label: "Auto-apply",
      value: "autoApply",
      name: "autoApply",
    },
    {
      label: "Allowed on deposits",
      value: "allowedOnDeposit",
      name: "allowedOnDeposits",
    },
    {
      label: "Available Now Only",
      value: "availableNow",
      name: "availableNowOnly",
    },
  ];

  // const restrictedBreeds = [
  //   "Aussiechon",
  //   "Bichon Frise",
  //   "Boston Terrier",
  //   "Cavachon",
  //   "Cavalier King Charles Spaniel",
  //   "Cavapoo",
  //   "Cockapoo",
  //   "Golden Retriever"
  // ];

  useEffect(() => {
    if (breeds) {
      const breedNames = breeds.map(breed => breed.breedInformation.breedName);
      setRestrictedBreeds(breedNames);
    }
  }, [breeds]);

  console.log(restrictedBreeds)
  

  useEffect(() => {
    if (selectedData && selectedData.length > 0 && isUpdateModal) {
      const data = selectedData[0] || {};
      form.setFieldsValue({
        couponCode: data?.couponCode ?? null,
        value: data.value ?? null,
        offsetWeeks: data?.offsetWeeks ?? null,
        restrictedBreeds: data?.restrictedBreeds ?? null,
        expireDate: data?.expireDate ? moment(data.expireDate) : null, // Set moment date
        couponType: data?.couponType ?? null,
        shippingOnly: data?.shippingOnly ?? null,
        handDelivery: data?.withHandDeliveryAlso ?? null,
        inHouseOnly: data?.inHouseOnly ?? null,
        preSaleOnly: data?.preSaleOnly ?? null,
        oneTimeOnly: data?.oneTimeOnly ?? null,
        autoApply: data?.autoApply ?? null,
        allowedOnDep: data?.allowedOnDeposits ?? null,
        availableNowOnly: data?.availableNowOnly ?? null,
        created: data?.createdAt ? moment(data.createdAt) : null, // Set moment date
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const expireDate = values.expireDate ? moment(values.expireDate) : null;

    if (expireDate && !expireDate.isValid()) {
      toast({
        title: "Invalid Date",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    const data = {
      couponCode: values?.couponCode ?? null,
      value: values.value ?? null,
      offsetWeeks: values?.offsetWeeks ?? null,
      restrictedBreeds: values?.restrictedBreeds ?? null,
      expireDate: expireDate ? expireDate.toISOString() : null, // Convert to ISO string
      couponType: values?.couponType ?? null,
      shippingOnly: values?.shippingOnly ?? null,
      handDelivery: values?.withHandDeliveryAlso ?? null,
      inHouseOnly: values?.inHouseOnly ?? null,
      preSaleOnly: values?.preSaleOnly ?? null,
      oneTimeOnly: values?.oneTimeOnly ?? null,
      autoApply: values?.autoApply ?? null,
      allowedOnDep: values?.allowedOnDeposits ?? null,
      availableNowOnly: values?.availableNowOnly ?? null,
      created: values?.createdAt ? moment(values.createdAt).toISOString() : null, // Convert to ISO string
    };

    if (isUpdateModal && selectedData) {
      UpdateMutation.mutate(
        {
          apiUrl: `/api/discount/${selectedData[0]?._id}`,
          data,
        },
        {
          onSuccess: () => {
            form.resetFields();
            toast({
              title: "Discount coupon Updated successfully!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
          },
          onError: (error) => {
            toast({
              title: error.response?.data?.details?.includes("duplicate")
                ? "Discount coupon Already Exist"
                : error.response?.data?.details ||
                  error.message ||
                  "Error Updating Discount Coupon",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    } else {
      createMutation.mutate(
        {
          apiUrl: "/api/discount",
          data,
        },
        {
          onSuccess: () => {
            form.resetFields();
            toast({
              title: "Discount coupon created successfully!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
          },
          onError: (error) => {
            toast({
              title: error.response?.data?.details?.includes("duplicate")
                ? "Discount coupon Already Exist"
                : error.response?.data?.details ||
                  error.message ||
                  "Error creating Discount Coupon",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    }

    setIsLoading(false);
  };

  return (
    <div className="auto mx-0 overflow-y-auto">
      <h2 className="pb-10 text-2xl font-bold">Coupons</h2>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <div>
          <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
            Content
          </div>
          <Form.Item name="couponCode">
            <TextArea rows={3} placeholder="Code" />
          </Form.Item>

          <div className="grid grid-cols-3 gap-4">
            <Form.Item label="Value" name="value">
              <InputNumber style={{ width: "100%" }} placeholder="0" />
            </Form.Item>
            <Form.Item label="Expires" name="expireDate">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="Coupon Type" name="couponType">
              <Select
                style={{ width: "100%" }}
                placeholder="Select Coupon Type"
                allowClear
                options={items}
              />
            </Form.Item>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            {options.map((option, index) => (
              <Form.Item key={index} name={option.name} valuePropName="checked">
                <Checkbox value={option.value}>{option.label}</Checkbox>
              </Form.Item>
            ))}
          </div>

          <Form.Item
            label="Offset Weeks (separated by comma)"
            name="offsetWeeks"
          >
            <Input />
          </Form.Item>
        </div>
        <div>
          <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
            Restricted to breeds
          </div>
          <Form.Item name="restrictedBreeds" label="Restricted Breeds">
              <Select mode="multiple" placeholder="Select Restricted Breeds">
                {restrictedBreeds.map((breed, index) => (
                  <Select.Option key={index} value={breed}>
                    {breed}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
        </div>

        <Form.Item>
          <Button
            className="!bg-[#001219] !text-white"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DiscountsForm;
