import React, { useState, useEffect } from "react";
import OrdersTabs from "../OrderTabs.jsx";

const OrdersTable = () => {
  return (
    <div className="my-10">
      <OrdersTabs />
    </div>
  );
};

export default OrdersTable;
