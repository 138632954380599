import React, { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../components/GlobalModal";

import OrdersForm from "./components/OrdersForm";
import OrdersTable from "./components/OrdersTable";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import OrdersDashboard from "./components/OrderDetail/OrdersDashboard";

const Orders = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const params = useParams();
  const location = useLocation();
  const { orderData } = location.state || {}; // Access the passed data
  console.log(orderData, "orderData");
  console.log(location, "location");
  console.log(params, "params");

  if (params?.subpage === "orders" && !params?.subsubpage) {
    return (
      <div className="my-10">
        <OrdersTable />
      </div>
    );
  } else if (
    params?.subpage === "orders" &&
    params?.subsubpage === orderData?._id
  ) {
    return (
      <div className="my-10">
        <OrdersDashboard data={orderData} />
      </div>
    );
  }
};

export default Orders;
