import axios from "axios";

export const uploadFiles = async (files) => {
  if (!files) return null;
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/upload`,
      formData,
    );

    if (response?.data?.error) {
      console.log("File Upload Failed" + response?.data?.error);
    }

    const result = await response.data;
    console.log("Upload response===>", result)
    return result.files.map((file) => file.fileUrl);
  } catch (error) {
    return error;
  }
};

export const uploadVideo = async (file) => {
  if (!file) return null;
  const formData = new FormData();
  formData.append("video", file);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/upload/video`,
      formData,
    );

    if (response?.data?.error) {
      console.log("File Upload Failed:", response?.data?.error);
      return null;
    }

    const result = response.data.files[0].fileUrl;
    console.log("Upload response:", result);
    return result || null;
  } catch (error) {
    console.error("Error during video upload:", error);
    return null;
  }
};
