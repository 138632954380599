import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import {
  useCreateDeliveryOption,
  useUpdateDeliveryOptionMutation,
} from "../../../features/delivery-option/deliveryOptionsService";
import { useToast } from "@chakra-ui/react";
import { useAllBreeds } from "../../../features/Breeds/Breeds";

const AddLocationForm = ({ onClose, selectedData, isUpdateModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [restrictedBreeds, setRestrictedBreeds] = useState([])
  const [form] = Form.useForm(); // Initialize the form correctly
  const createMutation = useCreateDeliveryOption();
  const toast = useToast();
  console.log("edit data", selectedData);
  const UpdateMutation = useUpdateDeliveryOptionMutation();
  const {data: breeds, isLoadingBreed, error} = useAllBreeds('/api/breeds');

  // const restrictedBreeds = [
  //   "Aussiechon",
  //   "Bichon Frise",
  //   "Boston Terrier",
  //   "Cavachon",
  //   "Cavalier King Charles Spaniel",
  //   "Cavapoo",
  //   "Cockapoo",
  // ];
  useEffect(() => {
    if (breeds) {
      const breedNames = breeds.map(breed => breed.breedInformation.breedName);
      setRestrictedBreeds(breedNames);
    }
  }, [breeds]);
  const [deliveryMethod, setDeliveryMethod] = useState("Store Delivery");

  const handleCheckboxChange = (e) => {
    setDeliveryMethod(e.target.checked ? "NK Delivery" : "Store Delivery");
  };

  useEffect(() => {
    if (selectedData && selectedData.length > 0 && isUpdateModal) {
      const data = selectedData[0] || {};
      form.setFieldsValue({
        name: data.name ?? null,
        active: data.active ?? null,
        noSalesTax: data.noSalesTax ?? null,
        taxRate: data.taxRate ?? null,
        chargeTaxOnDeliveryService: data.chargeTaxOnDeliveryService ?? null,
        notes: data.notes ?? null,
        longitude: data.longitude ?? null,
        latitude: data.latitude ?? null,
        route: data.route ?? null,
        zone: data.zone ?? null,
        proStop: data.proStop ?? null,
        pickUp: data.pickUp ?? null,
        priority: data.priority ?? null,
        nanny: data.nanny ?? null,
        vip: data.vip ?? null,
        storks: data.storks ?? null,
        place: data.place ?? null,
        airportCode: data.airportCode ?? null,
        address: data.address ?? null,
        city: data.city ?? null,
        state: data.state ?? null,
        zipCode: data.zipCode ?? null,
        country: data.country ?? null,
        restrictedBreeds: data.restrictedBreeds ?? null,
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
      name: values.name ?? null,
      active: values.active ?? null,
      noSalesTax: values.noSalesTax ?? null,
      taxRate: values.taxRate ?? null,
      chargeTaxOnDeliveryService: values.chargeTaxOnDeliveryService ?? null,
      notes: values.notes ?? null,
      longitude: values.longitude ?? null,
      latitude: values.latitude ?? null,
      route: values.route ?? null,
      zone: values.zone ?? null,
      deliveryMethod: deliveryMethod ?? null,
      proStop: values.proStop ?? null,
      pickUp: values.pickUp ?? null,
      priority: values.priority ?? null,
      nanny: values.nanny ?? null,
      vip: values.vip ?? null,
      storks: values.storks ?? null,
      place: values.place ?? null,
      airportCode: values.airportCode ?? null,
      address: values.address ?? null,
      city: values.city ?? null,
      state: values.state ?? null,
      stateAbbreviation: values.stateAbb ?? null,
      zipCode: values.zipCode ?? null,
      country: values.country ?? null,
      restrictedBreeds: values.restrictedBreeds ?? null,
    };

    if (isUpdateModal && selectedData) {
      UpdateMutation.mutate(
        {
          apiUrl: `/api/deliveriesOption/${selectedData[0]?._id}`,
          data,
        },
        {
          onSuccess: () => {
            form.resetFields();
            toast({
              title: "Delivery Option Updated successfully!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
          },
          onError: (error) => {
            toast({
              title: error.response?.data?.details?.includes("duplicate")
                ? "Delivery Option Already Exist"
                : error.response?.data?.details ||
                  error.message ||
                  "Error Updating Deleting Option",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    } else {
      createMutation.mutate(
        {
          apiUrl: "/api/deliveriesOption",
          data,
        },
        {
          onSuccess: () => {
            form.resetFields();
            toast({
              title: "Delivery Option created successfully!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
          },
          onError: (error) => {
            toast({
              title: error.response?.data?.details?.includes("duplicate")
                ? "Delivery Option Already Exist"
                : error.response?.data?.details ||
                  error.message ||
                  "Error creating Delivery Option",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    }

    setIsLoading(false);
  };

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <h2 className="pb-10 text-2xl font-bold">Delivery Locations</h2>
      <Form
        form={form} // Pass the form instance to the Form component
        layout="vertical"
        onFinish={onFinish}
        className="m-auto flex w-full flex-col items-center justify-center rounded border border-gray-400 p-6"
      >
        <div className="flex w-full justify-between gap-20">
          <div className="w-full">
            <div>
              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Info
              </div>
              <Form.Item label="Name" name="name">
                <TextArea
                  placeholder="Name"
                  disabled={isLoading}
                  style={{
                    height: 70,
                    resize: "none",
                  }}
                />
              </Form.Item>
              <Form.Item name="active" valuePropName="checked">
                <Checkbox disabled={isLoading}>Active</Checkbox>
              </Form.Item>
              <Form.Item name="noSalesTax" valuePropName="checked">
                <Checkbox disabled={isLoading}>
                  Turn Off "No Sales Tax"
                </Checkbox>
              </Form.Item>
              <Form.Item label="Tax Rate %" name="taxRate">
                <TextArea
                  placeholder="Tax Rate %"
                  disabled={isLoading}
                  style={{
                    height: 70,
                    resize: "none",
                  }}
                />
              </Form.Item>
              <Form.Item
                name="chargeTaxOnDeliveryService"
                valuePropName="checked"
              >
                <Checkbox disabled={isLoading}>
                  Charge Tax on Delivery Service
                </Checkbox>
              </Form.Item>
              <Form.Item label="Notes" name="notes">
                <TextArea
                  placeholder="Notes"
                  disabled={isLoading}
                  style={{
                    height: 70,
                    resize: "none",
                  }}
                />
              </Form.Item>
              <Form.Item label="Longitude" name="longitude">
                <TextArea
                  placeholder="Longitude"
                  disabled={isLoading}
                  style={{
                    height: 70,
                    resize: "none",
                  }}
                />
              </Form.Item>
              <Form.Item label="Latitude" name="latitude">
                <TextArea
                  placeholder="Latitude"
                  disabled={isLoading}
                  style={{
                    height: 70,
                    resize: "none",
                  }}
                />
              </Form.Item>
              <Form.Item label="Route" name="route">
                <TextArea
                  placeholder="Route"
                  disabled={isLoading}
                  style={{
                    height: 70,
                    resize: "none",
                  }}
                />
              </Form.Item>
              <Form.Item label="Zone" name="zone">
                <TextArea
                  placeholder="Zone"
                  disabled={isLoading}
                  style={{
                    height: 70,
                    resize: "none",
                  }}
                />
              </Form.Item>

              {/* <Form.Item name="deliveryMethod" valuePropName="checked">
                <Checkbox disabled={isLoading}>NK Delivery</Checkbox>
              </Form.Item> */}
              <Form.Item name="deliveryMethod">
                <Checkbox disabled={isLoading} onChange={handleCheckboxChange}>
                  NK Delivery
                </Checkbox>
              </Form.Item>
            </div>
            <div className="mt-5">
              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Pricing
              </div>
              <div className="flex w-full items-center justify-between gap-10">
                <Form.Item
                  label="Pro-Stop"
                  name="proStop"
                  style={{ width: "100%" }}
                >
                  <TextArea
                    placeholder="Pro-Stop"
                    disabled={isLoading}
                    style={{
                      height: 70,
                      width: "100%",
                      resize: "none",
                    }}
                  />
                </Form.Item>
                <Form.Item name="proStop-active" valuePropName="checked">
                  <Checkbox disabled={isLoading}>Active</Checkbox>
                </Form.Item>
              </div>
              <div className="flex w-full items-center justify-between gap-10">
                <Form.Item
                  label="Pick-Up"
                  name="pickUp"
                  style={{ width: "100%" }}
                >
                  <TextArea
                    placeholder="Pick-Up"
                    disabled={isLoading}
                    style={{
                      height: 70,
                      width: "100%",
                      resize: "none",
                    }}
                  />
                </Form.Item>
                <Form.Item name="pickUp-active" valuePropName="checked">
                  <Checkbox disabled={isLoading}>Active</Checkbox>
                </Form.Item>
              </div>
              <div className="flex w-full items-center justify-between gap-10">
                <Form.Item
                  label="Priority"
                  name="priority"
                  style={{ width: "100%" }}
                >
                  <TextArea
                    placeholder="Priority"
                    disabled={isLoading}
                    style={{
                      height: 70,
                      width: "100%",
                      resize: "none",
                    }}
                  />
                </Form.Item>
                <Form.Item name="priority-active" valuePropName="checked">
                  <Checkbox disabled={isLoading}>Active</Checkbox>
                </Form.Item>
              </div>
              <div className="flex w-full items-center justify-between gap-10">
                <Form.Item label="Nanny" name="nanny" style={{ width: "100%" }}>
                  <TextArea
                    placeholder="Nanny"
                    disabled={isLoading}
                    style={{
                      height: 70,
                      width: "100%",
                      resize: "none",
                    }}
                  />
                </Form.Item>
                <Form.Item name="nanny-active" valuePropName="checked">
                  <Checkbox disabled={isLoading}>Active</Checkbox>
                </Form.Item>
              </div>
              <div className="flex w-full items-center justify-between gap-10">
                <Form.Item label="Vip" name="vip" style={{ width: "100%" }}>
                  <TextArea
                    placeholder="Vip"
                    disabled={isLoading}
                    style={{
                      height: 70,
                      width: "100%",
                      resize: "none",
                    }}
                  />
                </Form.Item>
                <Form.Item name="vip-active" valuePropName="checked">
                  <Checkbox disabled={isLoading}>Active</Checkbox>
                </Form.Item>
              </div>
              <div className="flex w-full items-center justify-between gap-10">
                <Form.Item
                  label="Storks"
                  name="storks"
                  style={{ width: "100%" }}
                >
                  <TextArea
                    placeholder="Storks"
                    disabled={isLoading}
                    style={{
                      height: 70,
                      width: "100%",
                      resize: "none",
                    }}
                  />
                </Form.Item>
                <Form.Item name="storks-active" valuePropName="checked">
                  <Checkbox disabled={isLoading}>Active</Checkbox>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Meta
            </div>
            <Form.Item label="Place" name="place">
              <TextArea
                placeholder="Place"
                disabled={isLoading}
                style={{
                  height: 70,
                  resize: "none",
                }}
              />
            </Form.Item>
            <Form.Item label="Airport Code" name="airportCode">
              <TextArea
                placeholder="Airport Code"
                disabled={isLoading}
                style={{
                  height: 70,
                  resize: "none",
                }}
              />
            </Form.Item>
            <Form.Item label="Address" name="address">
              <TextArea
                placeholder="Address"
                disabled={isLoading}
                style={{
                  height: 70,
                  resize: "none",
                }}
              />
            </Form.Item>
            <Form.Item label="City" name="city">
              <TextArea
                placeholder="City"
                disabled={isLoading}
                style={{
                  height: 70,
                  resize: "none",
                }}
              />
            </Form.Item>
            <Form.Item label="State" name="state">
              <TextArea
                placeholder="State"
                disabled={isLoading}
                style={{
                  height: 70,
                  resize: "none",
                }}
              />
            </Form.Item>
            <Form.Item label="State Abbreviation" name="stateAbb">
              <TextArea
                placeholder="e.g (NY)"
                disabled={isLoading}
                style={{
                  height: 70,
                  resize: "none",
                }}
              />
            </Form.Item>
            <Form.Item label="Zipcode" name="zipCode">
              <TextArea
                placeholder="Zipcode"
                disabled={isLoading}
                style={{
                  height: 70,
                  resize: "none",
                }}
              />
            </Form.Item>
            <Form.Item label="Country" name="country">
              <TextArea
                placeholder="Country"
                disabled={isLoading}
                style={{
                  height: 70,
                  resize: "none",
                }}
              />
            </Form.Item>
            <Form.Item name="restrictedBreeds" label="Restricted Breeds">
              <Select mode="multiple" placeholder="Select Restricted Breeds">
                {restrictedBreeds.map((breed, index) => (
                  <Select.Option key={index} value={breed}>
                    {breed}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button
            className="!bg-[#001219] !text-white"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            Save
            {/* {isUpdateModal ? "Update" : "Create"} */}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddLocationForm;
