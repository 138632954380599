import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import {
    useAllBreeder,
  useCreateBreeder,
  useUpdateBreederMutation,
} from "../../../features/Breeder/breederService";
import dayjs from "dayjs";
import { useAllBreeds } from "../../../features/Breeds/Breeds";
import { useCreateAkc, useUpdateAkcMutation } from "../../../features/Akc/AkcService";
import { useAllParents } from "../../../features/parent/parentService";

const AkcForm = ({ onClose, selectedData, isUpdateModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const {data: parents} = useAllParents('/api/parents');
  const { data: akcs} = useAllBreeds("/api/Akcs");
  const {data: breeders} = useAllBreeder('/api/Breeders')

  const createMutation = useCreateAkc();
  const UpdateMutation = useUpdateAkcMutation();

  const [form] = Form.useForm();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      console.log("Selected Data:", selectedData[0]);
      const registrationDate = selectedData[0]?.registrationDate 
        ? dayjs(selectedData[0]?.registrationDate )
        : null;

      const expirationDate = selectedData[0]?.expirationDate
        ? dayjs(selectedData[0]?.expirationDate)
        : null;

      form.setFieldsValue({

        firstName: selectedData[0]?.firstName ?? null,
        registrationNumber: selectedData[0]?.registrationNumber?? null,
        breed: selectedData[0]?.breed ?? null,
        breeder: selectedData[0]?.breeder?? null,
        sire: selectedData[0]?.sire ?? null,
        registrationDate: registrationDate,
        expirationDate: expirationDate,
        registeredOwner: selectedData[0]?.registeredOwner ?? null,
        color: selectedData[0]?.color ?? null,
        markings: selectedData[0]?.markings?? null
        
      });
    }
  }, [selectedData, isUpdateModal, form]);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const data = {
        registrationNumber: values.registrationNumber?? null,
        breed: values.breed ?? null,
        breeder: values.breeder?? null,
        sire: values.sire ?? null,
        registrationDate: values.registrationDate ?? null,
        expirationDate: values.expirationDate ?? null,
        registeredOwner: values.registeredOwner ?? null,
        color: values.color ?? null,
        markings: values.markings?? null
      };

      if (isUpdateModal && selectedData) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/Akc/${selectedData[0]?._id}`,
          data,
        });
        toast({
          title: "Akc Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/Akc",
          data,
        });
        toast({
          title: "Akc created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      form.resetFields();
      onClose();
    } catch (error) {
      console.error("Error details:", error);
      const errorMessage =
        error.response?.data?.message ||
        (error.response?.data?.details?.includes("duplicate")
          ? "Akc Already Exists"
          : error.message || "Error creating Akc");

      toast({
        title: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const breederOptions = breeders?.map((breeder) => ({
    value: breeder?._id,
    label: breeder?.firstName,
  }));
const parentOptions = parents?.map((parent) => ({
    value: parent?._id,
    label: parent?.parentInformation?.parentName,
  }));

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">Breeder</h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <Form.Item
              name="registrationNumber"
              label="Registration Number"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              
            >
              <Input placeholder="Registration Number" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="breed"
              label="Breed"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Breed" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name={"breeder"}
              label="Breeder"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                placeholder="Select a breeder"
                style={{ width: "100%" }}
                allowClear
                options={breederOptions}
                disabled={isLoading}
              />
            </Form.Item>
            <Form.Item
              name={"sire"}
              label="Parent"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                placeholder="Select a Parent"
                style={{ width: "100%" }}
                allowClear
                options={parentOptions}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name="registrationDate"
              label="Registration Date"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <DatePicker disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="expirationDate"
              label="Expiration Date"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <DatePicker disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="registeredOwner"
              label="Registered Owner"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Registered Owner" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="color"
              label="Color"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Color" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="markings"
              label="Markings"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Markings" disabled={isLoading} />
            </Form.Item>
           
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button
              className="!bg-[#001219] !text-white"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AkcForm;
