import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {  GET_ALL_OFFERS } from "./OfferType"
import { create, Delete, fetchAll, update } from "../Apis/Apis"


export const useAllOffer = (apiUrl) =>{
    return useQuery({
        queryKey: [GET_ALL_OFFERS],
        queryFn: ()=>fetchAll(apiUrl)
    })
}

export const useCreateOffer = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: create,
        onMutate: async (new_offer)=> {
            queryClient.invalidateQueries(GET_ALL_OFFERS)
        },
        onError: (err, new_offer, context) => {
            console.log('Error creating offer', err)
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_OFFERS)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_OFFERS)
        }
    })

}

export const useUpdateOfferMutation = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: update,
        onMutate: async(new_offer)=> {
            queryClient.invalidateQueries(GET_ALL_OFFERS)
        },
        onError: (err, new_offer, context) => {
            console.log('Error updating offer', err)
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_OFFERS)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_OFFERS)
        }

    })
}


export const useDeleteOfferMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: Delete,
        onMutate: async(new_offer)=> {
            queryClient.invalidateQueries(GET_ALL_OFFERS)
        },
        onError: (err, new_offer, context)=> {
            console.log('Error deleting offer', err)
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_OFFERS)
        }, 
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_OFFERS)
        }
    })
}