import React from "react";
import Table3 from "../../components/Table3";
// import { Table } from "antd";
// const columns = [
//   {
//     title: "Full Name",
//     width: 100,
//     dataIndex: "name",
//     key: "name",
//     fixed: "left",
//   },
//   {
//     title: "Age",
//     width: 100,
//     dataIndex: "age",
//     key: "age",
//     fixed: "left",
//   },
//   {
//     title: "Column 1",
//     dataIndex: "address",
//     key: "1",
//     width: 150,
//   },
//   {
//     title: "Column 2",
//     dataIndex: "address",
//     key: "2",
//     width: 150,
//   },
//   {
//     title: "Column 3",
//     dataIndex: "address",
//     key: "3",
//     width: 150,
//   },
//   {
//     title: "Column 4",
//     dataIndex: "address",
//     key: "4",
//     width: 150,
//   },
//   {
//     title: "Column 5",
//     dataIndex: "address",
//     key: "5",
//     width: 150,
//   },
//   {
//     title: "Column 6",
//     dataIndex: "address",
//     key: "6",
//     width: 150,
//   },
//   {
//     title: "Column 7",
//     dataIndex: "address",
//     key: "7",
//     width: 150,
//   },
//   {
//     title: "Column 8",
//     dataIndex: "address",
//     key: "8",
//   },
//   {
//     title: "Action",
//     key: "operation",
//     fixed: "right",
//     width: 100,
//     render: () => <a>action</a>,
//   },
// ];

// const data = [];
// for (let i = 0; i < 100; i++) {
//   data.push({
//     key: i,
//     name: `Edward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }

const Watchlist = () => {
  return (
    <div className="">
      <div className="lg:grid-row-4 grid grid-cols-3 grid-rows-2 gap-4 lg:grid-cols-7">
        <div className="order-1 col-span-2 rounded-[20px] bg-white">
          <div className="flex flex-col p-12">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Pickups</p>
            </div>
            <div className="flex justify-between gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">12</p>
                <p className="text-sm font-semibold text-[#001219]/50">Today</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">8</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Tomorrow
                </p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">4</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Day After
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="order-2 col-span-1 flex justify-center rounded-[20px] bg-white">
          <div className="flex flex-col p-12">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Appointment</p>
            </div>
            <div className="flex justify-center gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">12</p>
                <p className="text-sm font-semibold text-[#001219]/50">Today</p>
              </div>
            </div>
          </div>
        </div>
        <div className="order-3 col-span-1 flex justify-center rounded-[20px] bg-white">
          <div className="flex flex-col p-12">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Facetimes</p>
            </div>
            <div className="flex justify-between gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">63</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Schedule
                </p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">-</p>
                <p className="text-sm font-semibold text-[#001219]/50">Calls</p>
              </div>
            </div>
          </div>
        </div>
        <div className="order-8 col-span-3 row-span-2 rounded-[20px] bg-[#D9F4FF] lg:order-4">
          <div className="flex flex-col px-12 py-8">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Pickups</p>
            </div>
            <div>
              <p className="pt-4 text-sm font-semibold text-[#001219]/50">
                Today
              </p>
              <div className="flex justify-between gap-6 border-b border-[#001219]/50 pb-8 pt-4">
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">5</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Day 1
                  </p>
                </div>
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">-</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Day 2
                  </p>
                </div>
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">-</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Day 7
                  </p>
                </div>
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">7</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Total
                  </p>
                </div>
              </div>
            </div>

            <div>
              <p className="pt-4 text-sm font-semibold text-[#001219]/50">
                Tomorrow
              </p>
              <div className="flex justify-between gap-6 border-b border-[#001219]/50 pb-8 pt-4">
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">1</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Day 1
                  </p>
                </div>
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">5</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Day 2
                  </p>
                </div>
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">-</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Day 7
                  </p>
                </div>
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">6</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Total
                  </p>
                </div>
              </div>
            </div>

            <div>
              <p className="pt-4 text-sm font-semibold text-[#001219]/50">
                Day After
              </p>
              <div className="flex justify-between gap-6 pt-4">
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">9</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Day 1
                  </p>
                </div>
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">1</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Day 2
                  </p>
                </div>
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">-</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Day 7
                  </p>
                </div>
                <div className="flex flex-col gap-2 text-center">
                  <p className="text-2xl font-bold">10</p>
                  <p className="text-sm font-semibold text-[#001219]/50">
                    Total
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="order-5 col-span-2 rounded-[20px] bg-white">
          <div className="flex flex-col p-12">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Offers</p>
            </div>
            <div className="flex justify-between gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">12</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Amount
                </p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">8</p>
                <p className="text-sm font-semibold text-[#001219]/50">Call</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">4</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Follow Up
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="order-6 col-span-1 flex justify-center rounded-[20px] bg-white">
          <div className="flex flex-col p-12">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">1 Year</p>
            </div>
            <div className="flex justify-center gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">1458</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Reviews
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="order-7 col-span-1 flex justify-center rounded-[20px] bg-white">
          <div className="flex flex-col p-12">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Videos/Photos</p>
            </div>
            <div className="flex justify-between gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">86</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  In house
                </p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">5</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Presale
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="grid grid-cols-1 gap-4 pt-4 lg:grid-cols-4 xl:grid-cols-10">
        <div className="flex place-content-center justify-center rounded-[20px] border border-[#001219]/50">
          <div className="flex flex-col items-center justify-center px-10 py-4">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Blue</p>
            </div>
            <div className="flex justify-center gap-6 pt-6">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">64</p>
                <p className="text-sm font-semibold text-[#001219]/50">Calls</p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="flex place-content-center justify-center rounded-[20px] border border-[#001219]/50">
          <div className="flex flex-col items-center justify-center px-10 py-4">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Book</p>
            </div>
            <div className="flex justify-center gap-6 pt-6">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">2</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Pickup App
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="flex place-content-center justify-center rounded-[20px] border border-[#001219]/50">
          <div className="flex flex-col items-center justify-center px-10 py-4">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Due</p>
            </div>
            <div className="flex justify-center gap-6 pt-6">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">38</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Balances
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="flex place-content-center justify-center rounded-[20px] border border-[#001219]/50">
          <div className="flex flex-col items-center justify-center px-10 py-4">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">MGR</p>
            </div>
            <div className="flex justify-center gap-6 pt-6">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">41</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Alerts
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="col-span-2 flex place-content-center justify-center rounded-[20px] border border-[#001219]/50">
          <div className="flex w-full flex-col items-center justify-center px-10 py-4">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Requests</p>
            </div>
            <div className="flex justify-between gap-6 pt-6">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">86</p>
                <p className="text-sm font-semibold text-[#001219]/50">Send</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">3307</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Follow Up
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="flex place-content-center justify-center rounded-[20px] border border-[#001219]/50">
          <div className="flex flex-col items-center justify-center px-10 py-4">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">DISC</p>
            </div>
            <div className="flex justify-center gap-6 pt-6">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">186</p>
                <p className="text-sm font-semibold text-[#001219]/50">Calls</p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="flex place-content-center justify-center rounded-[20px] border border-[#001219]/50">
          <div className="flex flex-col items-center justify-center px-10 py-4">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Tests</p>
            </div>
            <div className="flex justify-center gap-6 pt-6">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">12</p>
                <p className="text-sm font-semibold text-[#001219]/50">Tests</p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="flex place-content-center justify-center rounded-[20px] border border-[#001219]/50">
          <div className="flex flex-col items-center justify-center px-10 py-4">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">MGR</p>
            </div>
            <div className="flex justify-center gap-6 pt-6">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">12</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Alerts
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="flex place-content-center justify-center rounded-[20px] border border-[#001219]/50">
          <div className="flex flex-col items-center justify-center px-10 py-4">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">RED</p>
            </div>
            <div className="flex justify-center gap-6 pt-6">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">12</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Code Red
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-16">
        {/* <Table
          className="bg-transparent"
          columns={columns}
          dataSource={data}
          scroll={{
            x: 1500,
            y: 300,
          }}
        /> */}
        {/* <Table2 /> */}
        <Table3 />
      </div>
      {/* <div className="grid grid-cols-7 grid-rows-2 gap-4">
        <div className="col-span-2"></div>
        <div className="col-span-1"></div>
        <div className="col-span-1"></div>
        <div className="col-span-3"></div>
        <div className="col-span-2"></div>
        <div className="col-span-1"></div>
        <div className="col-span-1"></div>
      </div> */}
    </div>
  );
};

export default Watchlist;
