import axios from 'axios';
import { refreshToken } from '../features/auth/authService';
import { REFRESH_TOKEN_SUCCESS, LOGOUT } from '../features/auth/actionTypes';
import { store } from '../store/store';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:5000', // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setupAxiosInterceptors = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = store.getState().auth.user?.accessToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const state = store.getState();
          const currentRefreshToken = state.auth.user?.refreshToken;
          const response = await refreshToken(currentRefreshToken);
          const newAccessToken = response.data.accessToken;

          store.dispatch({
            type: REFRESH_TOKEN_SUCCESS,
            payload: { accessToken: newAccessToken },
          });

          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        } catch (e) {
          store.dispatch({ type: LOGOUT });
          return Promise.reject(e);
        }
      }
      return Promise.reject(error);
    },
  );
};

export default axiosInstance;
