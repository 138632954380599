import { useQuery, useMutation, useQueryClient, QueryClient } from "@tanstack/react-query";
import { GET_ALL_DELIVERYOPTION } from "./delivery-optionType";
import { create, Delete, fetchAll, update } from "../Apis/Apis.js";

export const useAllDeliveryOptions = (apiUrl) => {
    return useQuery({
      queryKey: GET_ALL_DELIVERYOPTION,
      queryFn: () => fetchAll(apiUrl),
    });
  };

  export const useCreateDeliveryOption = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: create,
      onMutate: async (newProduct) => {
        queryClient.invalidateQueries(GET_ALL_DELIVERYOPTION);
      },
      onError: (err, newProduct, context) => {
        console.log("Error creating Delivery Options:", err);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(GET_ALL_DELIVERYOPTION);
      },
      onSettled: () => {
        queryClient.invalidateQueries(GET_ALL_DELIVERYOPTION);
      },
    });
  };


  export const useDeleteDeliveryOptionMutation = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: Delete,
      onMutate: async (newProduct) => {
        queryClient.invalidateQueries(GET_ALL_DELIVERYOPTION);
      },
      onError: (err, newProduct, context) => {
        console.log("Error deleting delivery option:", err);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(GET_ALL_DELIVERYOPTION);
      },
      onSettled: () => {
        queryClient.invalidateQueries(GET_ALL_DELIVERYOPTION);
      },
    });
  };
  
  export const useUpdateDeliveryOptionMutation = () => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: update,
      onMutate: async (newProduct) => {
        queryClient.invalidateQueries(GET_ALL_DELIVERYOPTION);
      },
      onError: (err, newProduct, context) => {
        console.log("Error updating delivery option:", err);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(GET_ALL_DELIVERYOPTION);
      },
      onSettled: () => {
        queryClient.invalidateQueries(GET_ALL_DELIVERYOPTION);
      },
    });
  };
  