import React from "react";

import TableOffer from "./TableOffer";

const PendingOffers = ({filterData, isLoading, error}) => {
  return (
    <div>
      {/* <Table3 /> */}
      <TableOffer offers={filterData} isLoading={isLoading} error={error} />
    </div>
  );
};

export default PendingOffers;
