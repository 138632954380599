const initialState = {
  currentStep: 0,
  formData: {},
  isStep3Done: false,
  isStep2Done: false,
  localProducts: [],
  addedProducts: [],
  zipCode: "",
};

const buyNowReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_STEP":
      return { ...state, currentStep: action.payload };
    case "SET_FORM_DATA":
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
          contactInformation: {
            ...state.formData.contactInformation,
            ...action.payload.contactInformation
          }
        }
      };
    case "SET_IS_STEP3_DONE":
      return { ...state, isStep3Done: action.payload };
    case "SET_IS_STEP2_DONE":
      return { ...state, isStep2Done: action.payload };
    case "SET_LOCAL_PRODUCTS":
      return { ...state, localProducts: action.payload };
    case "SET_ADDED_PRODUCTS":
      return { ...state, addedProducts: action.payload };
    case "SET_ZIP_CODE":
      return { ...state, zipCode: action.payload };
    case "RESET_BUY_NOW_STATE":
      return initialState;
    default:
      return state;
  }
};

export default buyNowReducer;
