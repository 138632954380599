// actionTypes.js
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const UPDATE_CART = "UPDATE_CART";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";

// initialState
const initialState = {
  cart: [],
  error: null,
  isPending: false,
};

// cartReducer
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const existingProductIndex = state.cart.findIndex(
        (product) => product._id === action.payload._id,
      );

      if (existingProductIndex !== -1) {
        // Create a new cart array with the updated product quantity
        const updatedCart = state.cart.map((product, index) =>
          index === existingProductIndex
            ? { ...product, quantity: product.quantity + 1 }
            : product,
        );
        return {
          ...state,
          cart: updatedCart,
          error: null,
          isPending: false,
        };
      } else {
        // Add new product to cart
        return {
          ...state,
          cart: [...state.cart, { ...action.payload, quantity: 1 }],
          error: null,
          isPending: false,
        };
      }
    }
    case UPDATE_CART: {
      // Create a set of IDs from the latest product data
      const updatedProductIds = new Set(
        action.payload.map((product) => product._id),
      );

      // Filter out products that are no longer in the latest product data
      const updatedCart = state.cart
        .map((cartItem) => {
          const updatedProduct = action.payload.find(
            (product) => product._id === cartItem._id,
          );
          return updatedProduct ? { ...cartItem, ...updatedProduct } : null;
        })
        .filter((item) => item !== null); // Remove any items that are no longer valid

      return {
        ...state,
        cart: updatedCart,
        error: null,
        isPending: false,
      };
    }
    case INCREASE_QUANTITY: {
      const updatedCart = state.cart.map((product) =>
        product._id === action.payload._id
          ? { ...product, quantity: product.quantity + 1 }
          : product,
      );
      return {
        ...state,
        cart: updatedCart,
        error: null,
        isPending: false,
      };
    }
    case DECREASE_QUANTITY: {
      const updatedCart = state.cart.map((product) =>
        product._id === action.payload._id
          ? { ...product, quantity: Math.max(product.quantity - 1, 1) }
          : product,
      );
      return {
        ...state,
        cart: updatedCart,
        error: null,
        isPending: false,
      };
    }
    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter(
          (product) => product._id !== action.payload._id,
        ),
        error: null,
        isPending: false,
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: [],
        error: null,
        isPending: false,
      };
    default:
      return state;
  }
};

export default cartReducer;
