import React from 'react'
import TableGallery from './components/TableGallery'

const Gallery = () => {
  return (
    <div>
        <TableGallery />
    </div>
  )
}

export default Gallery