import React, { useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import { GlobalModalV1 } from "../../components/GlobalModal";
import TableDiscounts from "./components/TableDiscounts";
import DiscountsForm from "./components/DiscountsForm";
import { useDisclosure } from "@chakra-ui/react";

const Discounts = () => {
  const [showPage, setShowPage] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const pageHandler = () => {
    setShowPage(!showPage);
  };

  //Table data button component
  const btnComponent = () => {
    return (
      <div className="flex gap-5 pt-2">
        <button className="rounded-full bg-[#35f8fe] p-1 text-white">
          <AiFillEdit />
        </button>

        <button className="rounded-full bg-[#EE5D50] p-1 text-white">
          <LiaTimesSolid />
        </button>
      </div>
    );
  };
  const columns = [
    { headerName: "Coupon Code", field: "couponCode" },
    { headerName: "Value(percentage)", field: "valuePercentage" },
    { headerName: "Offset Weeks", field: "offsetWeeks" },
    { headerName: "Restricted Breeds", field: "restrictedBreeds" },
    { headerName: "Created", field: "created" },
    { headerName: "Expires", field: "expires" },
    {
      headerName: "Inhouse Only",
      field: "inhouseOnly",
    },
    {
      headerName: "Hand Delivery",
      field: "handDelivery",
    },
    {
      headerName: "One Time Only",
      field: "oneTimeOnly",
    },
    { headerName: "Auto Apply", field: "autoApply" },
    {
      headerName: "Presale Only",
      field: "presaleOnly",
    },
    {
      headerName: "Allowed On Dep",
      field: "allowedOnDep",
    },
    {
      headerName: "Available Now Only",
      field: "availableNowOnly",
    },
    {
      headerName: "Actions",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];

  const rows = [
    {
      couponCode: "SUMMER21",
      valuePercentage: 15,
      offsetWeeks: -2,
      restrictedBreeds: "None",
      created: "2023-06-15",
      expires: "2024-06-15",
      inhouseOnly: "hand delivery",
      handDelivery: "hand delivery",
      oneTimeOnly: "hand delivery",
      autoApply: "hand delivery",
      presaleOnly: "hand delivery",
      allowedOnDep: "hand delivery",
      availableNowOnly: "hand delivery",
    },
    {
      couponCode: "WINTER21",
      valuePercentage: 10,
      offsetWeeks: -1,
      restrictedBreeds: "None",
      created: "2023-06-20",
      expires: "2024-06-20",
      inhouseOnly: "hand delivery",
      handDelivery: "hand delivery",
      oneTimeOnly: "hand delivery",
      autoApply: "hand delivery",
      presaleOnly: "hand delivery",
      allowedOnDep: "hand delivery",
      availableNowOnly: "hand delivery",
    },
  ];
  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    floatingFilter: true,
  };
  return (
    <>
      <button
        onClick={onOpen}
        className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white"
      >
        + Add Coupon
      </button>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"40%"}>
        <DiscountsForm onClose={onClose} isOpen={isOpen} />
      </GlobalModalV1>

      <TableDiscounts />
    </>
  );
};

export default Discounts;
