import { Tooltip } from "antd";
import React from "react";
import { MdCheck } from "react-icons/md";

const ApproveBtn = ({ onClick, ...props }) => {
  return (
    <Tooltip title="Approve Item" color="#008000">
      <button
        className={`rounded-full bg-[#008000] p-1 text-white`}
        onClick={onClick}
        onMouseDown={(e) => e.stopPropagation()}
        {...props}
      >
        <MdCheck />
      </button>
    </Tooltip>
  );
};

export default ApproveBtn;
