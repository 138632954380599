import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { GET_ALL_ORDERS} from "./OrdersType.js"
import axios from "axios";
import { create, Delete, fetchAll, update } from "../Apis/Apis.js";

export const useAllOrders = (apiUrl) => {
  return useQuery({
    queryKey: GET_ALL_ORDERS,
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateOrders = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_ORDERS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Orders:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_ORDERS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_ORDERS);
    },
  });
};

export const useDeleteOrdersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_ORDERS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Orders:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_ORDERS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_ORDERS);
    },
  });
};

export const useUpdateOrdersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_ORDERS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Orders:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_ORDERS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_ORDERS);
    },
  });
};
