export const CREATE_FAQS = "CREATE_FAQS";
export const GET_FAQS = "GET_FAQS";
export const GET_ALL_FAQS = "GET_ALL_FAQS";
export const UPDATE_FAQS = "UPDATE_FAQS";
export const DELETE_FAQS = "DELETE_FAQS";

export const CREATE_FAQS_CATEGORY = "CREATE_FAQS_CATEGORY";
export const GET_FAQS_CATEGORY = "GET_FAQS_CATEGORY";
export const GET_ALL_FAQS_CATEGORY = "GET_ALL_FAQS_CATEGORY";
export const UPDATE_FAQS_CATEGORY = "UPDATE_FAQS_CATEGORY";
export const DELETE_FAQS_CATEGORY = "DELETE_FAQS_CATEGORY";
