import { Modal } from "antd";
import React, { useState } from "react";

export const GlobalModalV1 = ({ isOpen, onClose, width, height, children }) => {
  return (
    <div>
      <Modal
        open={isOpen}
        onCancel={onClose}
        footer={false}
        centered
        className="auto mx-0 h-full overflow-y-auto px-0"
        width={width ? width : "100%"}
        height={height ? height : "100%"}
      >
        {children}
      </Modal>
    </div>
  );
};
