import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCreateZIP } from "../features/Location/locationService";
import * as Yup from "yup";
import { setFormData, setZipCode } from "../features/buy-now/action";

const PickupAndDelivery = () => {
  const dispatch = useDispatch();
  const { formData, zipCode } = useSelector((state) => state.buyNow);

  const [val, setVal] = useState(zipCode);
  const [apiLocation, setApiLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectDeliveryOption, setSelectDeliveryOption] = useState(null);

  const createMutation = useCreateZIP();

  useEffect(() => {
    setIsButtonDisabled(false);
  }, []);

  // zip code validation
  const zipCodeSchema = Yup.string()
    .required("Zip code is required")
    .matches(/^\d{5}$/, "Zip code must be exactly 5 digits");

  const handlerSubmit = async (val) => {
    try {
      // Validate the input using Yup
      await zipCodeSchema.validate(val);
      setIsButtonDisabled(true);

      // Prepare the data to send in the mutation
      const data = {
        zipCode: val ?? null,
      };

      // Execute the mutation to create the nearest location
      createMutation.mutate(
        {
          apiUrl: "/api/nearest",
          data,
        },
        {
          onSuccess: (response) => {
            console.log("Success:", response);
            setApiLocation(response);
            setLocationError(null);
            setIsButtonDisabled(false);
            dispatch(setZipCode(val));
          },
          onError: (error) => {
            setLocationError(error.response.data.error);
            setIsButtonDisabled(false);
          },
        },
      );
    } catch (error) {
      // Handle validation errors
      if (error.name === "ValidationError") {
        console.log("zip code length must be 5");
        setLocationError(error.message);
      } else {
        console.error("Unexpected Error:", error);
      }
    }
  };

  //Select Delivery Method & Pickup Point
  // const handleSelection = (type, value) => {
  //   console.log("Form Data pickup method====>", formData.pickupPoint);
  //   if (type === "pickupPoint") {
  //     dispatch(
  //       setFormData({
  //         pickupPoint: value === formData.pickupPoint ? null : value,
  //         deliveryMethod: value,
  //       }),
  //     );
  //   } else if (type === "deliveryMethod") {
  //     dispatch(
  //       setFormData({
  //         deliveryMethod: value === formData.deliveryMethod ? null : value,
  //         pickupPoint: null,
  //       }),
  //     );
  //   }
  // };

  const distanceInMiles = Number(apiLocation?.distance?.split(" ")[0]);
  console.log("nearest store===>", distanceInMiles);
  useEffect(() => {
    if (distanceInMiles > 50 && formData.deliveryMethod !== "pickup") {
      dispatch(
        setFormData({
          deliveryMethod: "pickup",
          pickupPoint: "simplyCavapoo's",
        }),
      );
    }
  }, [distanceInMiles, dispatch, formData.deliveryMethod]);

  // const handleSelection = (type, value) => {
  //   if (type === "pickupPoint") {
  //     dispatch(
  //       setFormData({
  //         pickupPoint: value === formData.pickupPoint ? null : value,
  //         deliveryMethod: value,
  //       }),
  //     );
  //   } else if (type === "deliveryMethod") {
  //     dispatch(
  //       setFormData({
  //         deliveryMethod: value === formData.deliveryMethod ? null : value,
  //         pickupPoint: null,
  //       }),
  //     );
  //   }
  // };

  const handleSelection = (type, value) => {
    if (type === "pickupPoint") {
      dispatch(
        setFormData({
          pickupPoint: formData.pickupPoint === value ? null : value,
          deliveryMethod: formData.pickupPoint === value ? null : value,
        }),
      );
    } else if (type === "deliveryMethod") {
      dispatch(
        setFormData({
          deliveryMethod: formData.deliveryMethod === value ? null : value,
          pickupPoint: null,
        }),
      );
    }
  };

  return (
    <div className="mx-auto flex w-full max-w-3xl flex-col">
      <div className="">
        <div className="mx-auto flex flex-col justify-center text-center">
          <label htmlFor="zipcode" className="text-lg">
            Enter Your Zip Code To See Available Pickup/Delivery Options
          </label>
          <p className="pt-3 font-medium text-primaryBlack-theme/50">
            *for approximately distance & custom options for you
          </p>
          <div className="relative mt-10">
            <input
              id="zipcode"
              type="number"
              className="w-full rounded-[100px] border-none bg-[#EDEDE9] px-6 py-7 outline-none"
              placeholder="Please enter zip code here"
              value={val}
              onChange={(e) => setVal(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !isButtonDisabled) {
                  handlerSubmit(val);
                }
              }}
            />
            <button
              className={`absolute right-4 top-2 flex items-center justify-center gap-3 rounded-full bg-[#001219] px-12 py-5 text-white ${isButtonDisabled && "cursor-not-allowed opacity-50"}`}
              disabled={isButtonDisabled}
              onClick={() => handlerSubmit(val)}
            >
              apply
            </button>
          </div>
          {locationError && val && (
            <p className="mt-2 text-red-500">{locationError}</p>
          )}
        </div>
        <div className="my-10 flex flex-col items-center justify-center">
          {apiLocation && !locationError && (
            <>
              <p className="mb-5 mt-5 text-2xl font-bold">
                Select Pickup/Delivery Method{" "}
              </p>
              {/* <div className="my-10 flex flex-col gap-16 md:flex-row">
                <div
                  onClick={() => handleSelection("deliveryMethod", "pickup")}
                  className={`flex h-[350px] w-full cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl ${
                    formData.deliveryMethod === "pickup"
                      ? "bg-[#EDEDE9]"
                      : "bg-[#5EC0FC]"
                  } px-20 py-10 duration-200 hover:bg-[#5EC0FC]`}
                >
                  <p className="text-xl">
                    <span className="font-semibold">
                      {apiLocation.nearestStore.city}
                    </span>
                    , {apiLocation.nearestStore.stateAbbreviation}
                  </p>
                  <p className="text-center font-bold">
                    {apiLocation.nearestStore.address}
                  </p>

                  <p>
                    Approx,{" "}
                    <span className="font-semibold">
                      {apiLocation.distance}
                    </span>
                  </p>
                  <p className="text-center text-xl font-bold">Pickup</p>

                  <p className="inline-block rounded-3xl bg-[#001219] px-8 py-2 font-semibold text-white">
                    Free
                  </p>
                  <a
                    href="/shipping"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-bold underline"
                  >
                    Learn more
                  </a>
                </div>
                {distanceInMiles <= 50 && (
                  <div
                    onClick={() =>
                      handleSelection("pickupPoint", "simplyCavapoop")
                    }
                    className={`flex h-[350px] w-full cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl ${
                      formData.pickupPoint === "simplyCavapoop"
                        ? "bg-[#5EC0FC]"
                        : "bg-[#EDEDE9]"
                    } px-20 py-10 duration-200 hover:bg-[#5EC0FC]`}
                  >
                    <img src="/images/logo.svg" alt="logo" />
                    <p className="text-center text-xl font-bold">
                      In home delivery
                    </p>
                    <p>
                      Approx,{" "}
                      <span className="font-semibold">
                        {apiLocation.distance}
                      </span>
                    </p>
                  </div>
                )}
              </div> */}

              <div className="my-10 flex flex-col gap-16 md:flex-row">
                <div
                  onClick={() => handleSelection("deliveryMethod", "pickup")}
                  className={`flex h-[350px] w-full cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl ${
                    formData.deliveryMethod === "pickup"
                      ? "bg-[#5EC0FC]"
                      : "bg-[#EDEDE9]"
                  } px-20 py-10 duration-200 hover:bg-[#5EC0FC]`}
                >
                  <p className="text-xl">
                    <span className="font-semibold">
                      {apiLocation.nearestStore.city}
                    </span>
                    , {apiLocation.nearestStore.stateAbbreviation}
                  </p>
                  <p className="text-center font-bold">
                    {apiLocation.nearestStore.address}
                  </p>

                  <p>
                    Approx,{" "}
                    <span className="font-semibold">
                      {apiLocation.distance}
                    </span>
                  </p>
                  <p className="text-center text-xl font-bold">Pickup</p>

                  <p className="inline-block rounded-3xl bg-[#001219] px-8 py-2 font-semibold text-white">
                    Free
                  </p>
                  <a
                    href="/shipping"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-bold underline"
                  >
                    Learn more
                  </a>
                </div>
                {distanceInMiles <= 50 && (
                  <div
                    onClick={() =>
                      handleSelection("pickupPoint", "simplyCavapoop")
                    }
                    className={`flex h-[350px] w-full cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl ${
                      formData.pickupPoint === "simplyCavapoop"
                        ? "bg-[#5EC0FC]"
                        : "bg-[#EDEDE9]"
                    } px-20 py-10 duration-200 hover:bg-[#5EC0FC]`}
                  >
                    <img src="/images/logo.svg" alt="logo" />
                    <p className="text-center text-xl font-bold">
                      In home delivery
                    </p>
                    <p>
                      Approx,{" "}
                      <span className="font-semibold">
                        {apiLocation.distance}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
          <ul className="list-inside list-disc px-6 leading-10 md:px-0">
            <li>Setup an appointment ON/After availability date</li>
            <li>Our team will assist you</li>
            <li>
              Pups smaller than <strong>3</strong> lbs may need longer time with
              us
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PickupAndDelivery;
