import React from "react";

const OrderDetails = ({ data }) => {
  return (
    <div className="my-5">
      <div className="flex justify-between">
        <div className="leading-7">
          <p className="pb-10 font-bold">Destination Info</p>
          <p className="text-xl font-bold">
            {data.destinationAddress.firstName +
              "" +
              data.destinationAddress.lastName}
          </p>
          <p className="text-wrap">
            {data.destinationAddress.address} {data.destinationAddress.city}{" "}
            {data.destinationAddress.state}
          </p>
          <p>
            email
            <strong> {data.contactInformation.emailAddress}</strong>
          </p>
          <p>
            phone
            <strong> {data.contactInformation.cellPhone}</strong>
          </p>
        </div>
      </div>

      <div className="my-5">
        <div className="mb-4">
          <h4 className="py-6 text-lg font-bold">Order Details</h4>
          <div className="">
            <table className="w-full min-w-[300px] table-auto sm:min-w-[400px] lg:min-w-[600px]">
              <tbody>
                {data?.products?.map((item, index) => (
                  <tr>
                    <td className="p-2">{item.name}n Fee</td>
                    <td className="p-2">$ {item.price}</td>
                  </tr>
                ))}
                <tr>
                  <td className="py-6 font-bold">Sub Total</td>
                  <td className="py-6 font-bold">$ {data.orderSummary.subtotal}</td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>$ {data.orderSummary.discount}</td>
                </tr>
                <tr>
                  <td>Sales Tax</td>
                  <td className="pb-4">$ {data.orderSummary.salesTax}</td>
                </tr>
                <tr>
                  <td>Additional Charges/Discounts</td>
                  <td className="py-4">$ $0.00</td>
                </tr>

                <tr>
                  <td className="font-bold">Order Total</td>
                  <td className="font-bold">$ {data.orderSummary.orderTotal}</td>
                </tr>
                <tr>
                  <td>Payment Received</td>
                  <td>$ 758.00</td>
                </tr>
                <tr>
                  <td>Remaining Balance</td>
                  <td>$ 86.01</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
