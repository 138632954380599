import React from "react";
import OrderDetails from "./innertabpages/OrderDetails";
import Survey from "./innertabpages/Survey";
import Accounts from "./innertabpages/Accounts";
import ExtHoldEnb from "./innertabpages/ExtHoldEnb";
import BloodTest from "./innertabpages/BloodTest";
import Timeline from "./innertabpages/Timeline";
import ChakraTabs from "../../../../../../components/ChakraTabs.jsx";

const Summary = ({ data }) => {
  const tabDataPrimary = [
    {
      label: "Order Details",
      content: <OrderDetails data={data} />,
    },
    // {
    //   label: "Survey",
    //   content: <Survey />,
    // },
    // {
    //   label: "Accounts",
    //   content: <Accounts />,
    // },
  ];
  const tabDataSecondary = [
    {
      label: "Extended Holding Enabled",
      content: <ExtHoldEnb />,
    },
    {
      label: "Blood Test",
      content: <BloodTest />,
    },
    {
      label: "Timeline",
      content: <Timeline />,
    },
  ];
  return (
    <div className="my-10 grid grid-cols-1 gap-10 md:grid-cols-5">
      <div className="col-span-3 min-h-[800px] w-full rounded-[20px] bg-white p-10">
        <ChakraTabs tabData={tabDataPrimary} />
      </div>
      {/* <div className="col-span-2 h-[300px] w-full overflow-y-auto rounded-[20px] bg-white p-10">
        <ChakraTabs tabData={tabDataSecondary} />
      </div> */}
    </div>
  );
};

export default Summary;
