import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../utils/uploadFile";
import {
  useCreateInsurance,
  useDeleteInsurance,
  useUpdateInsurance,
} from "../../../features/Insurance/insuranceServices";

const { TextArea } = Input;

const InsuranceForms = ({ onClose, selectedData, isUpdateModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [media, setMedia] = useState([]);

  const createMutation = useCreateInsurance();
  const UpdateMutation = useUpdateInsurance();

  const [form] = Form.useForm();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      console.log("Selected Data:", selectedData[0]);

      if (selectedData[0]?.media?.url) {
        const existingMedia = [
          {
            uid: "-4",
            name: "media",
            status: "done",
            url: selectedData[0].media.url,
            thumbUrl: selectedData[0].media.url,
            type: "image/jpeg",
          },
        ];
        console.log("Setting media:", existingMedia);
        setMedia(existingMedia);
      }

      form.setFieldsValue({
        title: selectedData[0]?.title ?? null,
        orders: selectedData[0]?.orders?.orderId ?? null,
        policyNumber: selectedData[0]?.policyNumber ?? null,
        policyCompany: selectedData[0]?.policyCompany ?? null,
        notes: selectedData[0]?.notes ?? null,
        media: selectedData[0]?.media?.url
          ? [
              {
                uid: "-4",
                name: "media",
                status: "done",
                url: selectedData[0].media.url,
              },
            ]
          : [],
      });
    }
  }, [selectedData, isUpdateModal, form]);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      let mediaUrl = null;
      if (media?.length) {
        if (media[0].originFileObj) {
          const uploadedFiles = await uploadFiles([media[0].originFileObj]);
          mediaUrl = uploadedFiles[0];
        } else {
          mediaUrl = media[0].url;
        }
      }

      const data = {
        orders: values.orders ?? null,
        policyNumber: values.policyNumber ?? null,
        policyCompany: values.policyCompany ?? null,
        notes: values.notes ?? null,
        media: mediaUrl,
      };

      console.log("Submitting data:", data);

      if (isUpdateModal && selectedData) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/insurance/${selectedData[0]?._id}`,
          data,
        });
        toast({
          title: "Insurance Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/insurance",
          data,
        });
        toast({
          title: "Insurance created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      setMedia([]);
      form.resetFields();
      onClose();
    } catch (error) {
      console.error("Error details:", error);
      const errorMessage =
        error.response?.data?.message ||
        (error.response?.data?.details?.includes("duplicate")
          ? "Insurance Already Exists"
          : error.message || "Error creating Insurance");

      toast({
        title: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleBeforeUpload = (file) => {
    const newFile = {
      uid: file.uid,
      name: file.name,
      status: "done",
      url: URL.createObjectURL(file),
      thumbUrl: URL.createObjectURL(file),
      originFileObj: file,
      type: file.type,
    };

    setMedia([newFile]);
    return false;
  };

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">Insurance</h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <Form.Item
              label="media"
              name={"media"}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                listType="picture-card"
                fileList={media}
                beforeUpload={handleBeforeUpload}
                onRemove={(file) => {
                  setMedia(media.filter((f) => f.uid !== file.uid));
                }}
                multiple={true}
                disabled={isLoading}
              >
                {media?.length < 1 && (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
              <span className="text-red-600">
                *Image dimensions should be 1200x800
              </span>
            </Form.Item>

            <Form.Item
              name="orders"
              label="order Id"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Order ID" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="policyNumber"
              label="Policy Number"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Policy Number" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="policyCompany"
              label="Policy Company"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Policy Company" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="notes"
              label="Notes"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea placeholder="Notes" disabled={isLoading} />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button
              className="!bg-[#001219] !text-white"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default InsuranceForms;
