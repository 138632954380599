import { useQuery } from "@tanstack/react-query"
import { GET_ALL_SALES } from "./salesType"
import { fetchAll } from "../Apis/Apis"


export const useAllSALES = (apiUrl) => {
    return useQuery({
      queryKey: [GET_ALL_SALES],
      queryFn: () => fetchAll(apiUrl),
    });
  };
