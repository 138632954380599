import React, { useState } from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";

const CustomPieChart = ({
  data01,
  onPieEnter,
  setActiveIndex,
  activeIndex,
  COLORS,
  outerRadius
}) => {
  return (
    <div className="flex justify-center items-center">
      <PieChart width={300} height={200}>
        <Pie
          isAnimationActive={true}
          data={data01}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={outerRadius}
          fill="#8884d8"
          onMouseEnter={onPieEnter}
          onMouseLeave={() => setActiveIndex(-1)}
        >
          {data01.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
              stroke={activeIndex === index ? "#000" : "#fff"}
              strokeWidth={activeIndex === index ? 3 : 1}
            />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );
};

export default CustomPieChart;
