import React from "react";

const GrossMargin = () => {
  const grossProfit_dashboard = [
    {
      title: "Total Gross Profit",
      value: "$985,455.49",
      label: "Gross Profit",
    },
    {
      title: "Total Sales",
      value: "1519",
      label: "Pups",
    },

    {
      title: "Total Revenue",
      value: "$3,785,833.61",
      label: "Revenue",
    },
    {
      title: "Total Tax",
      value: "$207,828.98",
      label: "Tax",
    },
    {
      title: "Total Cost of Goods",
      value: "$1,770,316.01",
      label: "Cost of Goods",
    },
    {
      title: "Total Adwords",
      value: "$419,228.04",
      label: "Adwords",
    },
    {
      title: "Total Employees",
      value: "$218,509.47",
      label: "Cost Employees",
    },
    {
      title: "Total Credit Cards",
      value: "$59,094.64",
      label: "Cost Credit Cards",
    },
    {
      title: "Total Cost of Supplies",
      value: "$50,392.00",
      label: "Cost of Supplies",
    },

    {
      title: "Total Misc Cost",
      value: "$48,028.00",
      label: "Misc Cost",
    },

    {
      title: " Total Cost Rent/Utilities",
      value: "$26,980.98",
      label: "Cost Rent/Utilities",
    },
    {
      title: "Total Misc Cost",
      value: [
        "$42,506.23",
        "$42,506.23",
        "$42,506.23",
        "$42,506.23",
        "$42,506.23",
        "$42,506.23",
        "$42,506.23",
      ],
      label: [
        "Cash",
        "Credit",
        "Debit",
        "Paypal",
        "Financed",
        "Outstanding",
        "Closed Outstanding",
      ],
    },
  ];
  return (
    <div>
      <div className="my-4 grid grid-cols-4 gap-4">
        {grossProfit_dashboard.map((data) => (
          <div
            key={data.title}
            className={`rounded-[20px] bg-white ${
              Array.isArray(data.value) && Array.isArray(data.label)
                ? "col-span-4 border"
                : "col-span-1"
            }`}
          >
            <div className="flex flex-col items-center justify-center p-12">
              <div className="flex items-center gap-2">
                <p className="font-bold">{data.title}</p>
              </div>
              <div className="flex items-center justify-between gap-6 pt-8">
                {Array.isArray(data.value) && Array.isArray(data.label) ? (
                  <div className="flex flex-wrap justify-between gap-6">
                    {data.value.map((val, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center justify-between border-r border-gray-300 pr-6 last:border-none"
                      >
                        <p className="text-2xl font-bold">{val}</p>
                        <p className="font-semibold text-[#001219]/50">
                          {data.label[index]}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <p className="text-2xl font-bold">{data.value}</p>
                    <p className="font-semibold text-[#001219]/50">
                      {data.label}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GrossMargin;
