import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const CustomLineChart = ({data}) => {
  // const data = [
  //   {
  //     name: "Page A",
  //     uv: 4000,
  //     pv: 2400,
  //     amt: 2400,
  //   },
  //   {
  //     name: "Page B",
  //     uv: 3000,
  //     pv: 1398,
  //     amt: 2210,
  //   },
  //   {
  //     name: "Page C",
  //     uv: 2000,
  //     pv: 9800,
  //     amt: 2290,
  //   },
  //   {
  //     name: "Page D",
  //     uv: 2780,
  //     pv: 3908,
  //     amt: 2000,
  //   },
  //   {
  //     name: "Page E",
  //     uv: 1890,
  //     pv: 4800,
  //     amt: 2181,
  //   },
  //   {
  //     name: "Page F",
  //     uv: 2390,
  //     pv: 3800,
  //     amt: 2500,
  //   },
  //   {
  //     name: "Page G",
  //     uv: 3490,
  //     pv: 4300,
  //     amt: 2100,
  //   },
  // ];
  return (
    <div className="w-full h-full">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <YAxis
            axisLine={false}
            tick={{ fontWeight: "bolder" }}
            markerEnd={false}
          />
          <Tooltip />

          <Line
            type="bumpX"
            dataKey="pv"
            stroke="#4318FF"
            strokeWidth={3}
            dot={false}
            activeDot={{ r: 5 }}
          />
          {/* <Line
            type="bumpX"
            dataKey="uv"
            stroke="#6AD2FF"
            strokeWidth={3}
            dot={false}
            activeDot={{ r: 5 }}
          /> */}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;
