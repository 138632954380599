import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { create, Delete, fetchAll, update } from "../Apis/Apis";
import { GET_ALL_PARENTS } from "./parentTypes";

export const useAllParents = (apiUrl) => {
  return useQuery({
    queryKey: [GET_ALL_PARENTS],
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateParent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_PARENTS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Parent:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_PARENTS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_PARENTS);
    },
  });
};

export const useDeleteProductsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_PARENTS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Parent:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_PARENTS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_PARENTS);
    },
  });
};

export const useUpdateProductsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_PARENTS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Parent:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_PARENTS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_PARENTS);
    },
  });
};
