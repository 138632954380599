export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_IS_STEP3_DONE = "SET_IS_STEP3_DONE";
export const SET_IS_STEP2_DONE = "SET_IS_STEP2_DONE";
export const SET_LOCAL_PRODUCTS = "SET_LOCAL_PRODUCTS";
export const SET_ADDED_PRODUCTS = "SET_ADDED_PRODUCTS";
export const SET_ZIP_CODE = "SET_ZIP_CODE";
export const RESET_BUY_NOW_STATE = "RESET_BUY_NOW_STATE";

export const setCurrentStep = (step) => ({
  type: SET_CURRENT_STEP,
  payload: step,
});

export const setFormData = (data) => ({
  type: SET_FORM_DATA,
  payload: data,
});

export const setIsStep3Done = (isDone) => ({
  type: SET_IS_STEP3_DONE,
  payload: isDone,
});

export const setIsStep2Done = (isDone) => ({
  type: SET_IS_STEP2_DONE,
  payload: isDone,
});

export const setLocalProducts = (products) => ({
  type: SET_LOCAL_PRODUCTS,
  payload: products,
});

export const setAddedProducts = (products) => ({
  type: SET_ADDED_PRODUCTS,
  payload: products,
});

export const setZipCode = (zipCode) => ({
  type: SET_ZIP_CODE,
  payload: zipCode,
});

export const resetBuyNowState = () => ({
  type: RESET_BUY_NOW_STATE,
});
