import React from "react";

const BreederFooterInfo = () => {
  return (
    <div className="mt-8 mb-5">
      <h6 className="text-center text-2xl md:text-3xl mb-2 mt-20">
        Our Health Guarantee for Your New Puppy
      </h6>
      <div className="flex flex-col md:flex-row gap-4 w-full">
        <div className="px-3 py-6 text-center text-primaryBlack-theme sm:px-6 flex-1">
          <p className="text-lg font-medium uppercase tracking-wide text-primaryBlack-theme/50">
            Puppies with a
          </p>
          <h6 className="text-2xl md:text-3xl">guarantee</h6>
          <p className="py-4 text-sm md:text-base font-medium leading-6">
            The health of your new family member is our primary concern. We take
            all necessary precautions from vet inspection of our pups to their
            parents requirement to pass annual exams. If there&apos;s something
            undiscoverable at 8 weeks of age that later comes up as hereditary
            or congenital, you&apos;re covered. We stand behind our pups with up
            to a 10 Year guanrantee.
          </p>
        </div>
        <div className="px-3 py-6 text-center text-primaryBlack-theme sm:px-6 flex-1">
          <p className="text-lg font-medium uppercase tracking-wide text-primaryBlack-theme/50">
            Breeders with
          </p>
          <h6 className="text-2xl md:text-3xl">Integrity</h6>
          <p className="py-4 text-sm md:text-base font-medium leading-6">
            Breeders with Integrity emphasizes ethical practices, transparency,
            and a commitment to animal welfare among breeders. These breeders
            prioritize the health, socialization, and well-being of their
            animals, ensuring responsible breeding practices, maintaining high
            standards of care, and following ethical guidelines. Their goal is
            to provide happy, healthy animals and build trust with potential pet
            owners through open communication and integrity.
          </p>
        </div>
        <div className="px-3 py-6 text-center text-primaryBlack-theme sm:px-6 flex-1">
          <p className="text-lg font-medium uppercase tracking-wide text-primaryBlack-theme/50">
            Breeders with
          </p>
          <h6 className="text-2xl md:text-3xl">Commitment</h6>
          <p className="py-4 text-sm md:text-base font-medium leading-6">
            Breeders with Commitment highlights breeders dedicated to the
            lifelong health and happiness of their animals. These breeders
            invest time and resources into responsible breeding, high-quality
            care, and ongoing support for pet owners. They are committed to
            producing well-socialized, healthy animals and maintaining strong
            relationships with new owners.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BreederFooterInfo;
