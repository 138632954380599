import React, { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../components/GlobalModal";

import CavaPooForm from "./components/CavaPooForm";
import { useAllCAVAPOO } from "../../features/cavapoo/cavapooServices";

const Cavapoo101 = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: cavapooPage } = useAllCAVAPOO("/api/cavapoos");
  console.log(cavapooPage);

  return (
    <div className="my-10">
      <div className="flex flex-1 flex-col">
        <button
          onClick={onOpen}
          className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white"
        >
          Edit CavaPoo 101
        </button>

        <GlobalModalV1 onClose={onClose} isOpen={isOpen} width={"800px"}>
          <CavaPooForm
            onClose={onClose}
            isOpen={isOpen}
            cavapooPage={cavapooPage}
          />
        </GlobalModalV1>
      </div>
      <div className="summary mx-auto mt-10 w-[75%] rounded-lg border border-zinc-300 p-6 shadow-2xl">
        <h2 className="mb-4 text-center text-3xl font-bold">
          {cavapooPage?.[0]?.mainHeading}
        </h2>
        <div className="mx-auto mt-4 h-[220px] w-[220px]">
          <img
            src={cavapooPage?.[0]?.media}
            alt=""
            className="h-full w-full rounded-md object-cover"
          />
        </div>
        <div className="mt-6 space-y-4">
          <p>
            <strong>Description:</strong> {cavapooPage?.[0]?.description}
          </p>
          <p>
            <strong>Dog Details:</strong> {cavapooPage?.[0]?.dogDetails}
          </p>
          <p>
            <strong>Companionship Breeds:</strong>{" "}
            {cavapooPage?.[0]?.companionshipBreeds}
          </p>
          <p>
            <strong>Cavapoo Companionship:</strong>{" "}
            {cavapooPage?.[0]?.cavapooCompanionship}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cavapoo101;
